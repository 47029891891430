import { forwardRef } from 'react';
import ResizeTextarea, { TextareaAutosizeProps } from 'react-textarea-autosize';
import { CounterInput, CounterInputProps } from './CounterInput';
import { Input, InputProps } from './Input';

export type TextareaProps = Omit<InputProps<'textarea'>, 'as'>;

const textareaCommonProps = {
  py: 2,
  minHeight: 80,
  as: 'textarea',
} as const;

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (props, ref) => <Input {...textareaCommonProps} {...props} ref={ref} />,
);

Textarea.displayName = 'Textarea';

export type CounterTextareaProps = Omit<CounterInputProps<'textarea'>, 'as'>;

export const CounterTextarea = forwardRef<
  HTMLTextAreaElement,
  CounterTextareaProps
>((props, ref) => (
  <CounterInput {...textareaCommonProps} {...props} ref={ref} />
));

CounterTextarea.displayName = 'CounterTextarea';

const expandingTextareaCommonProps = {
  as: ResizeTextarea,
  resize: 'none',
  width: '100%',
} as const;

export type ExpandingTextareaProps = Omit<
  TextareaAutosizeProps,
  keyof TextareaProps
> &
  TextareaProps;

export const ExpandingTextarea = forwardRef<
  HTMLTextAreaElement,
  ExpandingTextareaProps
>((props, ref) => (
  <Textarea {...expandingTextareaCommonProps} {...props} ref={ref} />
));

ExpandingTextarea.displayName = 'ExpandingTextarea';

export type ExpandingCounterTextareaProps = Omit<
  TextareaAutosizeProps,
  keyof CounterTextareaProps
> &
  CounterTextareaProps;

export const ExpandingCounterTextarea = forwardRef<
  HTMLTextAreaElement,
  ExpandingCounterTextareaProps
>((props, ref) => (
  <CounterTextarea {...expandingTextareaCommonProps} {...props} ref={ref} />
));

ExpandingCounterTextarea.displayName = 'ExpandingCounterTextarea';
