import { Box } from '@amzn/ring-ui-react-components';
import Maybe from 'graphql/tsutils/Maybe';
import { Capitalizer } from 'src/shared/components/Capitalizer';

export const EventProperty = ({
  label,
  value,
  show,
  ...props
}: {
  label: string;
  value: Maybe<string> | undefined;
  show?: boolean;
  mb?: number;
}) =>
  show ? (
    <Box {...props} data-testid="event-property">
      <Box
        as="span"
        textStyle="body-normal-medium"
        data-testid="event-propety-label"
      >
        {label}
      </Box>
      :{' '}
      <Capitalizer display="inline" data-testid="event-propety-value">
        <Box as="span" textStyle="body-normal-regular">
          {value}
        </Box>
      </Capitalizer>
    </Box>
  ) : null;
