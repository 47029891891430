import { GetDeniedPostPageCollectionQuery } from '@amzn/ring-neighbors-contentful-gql-schema';
import GET_DENIED_POST_PAGE_COLLECTION from '@amzn/ring-neighbors-contentful-gql-schema/operations/denied-posts/getDeniedPostPageCollection.graphql';
import { useIsNpssUser } from 'src/auth';
import { useContentfulQuery } from 'src/hooks/useContentfulQuery';

export const useGetDeniedPostsPages = () => {
  const isNpssUser = useIsNpssUser();
  const { data, error, ...rest } =
    useContentfulQuery<GetDeniedPostPageCollectionQuery>(
      GET_DENIED_POST_PAGE_COLLECTION,
      {
        skip: isNpssUser,
      },
    );

  if (error) throw new Error('Contentful not reachable');

  return {
    ...rest,
    data: data?.deniedPostPageCollection?.items,
  };
};
