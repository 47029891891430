import { PetInfo } from '@amzn/ring-neighbors-api-orchestrator-gql-schema';
import { Box } from '@amzn/ring-ui-react-components';
import { useTranslation } from 'react-i18next';
import { getPetAge } from 'src/features/PetTag/components';
import { EventProperty } from './EventProperty';

export interface PetInfoPdp extends PetInfo {
  name: any;
  species: any;
}

export interface EventCardPetInfoProps {
  petInfo: PetInfoPdp;
}

export const EventCardPetInfo = ({ petInfo }: EventCardPetInfoProps) => {
  const { t } = useTranslation('components.event');

  return (
    <Box mt={4}>
      {(petInfo.last_known_date || petInfo.last_known_location) && (
        <Box textStyle="body-normal-medium" data-testid="last-seen-header">
          {t('pet-info.title')}
        </Box>
      )}
      <EventProperty
        label={t('pet-info.lost-since')}
        value={petInfo.last_known_date}
        show={!!petInfo.last_known_date}
      />
      <EventProperty
        label={t('pet-info.lost-location')}
        value={petInfo.last_known_location}
        show={!!petInfo.last_known_location}
        mb={4}
      />
      {(petInfo.name ||
        petInfo.species ||
        petInfo.breed ||
        petInfo.gender ||
        petInfo.date_of_birth ||
        petInfo.weight_in_kg ||
        petInfo.color ||
        petInfo.medical_information ||
        petInfo.additional_information) && (
        <Box textStyle="body-normal-medium" data-testid="about-my-pet-header">
          {t('pet-info.about')}
        </Box>
      )}

      <EventProperty
        label={t('pet-info.name')}
        value={petInfo.name}
        show={!!petInfo.name}
      />
      <EventProperty
        label={t('pet-info.type')}
        value={petInfo.species}
        show={!!petInfo.species}
      />
      <EventProperty
        label={t('pet-info.breed')}
        value={petInfo.breed}
        show={!!petInfo.breed}
      />
      <EventProperty
        label={t('pet-info.gender')}
        value={petInfo.gender}
        show={!!petInfo.gender}
      />
      <EventProperty
        label={t('pet-info.age')}
        value={getPetAge(petInfo.date_of_birth)}
        show={!!petInfo.date_of_birth}
      />
      <EventProperty
        label={t('pet-info.weigth')}
        value={`${String(petInfo.weight_in_kg).toString()}kg`}
        show={
          petInfo.weight_in_kg !== null && petInfo.weight_in_kg !== undefined
        }
      />
      <EventProperty
        label={t('pet-info.color')}
        value={petInfo.color}
        show={!!petInfo.color}
      />
      <EventProperty
        label={t('pet-info.medical-information')}
        value={petInfo.medical_information}
        show={!!petInfo.medical_information}
      />
      <EventProperty
        label={t('pet-info.additional-information')}
        value={petInfo.additional_information}
        show={!!petInfo.additional_information}
      />
    </Box>
  );
};
