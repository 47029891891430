import { ReportCategory } from '@amzn/ring-neighbors-api-orchestrator-gql-schema';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsNpssUser } from 'src/auth';
import { useEventScheduledPostDelete } from 'src/components/EventCard/hooks/useEventScheduledPostDelete';
import { toast } from 'src/shared/components/Toast';
import {
  useEventDeleteMutation,
  useEventDownvoteMutation,
  useEventHideMutation,
  useEventReportMutation,
  useEventResolveMutation,
  useEventUnresolveMutation,
  useEventUpvoteMutation,
  useEventUpdateMutation,
} from './useEventMutations';

export interface UseEventCardHandlersProps {
  eventId: string;
  isScheduledEvent: boolean;
  onMutationCompleted: () => void;
}

export const useEventCardHandlers = ({
  eventId,
  isScheduledEvent,
  onMutationCompleted,
}: UseEventCardHandlersProps) => {
  const { t } = useTranslation('features.myPosts', { useSuspense: false });
  const isNpssUser = useIsNpssUser();

  const handleScheduledPostOnMutationCompleted = () => {
    toast.success(t('delete-schedule-post-toast.success'));
    onMutationCompleted?.();
  };

  const handleScheduledPostOnMutationError = () => {
    toast.error(t('delete-schedule-post-toast.error'));
  };

  const [deleteScheduledEvent] = useEventScheduledPostDelete({
    eventId,
    onMutationCompleted: handleScheduledPostOnMutationCompleted,
    onMutationError: handleScheduledPostOnMutationError,
  });

  const [deleteEvent] = useEventDeleteMutation({
    eventId,
    onMutationCompleted,
  });

  const [hideEvent] = useEventHideMutation({ eventId, onMutationCompleted });

  const [reportEvent] = useEventReportMutation({
    eventId,
    onMutationCompleted,
  });

  const [upvoteEvent, { loading: isUpvoteLoading }] = useEventUpvoteMutation({
    eventId,
  });

  const [downvoteEvent, { loading: isDownvoteLoading }] =
    useEventDownvoteMutation({ eventId });

  const [resolveEvent, { loading: isResolveLoading }] = useEventResolveMutation(
    { eventId },
  );

  const [resolveNpssEvent, { loading: isResolveNpssLoading }] =
    useEventUpdateMutation({ eventId, isResolved: true });

  const [unresolveEvent, { loading: isUnresolveLoading }] =
    useEventUnresolveMutation({ eventId });

  const [unresolveNpssEvent, { loading: isUnresolveNpssLoading }] =
    useEventUpdateMutation({ eventId, isResolved: false });

  const handleOnReport = useCallback(
    (flag: ReportCategory, details?: string) => {
      const reason = details || flag.id;

      reportEvent({
        variables: { reason },
      });
    },
    [reportEvent],
  );

  return {
    handlers: {
      onDelete: isScheduledEvent ? deleteScheduledEvent : deleteEvent,
      onHide: hideEvent,
      onReport: handleOnReport,
      onUpvote: upvoteEvent,
      onDownvote: downvoteEvent,
      onResolve: isNpssUser ? resolveNpssEvent : resolveEvent,
      onUnresolve: isNpssUser ? unresolveNpssEvent : unresolveEvent,
    },
    states: {
      isVoteLoading: isUpvoteLoading || isDownvoteLoading,
      isResolveLoading: isNpssUser
        ? isResolveNpssLoading || isUnresolveNpssLoading
        : isResolveLoading || isUnresolveLoading,
    },
  };
};
