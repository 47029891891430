import { EventOwnerHeaderProps } from './EventOwnerHeader';
import { OwnerHeaderBase } from './OwnerHeaderBase';
import {
  getCommentOwnerHeaderDisplayUsername,
  getEventOwnerHeaderDisplayUsername,
} from './utils';

export interface CommentOwnerHeaderProps extends EventOwnerHeaderProps {
  isOwnedEvent: boolean;
}

export const CommentOwnerHeader = ({
  isOwned,
  isOwnedEvent,
  ownerUsername,
  ...props
}: CommentOwnerHeaderProps) => {
  const getDisplayUsername = isOwnedEvent
    ? getCommentOwnerHeaderDisplayUsername
    : getEventOwnerHeaderDisplayUsername;

  const displayUsername = getDisplayUsername({ isOwned, ownerUsername });

  return (
    <OwnerHeaderBase {...props} isOwned={isOwned || isOwnedEvent}>
      {displayUsername}
    </OwnerHeaderBase>
  );
};
