import { Box, BoxBasedComponentProps, BoxProps } from '../Box';

export type MediaType = 'img' | 'video';

export type CardMediaProps<
  T extends MediaType,
  P extends {} = {},
  K extends keyof BoxProps<T> | '' = '',
> = BoxBasedComponentProps<
  T,
  P,
  K | 'display' | 'height' | 'maxHeight' | 'mx' | 'my' | 'sx' | 'width'
>;

export const CardMedia = <T extends MediaType>(props: CardMediaProps<T>) => (
  <Box
    objectFit="contain"
    {...props}
    display="block"
    height="100%"
    maxHeight="inherit"
    mx="auto"
    my={0}
    width="100%"
    sx={{
      '&:focus': {
        outline: 'none',
      },
      '&::-webkit-media-controls-fullscreen-button, &::-webkit-media-controls-overflow-button':
        {
          display: 'none',
        },
      '&:not(:only-child)': {
        '&:first-child': {
          borderTopLeftRadius: 'rounder',
          borderTopRightRadius: 'rounder',
        },
        '&:last-child': {
          borderBottomLeftRadius: 'rounder',
          borderBottomRightRadius: 'rounder',
        },
      },
    }}
  />
);
