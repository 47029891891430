import { Box, Flex } from '@amzn/ring-ui-react-components';
import { callAllHandlers } from '@chakra-ui/utils';
import { ChangeEvent, forwardRef, useEffect, useMemo, useState } from 'react';
import { Input, InputProps, InputType } from './Input';

export type CounterInputProps<T extends InputType = 'input'> = InputProps<
  T,
  { defaultValue?: string; initialCount?: number; value?: string }
>;

export const CounterInput = forwardRef<
  HTMLInputElement | HTMLTextAreaElement,
  CounterInputProps<'input'> | CounterInputProps<'textarea'>
>(
  (
    {
      children,
      defaultValue,
      initialCount = defaultValue?.length || 0,
      maxLength,
      onChange,
      value,
      ...props
    },
    ref,
  ) => {
    const [length, setLength] = useState(
      () => defaultValue?.length ?? value?.length ?? initialCount,
    );

    const handleChange = useMemo(
      () =>
        callAllHandlers((e: ChangeEvent<HTMLInputElement>) => {
          setLength(e.target.value.length);
        }, onChange),
      [onChange],
    );

    const text = maxLength ? `${length}/${maxLength}` : length;

    useEffect(() => {
      if (!value) {
        return;
      }

      setLength(value.length);
    }, [value]);

    return (
      <>
        <Flex
          justifyContent="space-between"
          position="relative"
          alignItems="center"
        >
          {children}
          <Box
            textStyle="body-small-light"
            color={length === maxLength ? 'negative.500' : undefined}
            data-testid="post-length"
          >
            {text}
          </Box>
        </Flex>
        <Input
          {...props}
          defaultValue={defaultValue}
          maxLength={maxLength}
          ref={ref}
          value={value}
          onChange={handleChange}
        />
      </>
    );
  },
);
