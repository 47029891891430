import {
  Slide,
  toast,
  ToastContainer as RTToastContainer,
  ToastContainerProps,
} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHasWebViewUserAgent } from 'src/hooks/useHasWebViewUserAgent';
import { CloseToastButton } from './CloseToastButton';

export const ToastContainer = ({
  autoClose = 3000,
  ...props
}: ToastContainerProps) => {
  const isWebview = useHasWebViewUserAgent();
  const toastStyleProps = { style: {} };
  if (isWebview) {
    toastStyleProps.style = { top: 0 };
  }

  return (
    <RTToastContainer
      closeButton={CloseToastButton}
      containerId="toast-container"
      {...props}
      autoClose={autoClose}
      closeOnClick={false}
      draggable={false}
      hideProgressBar
      pauseOnFocusLoss={false}
      pauseOnHover={false}
      position={toast.POSITION.TOP_CENTER}
      {...toastStyleProps}
      theme="colored"
      transition={Slide}
    />
  );
};
