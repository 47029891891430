import { CommentOwnerHeaderProps } from './CommentOwnerHeader';
import { EventOwnerHeaderProps } from './EventOwnerHeader';
import i18next from '../../../i18n';

export const getCommentOwnerHeaderDisplayUsername = ({
  isOwned,
  ownerUsername,
}: Pick<CommentOwnerHeaderProps, 'isOwned' | 'ownerUsername'>) => {
  const who = isOwned ? i18next.t('me', { ns: 'common' }) : ownerUsername;
  return `${who} (Posted this Alert)`;
};

export const getEventOwnerHeaderDisplayUsername = ({
  isOwned,
  ownerUsername,
}: Pick<EventOwnerHeaderProps, 'isOwned' | 'ownerUsername'>) => {
  if (isOwned) {
    return i18next.t('me', { ns: 'common' });
  }
  return ownerUsername;
};
