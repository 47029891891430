import { Box, Divider, Flex, ArrowLeft } from '@amzn/ring-ui-react-components';
import React, { useCallback } from 'react';
import { Capitalizer } from 'src/shared/components/Capitalizer';

export interface PetProfileItemProps {
  Icon: React.ElementType;
  title: String;
  description: String;
  clickHandler?: () => void;
}

export const PetProfileItem = ({
  clickHandler,
  Icon,
  title,
  description,
  ...props
}: PetProfileItemProps) => {
  const shortText = useCallback((str: String) => {
    const max = 31;
    return str.length >= max ? str.substring(0, max) + '...' : str;
  }, []);

  return (
    <>
      <Flex
        {...props}
        justifyContent="space-between"
        alignItems="center"
        px={6}
        py={4}
        cursor="pointer"
        bgColor="white"
        onClick={clickHandler}
      >
        <Box display="flex">
          <Box mr={5} mb={3}>
            <Icon color="primary.500" boxSize={8} />
          </Box>
          <Box display="flex" flexDirection="column">
            <Box
              textStyle="body-large-medium"
              as="span"
              data-testid="pet-profile-item-title"
            >
              {title}
            </Box>
            <Box
              textStyle="body-normal-regular"
              as="span"
              color="neutral.300"
              data-testid="pet-profile-item-description"
            >
              <Capitalizer>{shortText(description)}</Capitalizer>
            </Box>
          </Box>
        </Box>
        {clickHandler && (
          <ArrowLeft transform="rotate(180deg)" boxSize="24px" />
        )}
      </Flex>
      <Divider />
    </>
  );
};
