export const calculateAge = (dateString: string): string => {
  const now = new Date();
  const yearNow = now.getFullYear();
  const monthNow = now.getMonth();
  const dateNow = now.getDate();

  const dob = new Date(
    Number(dateString.substring(0, 4)),
    Number(dateString.substring(5, 7)) - 1,
    Number(dateString.substring(8, 10)),
  );

  const yearDob = dob.getFullYear();
  const monthDob = dob.getMonth();
  const dateDob = dob.getDate();
  let ageString = '';
  let yearString = '';
  let monthString = '';
  let yearAge = yearNow - yearDob;
  let monthAge = 0;
  let dateAge = 0;

  if (monthNow >= monthDob) monthAge = monthNow - monthDob;
  else {
    yearAge--;
    monthAge = 12 + monthNow - monthDob;
  }

  if (dateNow >= dateDob) dateAge = dateNow - dateDob;
  else {
    monthAge--;
    dateAge = 31 + dateNow - dateDob;

    if (monthAge < 0) {
      monthAge = 11;
      yearAge--;
    }
  }

  const age = {
    years: yearAge,
    months: monthAge,
    days: dateAge,
  };

  if (age.years > 1) yearString = 'years';
  else yearString = 'year';
  if (age.months > 1) monthString = 'months';
  else monthString = 'month';

  if (age.years > 0) {
    ageString = `${age.years} ${yearString} old`;
  } else {
    ageString = `${age.months} ${monthString} old`;
  }
  return ageString;
};
