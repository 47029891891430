import { useIsMobile } from 'src/shared/hooks';
import { UnauthenticatedNavbarDesktop } from './UnauthenticatedNavbarDesktop';
import { UnauthenticatedNavbarMobile } from './UnauthenticatedNavbarMobile';

export const UnauthenticatedNavbar = () => {
  const isMobile = useIsMobile();

  return isMobile ? (
    <UnauthenticatedNavbarMobile />
  ) : (
    <UnauthenticatedNavbarDesktop />
  );
};
