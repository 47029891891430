import { border } from 'polished';
import { useClickAndKeyDownHandlers } from 'src/shared/hooks';
import { Box, BoxBasedComponentProps } from '../Box';

export type ReportModalFlagProps = BoxBasedComponentProps<
  'div',
  {
    disabled?: boolean;
    text: string;
    title: string;
  }
>;

export const ReportModalFlag = ({
  disabled = false,
  onClick,
  text,
  title,
  ...props
}: ReportModalFlagProps) => {
  const handlers = useClickAndKeyDownHandlers(onClick, disabled);

  return (
    <Box
      data-testid="report-modal-flag"
      {...border('1px', 'solid', 'borders')}
      {...handlers}
      aria-disabled={disabled}
      cursor="pointer"
      my={3}
      p={3}
      position="relative"
      tabIndex={0}
      transition="250ms ease-in-out"
      _hover={{
        borderColor: 'primary.500',
      }}
      _last={{
        marginBottom: 0,
      }}
      _disabled={{
        pointerEvents: 'none',
        opacity: 0.6,
      }}
      {...props}
    >
      <Box textStyle="body-normal-medium">{title}</Box>
      {text && (
        <Box mt={2} color="backup">
          {text}
        </Box>
      )}
    </Box>
  );
};
