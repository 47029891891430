import { getIsNpssUser } from 'src/auth';
import { config } from '../config';
import { history } from '../history';

const { RING_URL, NEIGHBORS_URL, NPSS_BASE_URL, NPSS_REDIRECTION_URL } = config;

const neighbors = 'neighbors';

export const ringNeighborsUrl = `${RING_URL}/${neighbors}`;

export const returnSearchParam = 'return';
export const commentSearchParam = 'comment';
export const webviewSearchParam = 'webview';

export const getLoginUrl = () => {
  if (window.location.href.includes(NPSS_REDIRECTION_URL)) {
    return NPSS_BASE_URL;
  }

  const path = `/users/sign_in?${returnSearchParam}=${neighbors}&path=${history.location.pathname}`;
  const origin = window.location.hostname.includes('localhost')
    ? window.location.origin
    : RING_URL;

  return `${origin}${path}`;
};

export const getLogoutUrl = () => {
  const isNpssUser = getIsNpssUser();
  if (isNpssUser) {
    return npssLogoutUrl;
  }
  return logoutUrl;
};

export const getWebviewLoginUrl = (
  init?: ConstructorParameters<typeof URLSearchParams>[0],
) => {
  const loginUrl = `${RING_URL}/mobile/authorize?${returnSearchParam}=${NEIGHBORS_URL}${history.location.pathname}`;

  if (!init) {
    return loginUrl;
  }

  const params = new URLSearchParams(init).toString();

  return `${loginUrl}&${params}`;
};

export const logoutUrl = `${RING_URL}/users/sign_out`;

export const npssLogoutUrl = `${NPSS_BASE_URL}/users/sign_out`;

const getAuthNavigationHandlers = () => ({
  goToLogin: () => {
    window.location.href = getLoginUrl();
  },
  goToLogout: () => {
    window.sessionStorage.clear();
    window.location.href = getLogoutUrl();
  },
  reLoginWebview: () => {
    window.location.replace(getWebviewLoginUrl());
  },
});

export const { goToLogin, goToLogout, reLoginWebview } =
  getAuthNavigationHandlers();
