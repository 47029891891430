import GET_USER_EVENTS_HISTORY from '@amzn/ring-neighbors-api-orchestrator-gql-schema/operations/authenticated/me/getUserEventsHistory.graphql';
import { useQuery } from '@apollo/client';
import { useIsNpssUser } from 'src/auth';
import { DeepNonNullable } from 'utility-types';
import type {
  GetUserEventsHistoryQuery,
  UserEventsHistory,
} from '@amzn/ring-neighbors-api-orchestrator-gql-schema';

type NonNullableUserEventsHistory = DeepNonNullable<UserEventsHistory>;

export const useGetUserEventsHistory = () => {
  const isNpssUser = useIsNpssUser();
  const { data, ...rest } = useQuery<GetUserEventsHistoryQuery>(
    GET_USER_EVENTS_HISTORY,
    {
      skip: isNpssUser,
    },
  );

  const eventsHistory = (
    data ? data.me!.events_history : initialEventsHistory
  ) as NonNullableUserEventsHistory;

  return { data: eventsHistory, ...rest };
};

const initialEventsHistory = { approved: [], pending: [], rejected: [] };
