import { Link } from '@amzn/ring-ui-react-components';
import { useLocation } from 'react-router-dom';

import {
  getViewFromRoutePath,
  useNeighborsWebClickEventStreamDispatcher,
} from 'src/components/App/hooks';
import { OwnerHeaderBase, OwnerHeaderBaseProps } from './OwnerHeaderBase';
import { getEventOwnerHeaderDisplayUsername } from './utils';

export interface EventOwnerHeaderProps extends OwnerHeaderBaseProps {
  ownerUsername: string;
  profileUrl?: string;
}

export const EventOwnerHeader = ({
  isOwned,
  ownerUsername,
  profileUrl,
  ...props
}: EventOwnerHeaderProps) => {
  const location = useLocation();
  const viewType = getViewFromRoutePath(location.pathname);
  const dispatchClick = useNeighborsWebClickEventStreamDispatcher(viewType);
  const displayUsername = getEventOwnerHeaderDisplayUsername({
    isOwned,
    ownerUsername,
  });

  return (
    <OwnerHeaderBase {...props} isOwned={isOwned}>
      {profileUrl ? (
        <Link
          target="_blank"
          href={profileUrl}
          textStyle="textlink-large-medium"
          onClick={() => {
            dispatchClick?.(
              'nhWeb_tappedNpssAgencyName',
              [props.publisherData?.publisher_id as string],
              {},
            );
          }}
        >
          {displayUsername}
        </Link>
      ) : (
        displayUsername
      )}
    </OwnerHeaderBase>
  );
};
