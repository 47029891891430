import { ResponsiveValue, Space } from 'src/shared/theme';
import { Box } from '../Box';
import { Button } from '../Button';
import { Icon } from '../Icon';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from '../Modal';

export const SettingsModal = ({ onClose, ...props }: ModalProps) => (
  <Modal {...props} onClose={onClose} isCentered size="xlg">
    <ModalOverlay>
      <ModalContent p={0}>
        <ModalHeader justifyContent="center" width={1}>
          Settings
        </ModalHeader>
        <ModalCloseButton color="neutral.500" />
        <ModalBody py={7} px={px} textAlign="left">
          <Icon color="primary.500" name="logo" size={40} mb={4} />
          <Box
            textStyle={{
              _: 'heading-small-medium',
              m: 'heading-normal-medium',
            }}
            mb={2}
          >
            To adjust settings, open Neighbors mobile app.
          </Box>
          <Box textStyle="body-large-light">
            If you don't have it yet, search for "Neighbors by Ring" in the app
            store for your mobile device.
          </Box>
        </ModalBody>
        <ModalFooter borderTopWidth={0} px={px} py={py} pt={0}>
          <Button
            as="a"
            size="lg"
            variant="primary"
            onClick={onClose}
            width={1}
          >
            Got it
          </Button>
        </ModalFooter>
      </ModalContent>
    </ModalOverlay>
  </Modal>
);

const py = { _: 6, m: 7 } as ResponsiveValue<Space>;
const px = { _: 6, m: 8 } as ResponsiveValue<Space>;
