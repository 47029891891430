import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSetActiveEventId } from 'src/apollo';
import {
  getViewFromRoutePath,
  useNeighborsWebClickEventStreamDispatcher,
} from 'src/components/App/hooks';
import {
  useEventCardHandlers,
  UseEventCardHandlersProps,
} from 'src/components/EventCard';
import { getEventDetailPath } from 'src/routes';
import { commentSearchParam } from 'src/utils';
import { trackEventWithHeap } from 'src/utils/heap';

interface UseFeedEventCardHandlersProps extends UseEventCardHandlersProps {
  publisherId: string;
}

export const useFeedEventCardHandlers = (
  props: UseFeedEventCardHandlersProps,
) => {
  const { eventId, publisherId } = props;

  const history = useHistory();
  const location = useLocation();
  const viewType = getViewFromRoutePath(location.pathname);
  const dispatchClick = useNeighborsWebClickEventStreamDispatcher(viewType);

  const { handlers: eventCardHandlers, states: eventCardStates } =
    useEventCardHandlers(props);

  const [setActiveEventId, removeActiveEventId] = useSetActiveEventId();

  const feedEventCardHandlers = useMemo(() => {
    const path = getEventDetailPath(eventId);

    return {
      onClick: () => {
        trackEventWithHeap('Feed Card Click');
        dispatchClick?.('nhWeb_tappedPost', [publisherId], {});

        history.push(path);
      },
      onComment: () => {
        history.push(`${path}?${commentSearchParam}=true`);
      },
      onMouseEnter: () => {
        setActiveEventId(eventId);
      },
      onMouseLeave: removeActiveEventId,
    };
  }, [
    dispatchClick,
    eventId,
    history,
    publisherId,
    removeActiveEventId,
    setActiveEventId,
  ]);

  return {
    handlers: {
      ...eventCardHandlers,
      ...feedEventCardHandlers,
    },
    states: {
      ...eventCardStates,
    },
  };
};
