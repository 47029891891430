import { useTimeout } from 'react-use';

export const useOpacityTransition = () => {
  const [isReady] = useTimeout(100); // fake delay to avoid sudden visibility changes

  return {
    transition: 'opacity 150ms ease-in-out',
    opacity: isReady() ? 1 : 0,
  };
};
