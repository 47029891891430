import { lazy, Suspense, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useLocation } from 'react-use';
import { Error404 } from 'src/components/Error';
import { useFeature } from 'src/context';
import { UnifiedMyPostsPage } from 'src/features/MyPosts/UnifiedMyPostsPage';
import { logContentSecurityPolicyViolation } from 'src/logs';
import { AppRoutes, pathWithWV } from 'src/routes';
import { CenteredLoading } from 'src/shared/components/Loading';
import { AppLayout } from './AppLayout';
import { ErrorBoundary } from './ErrorBoundary';
import { useThirdPartyAnalytics } from './hooks';
import { PrivateRoute } from '../Auth';

export const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export const App = () => {
  const enableUnifiedMyPostsPage = useFeature('ENABLE_UNIFIED_MY_POSTS_PAGE');
  useThirdPartyAnalytics();

  useEffect(() => {
    window.addEventListener(
      'securitypolicyviolation',
      logContentSecurityPolicyViolation,
    );

    return () => {
      window.removeEventListener(
        'securitypolicyviolation',
        logContentSecurityPolicyViolation,
      );
    };
  }, []);

  return (
    <AppLayout>
      <Suspense fallback={<CenteredLoading data-testid="loading-indicator" />}>
        <ErrorBoundary>
          <ScrollToTop />
          <Switch>
            <Route path={AppRoutes.DeniedPost} component={DeniedPostPage} />
            <PrivateRoute exact path={AppRoutes.Feed} component={FeedPage} />
            <PrivateRoute
              exact
              path={AppRoutes.EventCreate}
              component={PostingPage}
            />
            <PrivateRoute
              path={AppRoutes.MyPosts}
              component={
                enableUnifiedMyPostsPage ? UnifiedMyPostsPage : MyPostsPage
              }
            />
            <Route
              path={AppRoutes.EventDetailOrSharePage}
              component={EventPage}
            />

            <Route
              path={AppRoutes.BetterNeighborhoods}
              exact
              component={BetterNeighborhoodsApp}
            />
            <Route path={AppRoutes.Terms} exact component={Terms} />
            <Route path={AppRoutes.Deals} exact component={DealsApp} />
            <Route path={AppRoutes.PetTag} exact component={PetTagApp} />
            <Route
              path={pathWithWV(AppRoutes.PetProfile)}
              exact
              component={PetProfilePage}
            />
            <Route
              path={pathWithWV(AppRoutes.PetTagFlyer)}
              exact
              component={PetProfileFlyerPage}
            />
            <Route
              path={pathWithWV(AppRoutes.PetMarketingPage)}
              exact
              component={PetMarketingPage}
            />
            <Route
              path={AppRoutes.EmergencyInfo}
              exact
              component={EmergencyInfoApp}
            />
            <Route
              path={AppRoutes.EmergencyInfoUpdates}
              exact
              component={EmergencyInfoUpdates}
            />
            <Route
              path={AppRoutes.EmergencyInfoTopic}
              exact
              component={EmergencyInfoTopic}
            />
            <Route
              path={AppRoutes.EmergencyInfoTopics}
              exact
              component={EmergencyInfoTopics}
            />
            <Route
              path={pathWithWV(AppRoutes.BestOfRing)}
              component={BestOfRingApp}
            />
            <PrivateRoute
              path={AppRoutes.EmailSettings}
              exact
              component={EmailSettings}
            />
            <Route component={Error404} />
          </Switch>
        </ErrorBoundary>
      </Suspense>
    </AppLayout>
  );
};

const FeedPage = lazy(() =>
  import(/* webpackChunkName: "FeedPage" */ '../Feed').then(({ FeedPage }) => ({
    default: FeedPage,
  })),
);

const EventPage = lazy(() =>
  import(/* webpackChunkName: "EventPage" */ '../Event').then(
    ({ EventPage }) => ({ default: EventPage }),
  ),
);

const PostingPage = lazy(() =>
  import(/* webpackChunkName: "PostingPage" */ '../Posting').then(
    ({ PostingPage }) => ({ default: PostingPage }),
  ),
);

const BetterNeighborhoodsApp = lazy(() =>
  import(
    /* webpackChunkName: "BetterNeighborhoods" */ 'src/features/BetterNeighborhoods'
  ).then(({ App }) => ({
    default: App,
  })),
);

const MyPostsPage = lazy(() =>
  import(/* webpackChunkName: "MyPosts" */ 'src/features/MyPosts').then(
    ({ MyPostsPage }) => ({
      default: MyPostsPage,
    }),
  ),
);

const DealsApp = lazy(() =>
  import(/* webpackChunkName: "Deals" */ 'src/features/Deals').then(
    ({ App }) => ({
      default: App,
    }),
  ),
);

const PetTagApp = lazy(() =>
  import(/* webpackChunkName: "PetTag" */ 'src/features/PetTag').then(
    ({ App }) => ({
      default: App,
    }),
  ),
);

const PetProfilePage = lazy(() =>
  import(
    /* webpackChunkName: "PetProfile" */ 'src/features/PetTag/components/PetProfile'
  ).then(({ PetProfile }) => ({
    default: PetProfile,
  })),
);

const PetMarketingPage = lazy(() =>
  import(
    /* webpackChunkName: "PetMarketing" */ 'src/features/PetTag/components/MarketingPage'
  ).then(({ MarketingPage }) => ({
    default: MarketingPage,
  })),
);

const PetProfileFlyerPage = lazy(() =>
  import(/* webpackChunkName: "PetFlyer" */ 'src/features/PetTag').then(
    ({ FlyerApp }) => ({
      default: FlyerApp,
    }),
  ),
);

const EmergencyInfoApp = lazy(() =>
  import(
    /* webpackChunkName: "EmergencyInfo" */ 'src/features/EmergencyInfo'
  ).then(({ App }) => ({
    default: App,
  })),
);

/*
const EmergencyInfoMap = lazy(() =>
  import('src/features/EmergencyInfo').then(({ MapApp }) => ({
    default: MapApp,
  })),
);
*/

const EmergencyInfoUpdates = lazy(() =>
  import('src/features/EmergencyInfo').then(({ RssFeedPage }) => ({
    default: RssFeedPage,
  })),
);

const EmergencyInfoTopic = lazy(() =>
  import('src/features/EmergencyInfo').then(({ TopicApp }) => ({
    default: TopicApp,
  })),
);

const EmergencyInfoTopics = lazy(() =>
  import('src/features/EmergencyInfo').then(({ TopicsApp }) => ({
    default: TopicsApp,
  })),
);

const Terms = lazy(() =>
  import(/* webpackChunkName: "Terms" */ 'src/features/Terms').then(
    ({ App }) => ({
      default: App,
    }),
  ),
);

const BestOfRingApp = lazy(() =>
  import(
    /* webpackChunkName: "BestOfRingApp" */ 'src/features/BestOfRing'
  ).then(({ BestOfRingApp }) => ({
    default: BestOfRingApp,
  })),
);

const DeniedPostPage = lazy(() =>
  import(
    /* webpackChunkName: "DeniedPostPage" */ 'src/features/DeniedPost'
  ).then(({ DeniedPostPage }) => ({
    default: DeniedPostPage,
  })),
);

const EmailSettings = lazy(() =>
  import('src/features/EmailSettings').then(({ EmailSettings }) => ({
    default: EmailSettings,
  })),
);
