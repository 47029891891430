import { useMemo } from 'react';
import { useUserAgentParser } from 'src/hooks';

const POSSIBLE_ANDROID_OS = ['Android', 'Android[-x86]'];

export const useIsAndroidOS = () => {
  const UAParser = useUserAgentParser();

  const OSName = useMemo(() => UAParser?.getOS()?.name, [UAParser]);
  const isAndroid = useMemo(
    () =>
      OSName !== undefined ? POSSIBLE_ANDROID_OS.includes(OSName) : undefined,
    [OSName],
  );

  return isAndroid;
};
