import {
  NavbarItem,
  NavbarLink,
  NavbarNav,
  NavLinkProps,
} from '@amzn/ring-ui-react-components';
import { border } from 'polished';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { config } from 'src/config';
import { useAlertAreaDispatch, useAlertAreaState, useAuth } from 'src/context';
import { useOpacityTransition } from 'src/hooks';
import { Box, BoxBasedComponentProps } from 'src/shared/components/Box';
import {
  Dropdown,
  DropdownItem,
  DropdownLinkItem,
  DropdownMenu,
  DropdownMenuProps,
  DropdownToggle,
} from 'src/shared/components/Dropdown';
import { Icon } from 'src/shared/components/Icon';
import { logoutUrl } from 'src/utils';
import { NavbarBase, RingShopNavbarItem } from './NavbarBase';
import { AuthenticatedNavbarProps } from './types';

const { RING_DASHBOARD_URL } = config;

export const AuthenticatedNavbarDesktop = ({
  onClickMyPosts,
  onClickSettings,
  onSelectedAreaChanged,
  dispatchEventStreamClick,
}: AuthenticatedNavbarProps) => {
  const { t } = useTranslation('components.navbar', { useSuspense: false });
  const { alertAreas, selectedAlertArea } = useAlertAreaState();
  const setSelectedAlertAreaId = useAlertAreaDispatch();
  const { isPending } = useAuth();

  const transitionProps = useOpacityTransition();

  return (
    <NavbarBase>
      <NavbarNav {...transitionProps} flex={1} justify="flex-end">
        <NavbarItem data-testid="alert-area-item">
          {selectedAlertArea && (
            <Dropdown openOnMouseEnter height="100%">
              <DropdownToggle>
                <SelectableNavbarDropdownLink>
                  <Icon
                    data-testid="location-icon"
                    aria-hidden="true"
                    focusable="false"
                    mb={1}
                    name="home"
                    size={20}
                  />
                  {selectedAlertArea.details.name}
                </SelectableNavbarDropdownLink>
              </DropdownToggle>
              <NavbarDropdownMenu minWidth={230}>
                {alertAreas.map(
                  ({
                    id,
                    details: { name },
                    location: {
                      address: { formatted_address },
                    },
                  }) => {
                    const isSelected = selectedAlertArea.id === id;

                    const handleClick = () => {
                      onSelectedAreaChanged();
                      setSelectedAlertAreaId(id);
                    };

                    return (
                      <DropdownAlertAreasItem
                        isSelected={isSelected}
                        key={id}
                        onClick={handleClick}
                      >
                        <Box mb={1} textStyle="body-large-medium">
                          {name}
                        </Box>
                        <Box textStyle="body-normal-light">
                          {formatted_address}
                        </Box>
                      </DropdownAlertAreasItem>
                    );
                  },
                )}
              </NavbarDropdownMenu>
            </Dropdown>
          )}
        </NavbarItem>
        <NavbarItem data-testid="account-item">
          <Dropdown openOnMouseEnter height="100%">
            <DropdownToggle>
              <SelectableNavbarDropdownLink>
                <Icon
                  aria-hidden="true"
                  focusable="false"
                  mb={1}
                  name="account"
                  size={20}
                />
                {t('account')}
              </SelectableNavbarDropdownLink>
            </DropdownToggle>
            <NavbarDropdownMenu>
              <DropdownLinkItem
                onClick={() => {
                  if (isPending !== true)
                    dispatchEventStreamClick?.('nhWeb_myAccount', [], {
                      itemType: 'button',
                    });
                }}
                href={RING_DASHBOARD_URL}
              >
                {t('account')}
              </DropdownLinkItem>
              <DropdownLinkItem
                onClick={() => {
                  if (isPending !== true)
                    dispatchEventStreamClick?.('nhWeb_myPosts', [], {
                      itemType: 'button',
                    });
                  onClickMyPosts();
                }}
              >
                {t('my-posts')}
              </DropdownLinkItem>
              <DropdownLinkItem
                onClick={() => {
                  if (isPending !== true)
                    dispatchEventStreamClick?.('nhWeb_settings', [], {
                      itemType: 'button',
                    });
                  onClickSettings();
                }}
              >
                {t('settings')}
              </DropdownLinkItem>
              <DropdownLinkItem href={logoutUrl}>
                {t('sign-out')}
              </DropdownLinkItem>
            </NavbarDropdownMenu>
          </Dropdown>
        </NavbarItem>
        <RingShopNavbarItem
          dispatchEventStreamClick={dispatchEventStreamClick}
        />
      </NavbarNav>
    </NavbarBase>
  );
};

const SelectableNavbarDropdownLink = forwardRef<
  HTMLAnchorElement,
  NavLinkProps
>((props: NavLinkProps, ref) => (
  <NavbarLink
    {...props}
    outline="none"
    position="relative"
    ref={ref}
    textStyle="footnote-xsmall-medium"
    _after={{
      backgroundColor: 'primary.500',
      bottom: 0,
      content: 'none',
      height: 1,
      left: 0,
      position: 'absolute',
      right: 0,
      width: '100%',
    }}
    _focus={{
      color: 'primary.500',
      '::after': {
        content: '""',
      },
    }}
    _expanded={{
      '::after': {
        content: '""',
      },
    }}
  />
));

const NavbarDropdownMenu = (props: DropdownMenuProps) => (
  <DropdownMenu
    {...props}
    border="none"
    borderRadius="0 0 4px 4px"
    boxShadow="dropDown"
    right="50%"
    top="calc(100% + 1px)"
    transform="translateX(50%)"
    css={{
      '& > *': {
        padding: 0,
      },
    }}
  />
);

type DropdownAlertAreasItemProps = BoxBasedComponentProps<
  'div',
  { isSelected: boolean }
>;

const DropdownAlertAreasItem = ({
  isSelected,
  ...props
}: DropdownAlertAreasItemProps) => (
  <DropdownItem
    {...props}
    {...border('bottom', '1px', 'solid', 'borders')}
    bg={isSelected ? 'primary.100' : undefined}
    disabled={isSelected}
    display="flex"
    flexDirection="column"
    px={6}
    py={3}
    sx={{
      '&:not(:hover):not(:focus) > :first-child': {
        color: isSelected ? 'primary.500' : undefined,
      },
      '&:last-child': {
        border: 'none',
      },
    }}
  />
);
