import {
  equalsDefaultCoordsTuple,
  getDistanceInMiles,
  GetDistanceInMilesProps,
} from 'src/shared/utils';
import { Box, BoxBasedComponentProps } from '../Box';

export type DistanceProps = BoxBasedComponentProps<
  'div',
  GetDistanceInMilesProps
>;

export const Distance = ({ from, to, ...props }: DistanceProps) => {
  if (equalsDefaultCoordsTuple(to)) {
    return null;
  }

  const miles = getDistanceInMiles({ from, to });
  const rounded = Math.round(miles * 10) / 10;

  return (
    <Box {...props} tabIndex={0}>
      {rounded}mi
    </Box>
  );
};
