import { defaultBorder, Flex, FlexProps } from '@amzn/ring-ui-react-components';
import { forwardRef } from 'react';
import { isLocalBuild } from 'src/utils';

export interface CardProps extends FlexProps {
  hoverable?: boolean;
  handleAuthContext?: () => void;
}

export const Card = forwardRef<HTMLDivElement, CardProps>(
  ({ hoverable = true, _hover = hoverStyles, ...props }, ref) => (
    <Flex
      {...defaultBorder}
      bg="white"
      borderRadius="medium"
      direction="column"
      ref={ref}
      wordWrap="break-word"
      {...props}
      _hover={hoverable ? _hover : undefined}
    />
  ),
);

if (isLocalBuild) {
  Card.displayName = 'Card';
}

export const hoverStyles = {
  borderColor: 'primary.500',
};
