import { useIsDesktop } from './useIsDesktop';
import { useIsMobile } from './useIsMobile';

import { useIsTablet } from './useIsTablet';

export const useBreakpoints = () => {
  const isDesktop = useIsDesktop();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();

  return {
    isDesktop,
    isMobile,
    isTablet,
  };
};
