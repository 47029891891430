import { Box, BoxProps, Text, TextProps } from '@amzn/ring-ui-react-components';
import { ReactNode, useCallback, useState } from 'react';

export interface ClampProps extends TextProps {
  more?: ReactNode;
  less?: ReactNode;
  wrapperProps?: BoxProps;
}

export const Clamp = ({
  noOfLines,
  more,
  less,
  children,
  wrapperProps,
  ...props
}: ClampProps) => {
  const [isClamped, setIsClamped] = useState(false);

  const ref = useCallback((node: HTMLDivElement | null) => {
    if (!node) {
      return;
    }

    const overflow = hasOverflow(node);

    setIsClamped(overflow);
  }, []);

  return (
    <Box {...wrapperProps}>
      <Text {...props} ref={ref} noOfLines={noOfLines}>
        {children}
      </Text>
      {isClamped ? (noOfLines ? more : less) : null}
    </Box>
  );
};

const hasOverflow = ({ scrollHeight, clientHeight }: HTMLDivElement) =>
  scrollHeight > clientHeight;
