import { useMemo } from 'react';
import { EventMediaType } from './types';
import type { MediaItem } from '@amzn/ring-neighbors-api-orchestrator-gql-schema';

export const useMediaItems = (items: MediaItem[] = []) => {
  const mediaItems = useMemo(
    () =>
      items.map(({ url, type, source_info }: MediaItem, index: number) => ({
        index,
        original: url,
        thumbnail: url,
        type: type as EventMediaType,
        source_info: source_info,
      })),
    [items],
  );

  return mediaItems;
};

export type MediaPathItem = ReturnType<typeof useMediaItems>[0];
