import { useDisclosure } from '@amzn/ring-ui-react-components';
import { ItemType as ItemTypeEventStream } from 'src/components/App/hooks';
import {
  usePushToFeed,
  usePushToHistory,
  useRouteMatchAsBoolean,
} from 'src/hooks';
import { AppRoutes } from 'src/routes';
import { SettingsModal } from 'src/shared/components/SettingsModal';
import { useIsMobile } from 'src/shared/hooks';
import { AuthenticatedNavbarDesktop } from './AuthenticatedNavbarDesktop';
import { AuthenticatedNavbarMobile } from './AuthenticatedNavbarMobile';

export const AuthenticatedNavbar = ({
  dispatchEventStreamClick,
}: {
  dispatchEventStreamClick?: (
    item: ItemTypeEventStream,
    tags?: string[],
  ) => Promise<void>;
}) => {
  const isMobile = useIsMobile();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const isEventDetailPage = useRouteMatchAsBoolean(
    AppRoutes.EventDetailOrSharePage,
  );

  const pushToFeedIfIsEventPage = usePushToFeed(isEventDetailPage);

  const pushToMyPosts = usePushToHistory(AppRoutes.MyPosts);

  const navbarProps = {
    onClickMyPosts: pushToMyPosts,
    onClickSettings: onOpen,
    onSelectedAreaChanged: pushToFeedIfIsEventPage,
  };

  return (
    <>
      {isMobile ? (
        <AuthenticatedNavbarMobile
          {...{ ...navbarProps, dispatchEventStreamClick }}
        />
      ) : (
        <AuthenticatedNavbarDesktop
          {...{ ...navbarProps, dispatchEventStreamClick }}
        />
      )}
      <SettingsModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};
