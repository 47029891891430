import { Box, Button, Flex } from '@amzn/ring-ui-react-components';
import { useTranslation } from 'react-i18next';

interface NoPostsProps {
  onClick: () => void;
}

export const NoPosts = ({ onClick }: NoPostsProps) => {
  const { t } = useTranslation('features.myPosts');

  return (
    <Flex
      direction="column"
      align="center"
      maxWidth={500}
      data-testid="no-posts"
    >
      <img
        src="/assets/images/Neighbors_Sidewalk.png"
        alt="Neighbors Sidewalk"
      />
      <Box
        color="neutral.500"
        mb={2}
        textStyle={{ base: 'heading-small-medium', m: 'heading-normal-medium' }}
        data-testid="no-posts-title"
      >
        {t('no-posts.title')}
      </Box>
      <Box
        color="neutral.300"
        mb={6}
        textAlign="center"
        textStyle="body-large-light"
        data-testid="no-posts-description"
      >
        {t('no-posts.description')}
      </Box>
      <Button onClick={onClick} data-testid="create-post">
        {t('create-post')}
      </Button>
    </Flex>
  );
};
