import {
  useDisclosure,
  UseDisclosureProps,
} from '@amzn/ring-ui-react-components';
import { useRef } from 'react';
import useOnClickOutside from 'use-onclickoutside';

export interface UseDropdownProps
  extends Pick<UseDisclosureProps, 'isOpen' | 'onClose'> {
  closeOnEsc?: boolean;
  closeOnSelect?: boolean;
  openOnMouseEnter?: boolean;
}

export const useDropdown = ({
  closeOnEsc = true,
  closeOnSelect = true,
  isOpen: isOpenProp,
  onClose: onCloseProp,
  openOnMouseEnter = false,
}: UseDropdownProps) => {
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure({
    isOpen: isOpenProp,
    onClose: onCloseProp,
  });

  const menuRef = useRef<HTMLDivElement>(null);
  const toggleRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(menuRef, (event) => {
    if (isOpen && !toggleRef.current?.contains(event.target as HTMLElement)) {
      onClose();
    }
  });

  return {
    closeOnEsc,
    closeOnSelect,
    isOpen,
    menuRef,
    onClose,
    onOpen,
    onToggle,
    openOnMouseEnter,
    toggleRef,
  };
};

export type UseDropdownReturn = ReturnType<typeof useDropdown>;
