import { useRouteMatch } from 'react-router-dom';

export const useRouteMatchAsBoolean = (
  ...args: Parameters<typeof useRouteMatch>
) => {
  const match = useRouteMatch(...args);
  const result = Boolean(match);

  return result;
};
