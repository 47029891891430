import css, { SystemStyleObject } from '@styled-system/css';
import type { ThemeProps } from 'src/shared/theme';
import type { Falsy } from 'utility-types';

export interface SxProps {
  sx?: (SystemStyleObject | Falsy)[] | SystemStyleObject | Falsy;
}

export const sx = ({ sx: sxProp, theme }: SxProps & ThemeProps) => {
  if (!sxProp) {
    return;
  }

  const sx = Array.isArray(sxProp)
    ? (sxProp
        .flat(Infinity)
        .filter(Boolean)
        .map((style) => (typeof style === 'function' ? style(theme) : style))
        .reduce((acc, val) => ({ ...acc, ...val }), {}) as SystemStyleObject)
    : sxProp;

  return css(sx)(theme);
};
