export const readFileAsArrayBuffer = (file: File) =>
  new Promise<FileReader['result']>((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = () => {
      reject(reader.error);
    };

    reader.readAsArrayBuffer(file);
  });

interface UploadFileProps {
  url: string;
  onProgress: XMLHttpRequestEventTarget['onprogress'];
  body: any;
}

export const uploadFile = ({ url, body, onProgress }: UploadFileProps) =>
  new Promise<ProgressEvent<EventTarget>>((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.onload = resolve;

    xhr.onerror = reject;

    xhr.upload.onprogress = onProgress;

    xhr.open('PUT', url);

    xhr.send(body);
  });
