import { border, transitions } from 'polished';
import { BaseColor, colors } from './colors';

const white = colors['white'];
const transparent = colors['transparent'];
const transparentBorder = border('1px', 'solid', transparent);

const transitionDuration = '.15s';

const transition = transitions(
  `color ${transitionDuration} ease-in-out`,
  `background-color ${transitionDuration} ease-in-out`,
  `border-color ${transitionDuration} ease-in-out`,
  `box-shadow ${transitionDuration} ease-in-out`,
);

const base = (backgroundColor: BaseColor) => ({
  ...transition,
  ...transparentBorder,
  backgroundColor: colors[backgroundColor][500],
  color: white,
  '&:hover': {
    backgroundColor: colors[backgroundColor]['hover'],
  },
});

const inverted = (color: BaseColor) => ({
  ...transition,
  ...transparentBorder,
  backgroundColor: white,
  color: colors[color][500],
});

const outline = (color: BaseColor) => ({
  ...transition,
  ...border('1px', 'solid', colors[color][500]),
  backgroundColor: transparent,
  color: colors[color][500],
  '&:hover': {
    backgroundColor: colors[color][500],
    color: white,
  },
});

const baseColors = [
  'primary',
  'secondary',
  'positive',
  'negative',
] as BaseColor[];

const extras = {
  empty: {
    backgroundColor: transparent,
    border: 'none',
    color: colors['primary'][500],
  },
} as const;

type InvertedColor = `inverted-${BaseColor}`;
type OutlineColor = `outline-${BaseColor}`;

type BaseReturnType = ReturnType<typeof base>;
type InvertedReturnType = ReturnType<typeof inverted>;
type OutlineReturnType = ReturnType<typeof outline>;

type ColorVariants = {
  [_ in BaseColor]: BaseReturnType;
} &
  { [_ in InvertedColor]: InvertedReturnType } &
  { [_ in OutlineColor]: OutlineReturnType };

const variants = baseColors.reduce((acc, c) => {
  acc[c] = base(c);
  acc[`inverted-${c}` as InvertedColor] = inverted(c);
  acc[`outline-${c}` as OutlineColor] = outline(c);

  return acc;
}, {} as ColorVariants);

export const buttons = {
  ...variants,
  ...extras,
} as const;
