import { Box, BoxBasedComponentProps } from '../Box';
import { Icon, IconName } from '../Icon';

type VideoButtonProps = BoxBasedComponentProps<
  'div',
  {
    icon: IconName;
    size?: number;
  }
>;

const VideoPlayerButton = ({ icon, size, ...props }: VideoButtonProps) => (
  <Box
    zIndex="videoPlayerButton"
    {...props}
    cursor="pointer"
    left="50%"
    position="absolute"
    top="50%"
    transform="translate(-50%, -50%) scale(1.75)"
    backgroundColor="rgba(0,0,0,.65)"
    borderRadius="circle"
  >
    <Icon
      color="whiteAlpha.900"
      name={icon}
      size={size || 32}
      transform="scale(.5714)"
    />
  </Box>
);

export const PlayButton = (props: Omit<VideoButtonProps, 'icon'>) => (
  <VideoPlayerButton {...props} icon="play" data-testid="play-control" />
);

export const PauseButton = (props: Omit<VideoButtonProps, 'icon'>) => (
  <VideoPlayerButton {...props} icon="pause" data-testid="pause-control" />
);
