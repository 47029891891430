import {
  MutationNpssScheduledPostUpdateArgs,
  NpssScheduledPostUpdateInput,
  NpssScheduledPostUpdateMutation,
} from '@amzn/ring-neighbors-api-orchestrator-gql-schema';
import NPSS_SCHEDULED_POST_UPDATE from '@amzn/ring-neighbors-api-orchestrator-gql-schema/operations/authenticated/npss/npssScheduledPostUpdate.graphql';
import NPSS_USER_SCHEDULED_POSTS from '@amzn/ring-neighbors-api-orchestrator-gql-schema/operations/authenticated/npss/npssUserScheduledPosts.graphql';
import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

export const useEventSchedulePostUpdate = (
  handleOnCompleted: () => void,
  handleOnError: () => void,
) => {
  const [updateNpssScheduledPostMutation] = useMutation<
    NpssScheduledPostUpdateMutation,
    MutationNpssScheduledPostUpdateArgs
  >(NPSS_SCHEDULED_POST_UPDATE, {
    refetchQueries: [NPSS_USER_SCHEDULED_POSTS],
    onCompleted() {
      handleOnCompleted();
    },
    onError() {
      handleOnError();
    },
  });

  return useCallback(
    async ({
      id,
      title,
      description,
      scheduled_for,
    }: NpssScheduledPostUpdateInput) => {
      await updateNpssScheduledPostMutation({
        variables: {
          input: {
            id,
            title,
            description,
            scheduled_for,
          },
        },
      });
    },
    [updateNpssScheduledPostMutation],
  );
};
