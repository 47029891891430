import {
  extendTheme,
  theme as baseTheme,
} from '@amzn/ring-ui-react-components';
import { components } from './components';
import { config } from './config';
import { styles } from './styles';
import { zIndices } from './zIndices';

export const theme = extendTheme(
  {
    config,
    components,
    styles,
    zIndices,
  },
  baseTheme,
);
