import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { usePushToFeedIfAuthenticated, useAuth } from 'src/context';
import { routes } from 'src/features/BestOfRing/routes';
import { logBugsnag, SEVERITY } from 'src/logs/utils';
import { Box } from 'src/shared/components/Box';
import { Error, ErrorProps } from 'src/shared/components/Error';
import { Icon } from 'src/shared/components/Icon';

const Copy404 = (
  //TODO: how to localize this line?
  <Box>
    Sorry that page doesn't
    <Icon aria-label="Ring" name="ring-logo" size={52} mx={2} mb={1} />a bell.
  </Box>
);

export const Error404 = ({ onClick }: Pick<ErrorProps, 'onClick'>) => {
  const handleClick = usePushToFeedIfAuthenticated(onClick);
  const { isAuthenticated } = useAuth();

  const { t } = useTranslation('components.error');

  return (
    <Error
      error={Copy404}
      details={t('message.404-details')}
      onClick={isAuthenticated ? handleClick : undefined}
      buttonText={t('back-to-feed')}
      data-testid="error-404"
    />
  );
};

export const Error500 = ({ onClick }: Pick<ErrorProps, 'onClick'>) => {
  const handleClick = usePushToFeedIfAuthenticated(onClick);
  const { t } = useTranslation('components.error');

  useEffect(() => {
    logBugsnag(SEVERITY.error, '500 internal server error');
  }, []);

  return (
    <Error
      error={t('message.500-error')}
      details={t('message.500-details')}
      onClick={handleClick}
      buttonText={t('back-to-feed')}
      data-testid="error-500"
    />
  );
};

interface ErrorDefaultProps {
  error: Error;
  onClick: () => void;
}

export const ErrorDefault = ({ error, onClick }: ErrorDefaultProps) => {
  const handleClick = usePushToFeedIfAuthenticated(onClick);
  const isBestOfRing = useRouteMatch(routes.BestOfRingFeed);
  const { t } = useTranslation('components.error');

  useEffect(() => {
    logBugsnag(SEVERITY.error, error.message);
  }, [error.message]);

  return (
    <Error
      error={isBestOfRing ? t('message.retry-error') : t('message.500-error')}
      details={
        isBestOfRing ? t('message.retry-details') : t('message.500-details')
      }
      onClick={isBestOfRing ? () => window.location.reload() : handleClick}
      buttonText={isBestOfRing ? t('retry') : t('back-to-feed')}
      data-testid="error-default"
    />
  );
};
