import { useEffect, useRef } from 'react';

const DEFAULT_THRESHOLD = 0.5; // only observe when the 50% of the component is in the viewport

export interface UseOnScreenProps {
  callback: IntersectionObserverCallback;
  threshold?: number;
}

export const useOnScreen = (
  callback: IntersectionObserverCallback,
  threshold = DEFAULT_THRESHOLD,
) => {
  const triggerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!triggerRef.current) {
      return;
    }

    const observer = new IntersectionObserver(callback, {
      threshold: threshold,
    });

    observer.observe(triggerRef.current);

    return () => observer.disconnect();
  }, [callback, triggerRef, threshold]);

  return triggerRef;
};
