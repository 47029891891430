import { pipe } from 'ramda';
import { FunctionComponent, SyntheticEvent, useMemo, useState } from 'react';
import { AspectRatio, AspectRatioProps } from '../components/AspectRatio';
import { isRoughly16by9 } from '../utils';

interface AspectRatioDimensions {
  width: number;
  height: number;
}

export const withAspectRatio =
  <
    P extends Pick<
      AspectRatioProps,
      'className' | 'borderRadius' | 'height' | 'maxHeight'
    >,
    E extends HTMLImageElement | HTMLVideoElement,
    L extends 'onLoad' | 'onLoadedMetadata',
  >(
    Component: FunctionComponent<P>,
    getDimensions: (event: SyntheticEvent<E>) => AspectRatioDimensions,
    onLoadPropName: L,
  ) =>
  ({ className, borderRadius, height, maxHeight, ...props }: P) => {
    const [dimensions, setDimensions] =
      useState<AspectRatioDimensions | null>(null);

    const bg =
      !dimensions || !isRoughly16by9(dimensions.width, dimensions.height)
        ? 'black'
        : undefined;

    const componentProps = useMemo(
      () => ({ [onLoadPropName]: pipe(getDimensions, setDimensions) }),
      [],
    );

    return (
      <AspectRatio
        bg={bg}
        borderRadius={borderRadius}
        className={className}
        height={height}
        maxHeight={maxHeight}
        overflow="hidden"
      >
        <Component {...(props as P)} {...componentProps} />
      </AspectRatio>
    );
  };
