import {
  GetPetProfileByIdOpenQuery,
  GetPetProfileByIdQuery,
  Maybe,
} from '@amzn/ring-neighbors-api-orchestrator-gql-schema';
import GET_PET_PROFILE_BY_ID_AUTH from '@amzn/ring-neighbors-api-orchestrator-gql-schema/operations/authenticated/PetTag/getPetProfileById.graphql';
import GET_PET_PROFILE_BY_ID from '@amzn/ring-neighbors-api-orchestrator-gql-schema/operations/open/getPetProfileByIdOpen.graphql';
import { useQueryWithBoundary, shouldIgnorePetNotFound } from 'src/hooks';

const KILOGRAMS_PER_POUND = 0.45359237;

export const formatWeight = (weightInKg: Maybe<number> | undefined) => {
  if (weightInKg) {
    const weightInPounds = weightInKg / KILOGRAMS_PER_POUND;
    return `${Math.round(weightInPounds)} lbs`;
  }
  return null;
};

const mapProfile = (rawData: GetPetProfileByIdOpenQuery | undefined) => {
  if (rawData && rawData.petProfileById) {
    return {
      media: rawData.petProfileById.details.media,
      name: rawData.petProfileById.details.name,
      type: rawData.petProfileById.details.species,
      breed: rawData.petProfileById.details.breed || '',
      gender: rawData.petProfileById.details.gender || '',
      dateOfBirth: rawData.petProfileById.details.date_of_birth,
      weight: formatWeight(rawData.petProfileById.details.weight),
      color: rawData.petProfileById.details.color || '',
      medicalInfo: rawData.petProfileById.details.medical_info || '',
      otherInfo: rawData.petProfileById.details.additional_info || '',
      phoneId: rawData.petProfileById.owner.phone_id || '',
      scheme: rawData.petProfileById.customized_info?.scheme || 'blue',
      message: rawData.petProfileById.customized_info?.message || '',
      ownerId: '',
    };
  }
  return null;
};

const mapProfileAuth = (rawData: GetPetProfileByIdQuery | undefined) => {
  if (rawData && rawData.petProfileById) {
    return {
      media: rawData.petProfileById.details.media,
      name: rawData.petProfileById.details.name,
      type: rawData.petProfileById.details.species,
      breed: rawData.petProfileById.details.breed || '',
      gender: rawData.petProfileById.details.gender || '',
      dateOfBirth: rawData.petProfileById.details.date_of_birth,
      weight: formatWeight(rawData.petProfileById.details.weight),
      color: rawData.petProfileById.details.color || '',
      medicalInfo: rawData.petProfileById.details.medical_info || '',
      otherInfo: rawData.petProfileById.details.additional_info || '',
      phoneId: rawData.petProfileById.owner.phone_id || '',
      scheme: rawData.petProfileById.customized_info?.scheme || 'blue',
      message: rawData.petProfileById.customized_info?.message || '',
      ownerId: rawData.petProfileById.owner.id || '',
    };
  }
  return null;
};

export const usePetProfileById = (id: string) => {
  const { data, error, loading } =
    useQueryWithBoundary<GetPetProfileByIdOpenQuery>(
      GET_PET_PROFILE_BY_ID,
      {
        variables: {
          pet_id: id,
        },
      },
      shouldIgnorePetNotFound,
    );
  return { petProfile: mapProfile(data), error, loading };
};

export const usePetProfileByIdAuth = (id: string) => {
  const { data, error, loading } = useQueryWithBoundary<GetPetProfileByIdQuery>(
    GET_PET_PROFILE_BY_ID_AUTH,
    {
      variables: {
        pet_id: id,
      },
    },
    shouldIgnorePetNotFound,
  );
  return { petProfile: mapProfileAuth(data), error, loading };
};
