import { forwardRef } from 'react';
import { Box, BoxProps } from '../Box';

export const getRel = (target: string | undefined, rel?: string) =>
  target === '_blank' ? 'noreferrer' : rel;

export type LinkProps = Omit<BoxProps<'a'>, 'as'>;

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ target, rel, ...props }: LinkProps, ref) => {
    const finalRel = getRel(target, rel);

    return (
      <Box
        {...linkStyles}
        {...props}
        as="a"
        target={target}
        rel={finalRel}
        ref={ref}
      />
    );
  },
);

export const linkStyles = {
  color: 'primary.400',
  cursor: 'pointer',
  display: 'inline',
  textDecoration: 'underline',
  textStyle: 'textlink-normal-medium',
} as LinkProps;
