import {
  Event,
  EventActivity,
  EventDetails,
} from '@amzn/ring-neighbors-api-orchestrator-gql-schema';
import { useMemo } from 'react';
import { getCoordinatesTuple } from 'src/shared/utils';
import { DeepNonNullable } from 'utility-types';

export const useNormalizedEvent = (event: Event) => {
  const normalized = useMemo(() => {
    const {
      activity,
      commentConnection,
      details,
      owner,
      resolution_info,
      user_activity,
      ...restEvent
    } = event;

    const { date_info, media, share, location, ...restDetails } = details!;

    const initialMedia = getInitialMedia(media);
    const coordinates = getCoordinatesTuple(location);

    return {
      ...restEvent,
      ...restDetails,
      ...(activity as DeepNonNullable<EventActivity>),
      ...date_info!,
      ...commentConnection,
      ...resolution_info,
      ...user_activity!,
      ...initialMedia,
      location,
      owner,
      // details
      share_url: share?.url ?? '',
      coordinates,
    };
  }, [event]);

  return normalized;
};

export type NormalizedEvent = ReturnType<typeof useNormalizedEvent>;

const getInitialMedia = (media: EventDetails['media']) => {
  if (!media) {
    return {
      media_type: undefined,
      media_src: undefined,
    };
  }

  const { type, url } = media[0];

  return {
    media_type: type,
    media_src: url,
  };
};
