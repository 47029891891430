import { PublisherData } from '@amzn/ring-neighbors-api-orchestrator-gql-schema';
import { Box, Image } from '@amzn/ring-ui-react-components';
import { BoxBasedComponentProps } from '../Box';
import { Flex } from '../Flex';
import { Icon } from '../Icon';

export type OwnerHeaderBaseProps = BoxBasedComponentProps<
  'div',
  {
    isOwned: boolean;
    ownerType: string;
    publisherData?: PublisherData;
  }
>;

export const OwnerHeaderBase = ({
  isOwned,
  children,
  ownerType,
  publisherData,
  ...props
}: OwnerHeaderBaseProps) => {
  const verified = isVerifiedUser(ownerType, isOwned);
  const color = verified ? 'primary.400' : 'neutral.500';

  return (
    <Flex {...props} alignItems="center" tabIndex={0}>
      {publisherData?.avatar_url && (
        <Image
          name="avatar"
          boxSize="40px"
          src={publisherData.avatar_url}
          borderRadius="full"
          mr={2}
          data-testid="event-card-header-avatar"
        />
      )}
      <Box flexGrow={1}>
        <Box
          color={color}
          textStyle="body-normal-medium"
          display="flex"
          alignItems="center"
        >
          {verified && !publisherData?.avatar_url && (
            <Icon name="verified" size={20} color="primary.700" mr={2} mb={0} />
          )}
          {children}
        </Box>
        {publisherData?.description && (
          <Box
            color="neutral.300"
            textStyle="body-small-light"
            mt={0.5}
            data-testid="event-card-header-description"
          >
            <span>{publisherData.description}</span>
          </Box>
        )}
      </Box>
    </Flex>
  );
};

const isVerifiedUser = (type: string | null | undefined, isOwned: boolean) =>
  type !== 'neighbor' && !isOwned;
