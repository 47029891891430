import { configsUnification } from 'src/config/configs.unification';
import {
  isLocalNeighbors,
  isLocalNpss,
  isProd,
  isQA,
} from 'src/utils/constants';
import { beta } from './config.beta';
import { gamma } from './config.gamma';
import { prod } from './config.prod';
import { Config } from './types';

const getConfig = (): Config => {
  if (isProd) {
    return prod;
  }

  if (isQA) {
    return gamma;
  }

  if (isLocalNpss || isLocalNeighbors) {
    return configsUnification;
  }

  return beta;
};

export const config = getConfig();
