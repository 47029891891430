import { UAParser } from 'ua-parser-js';

const ringApp = 'ring/';
const neighborsApp = 'neighborhoods/';

export const useHasWebViewUserAgent = () => {
  const userAgent = new UAParser().getUA();

  return userAgent.includes(ringApp) || userAgent.includes(neighborsApp);
};
