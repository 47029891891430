import { Maybe } from '@amzn/ring-neighbors-api-orchestrator-gql-schema';
import { Box, BoxProps } from '@amzn/ring-ui-react-components';

export interface LocationProps extends BoxProps {
  administrativeArea: Maybe<string> | undefined;
  locality: Maybe<string> | undefined;
}

export const Location = ({
  administrativeArea,
  locality,
  ...props
}: LocationProps) => {
  if (!administrativeArea || !locality) return null;

  const formattedLocality = getFormattedLocality(locality);
  const formattedAddress = `${formattedLocality}, ${administrativeArea}`;

  return (
    <Box {...props} tabIndex={0}>
      {formattedAddress}
    </Box>
  );
};

const getFormattedLocality = (locality: string) =>
  locality.length > LOCALITY_MAX_LENGTH
    ? locality.substr(0, LOCALITY_MAX_LENGTH - 1) + '...'
    : locality;

const LOCALITY_MAX_LENGTH = 20;
