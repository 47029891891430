import { EventActivity } from '@amzn/ring-neighbors-api-orchestrator-gql-schema';
import { FieldPolicy, InMemoryCache } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';
import { mergeIncomingNullToZeroPolicy } from './typePolicies';

export const cache = new InMemoryCache({
  addTypename: false,
  typePolicies: {
    Query: {
      fields: {
        feedByAlertAreaId: relayStylePagination(['alert_area_id']),
        feed: relayStylePagination(['alert_area_id']),
      },
    },
    AlertArea: {
      fields: {
        feed: relayStylePagination(['alert_area_id']),
      },
    },
    NeighborsUser: {
      keyFields: false,
    },
    CommentsConnection: relayStylePagination(),
    EventActivity: {
      fields: {
        comment_count: mergeIncomingNullToZeroPolicy,
        view_count: mergeIncomingNullToZeroPolicy,
      } as Record<keyof EventActivity, FieldPolicy>,
    },
  },
});
