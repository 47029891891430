import { MediaItem } from '@amzn/ring-neighbors-api-orchestrator-gql-schema';
import GET_PRODUCT_BY_ID from '@amzn/ring-neighbors-api-orchestrator-gql-schema/operations/open/getProductById.graphql';
import {
  useBoolean,
  InfoOutline,
  Divider,
  DEVICE_ICON_MATCH,
} from '@amzn/ring-ui-react-components';
import classnames from 'classnames';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import ReactImageGallery, { ReactImageGalleryProps } from 'react-image-gallery';
import { useQueryWithBoundary } from 'src/hooks';
import { LoopBanner } from 'src/shared/components/LoopBanner';
import { useLockBodyScroll } from 'src/shared/hooks';
import { EventGalleryFullscreenButton } from './EventGalleryFullscreenButton';
import { EventGalleryMediaThumbnail } from './EventGalleryMediaThumbnail';
import { ImageGalleryWrapper } from './EventMediaGallery.styled';
import {
  EventGalleryLeftNav,
  EventGalleryRightNav,
} from './EventMediaGalleryArrowButton';
import { EventMediaGalleryIndex } from './EventMediaGalleryIndex';
import {
  EventGalleryMediaItem,
  EventGalleryMediaItemProps,
} from './EventMediaGalleryItem';
import { MediaPathItem, useMediaItems } from './hooks';
import { EventMediaType, ThumbnailType } from './types';
import { resetMedia } from './utils';
import { Box } from '../Box';
import 'react-image-gallery/styles/css/image-gallery.css';

export interface EventMediaGalleryProps
  extends Omit<ReactImageGalleryProps, 'items'>,
    Pick<EventGalleryMediaItemProps, 'borderRadius'> {
  eventId: string;
  media: MediaItem[];
  onMediaClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
  thumbnailType?: ThumbnailType;
  showDeviceBanner: boolean;
  children?: ReactNode;
}

const DEFAULT_REDIRECT_DEVICE_BANNER =
  'https://ring.com/builder/pages/webview/unknown';

export interface DeviceInformationProps {
  deviceId: string;
}

export const DeviceInformation = ({ deviceId }: DeviceInformationProps) => {
  const { data } = useQueryWithBoundary(GET_PRODUCT_BY_ID, {
    variables: {
      product_id: deviceId,
    },
  });

  const DeviceIcon = useCallback(() => {
    const DeviceIcon = DEVICE_ICON_MATCH[deviceId];

    if (DeviceIcon === undefined)
      return <InfoOutline mr={2} boxSize={6} color="primary.500" />;

    return <DeviceIcon mr={2} boxSize={6} color="primary.500" />;
  }, [deviceId]);

  if (data === undefined) return <></>;

  const {
    productById: { name, educationalUrl, storeUrl },
  } = data;

  const redirectUrl =
    !educationalUrl || educationalUrl === ''
      ? !storeUrl || storeUrl === ''
        ? DEFAULT_REDIRECT_DEVICE_BANNER
        : storeUrl
      : educationalUrl;
  return (
    <>
      <Divider />
      <LoopBanner
        icon={<DeviceIcon />}
        text={`Recorded on ${name}`}
        href={redirectUrl}
        target="blank"
        clickHandler={() => window.open(redirectUrl, 'blank')}
      />
    </>
  );
};

export const EventMediaGallery = ({
  borderRadius,
  eventId,
  media,
  onMediaClick,
  onSlide,
  showIndex = false,
  showThumbnails: showThumbnailsProp = true,
  thumbnailType = 'visible',
  showDeviceBanner,
  showBullets = false,
  showNav = true,
  autoPlay = false,
  slideDuration = 100,
  ...props
}: EventMediaGalleryProps) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [isFullscreen, { toggle: toggleIsFullscreen }] = useBoolean();

  const imageGalleryRef = useRef(null);

  useLockBodyScroll(imageGalleryRef, isFullscreen);

  const paths = useMediaItems(media);
  const pathsLengthRef = useRef(paths.length);

  const usesDashes = thumbnailType === 'dashes';
  const showThumbnails =
    showThumbnailsProp && paths.length > 1 && !isFullscreen;
  const showDashes = showThumbnails && usesDashes;

  const className = classnames(`image-gallery-${eventId}`, {
    'show-dashes': showDashes,
    'show-thumbnails': showThumbnails,
  });

  const resetMediaWithId = useCallback(() => resetMedia(eventId), [eventId]);

  const handleOnSlide = useCallback(
    (currentIndex: number) => {
      setCurrentSlideIndex(currentIndex);
      onSlide?.(currentIndex);

      resetMediaWithId();
    },
    [onSlide, resetMediaWithId],
  );

  const handleScreenChange = useCallback(() => {
    resetMediaWithId();
    toggleIsFullscreen();
  }, [resetMediaWithId, toggleIsFullscreen]);

  useEffect(() => {
    if (pathsLengthRef.current !== paths.length) {
      // in case where the paths length is discreased by e.g an slide removed, we need to update the currentSlideIndex
      if (pathsLengthRef.current > paths.length) {
        handleOnSlide(currentSlideIndex > 0 ? currentSlideIndex - 1 : 0);
      }

      // if slides has been increased, let's update the current slide to be the last one
      if (pathsLengthRef.current < paths.length) {
        handleOnSlide(paths.length - 1);
      }

      pathsLengthRef.current = paths.length;
    }
  }, [currentSlideIndex, handleOnSlide, paths.length]);

  return (
    <ImageGalleryWrapper
      mb={!usesDashes ? 3 : undefined}
      overflow="hidden"
      textStyle="body-small-regular"
      data-testid="event-media-gallery"
    >
      {showIndex && (
        <EventMediaGalleryIndex
          currentSlide={currentSlideIndex + 1}
          slidesCount={paths.length}
        />
      )}
      <ReactImageGallery
        {...props}
        additionalClass={className}
        items={paths}
        onScreenChange={handleScreenChange}
        onSlide={handleOnSlide}
        onThumbnailClick={resetMediaWithId}
        ref={imageGalleryRef}
        renderFullscreenButton={EventGalleryFullscreenButton}
        renderItem={
          (({ original, type, source_info }: MediaPathItem) =>
            (
              <Box borderRadius={source_info && borderRadius}>
                <EventGalleryMediaItem
                  isFullscreen={isFullscreen}
                  onMediaClick={onMediaClick}
                  src={original}
                  type={type}
                />
                {source_info?.source && showDeviceBanner && (
                  <DeviceInformation deviceId={source_info.source} />
                )}
              </Box>
            ) as unknown) as ReactImageGalleryProps['renderItem']
        }
        renderLeftNav={EventGalleryLeftNav}
        renderRightNav={EventGalleryRightNav}
        renderThumbInner={
          (({ original, index, type }: MediaPathItem) =>
            (
              <EventGalleryMediaThumbnail
                borderRadius={borderRadius}
                isActive={index === currentSlideIndex}
                showDash={showDashes}
                src={original}
                type={type as EventMediaType}
              />
            ) as unknown) as ReactImageGalleryProps['renderThumbInner']
        }
        showBullets={showBullets}
        showFullscreenButton={!showIndex}
        showIndex={false}
        showNav={showNav}
        showPlayButton={false}
        showThumbnails={showThumbnails}
        slideDuration={slideDuration}
        startIndex={currentSlideIndex}
        autoPlay={autoPlay}
      />
      {props.children}
    </ImageGalleryWrapper>
  );
};
