const NODE_ENV = process.env['NODE_ENV'];

export const isLocalBuild = NODE_ENV === 'development';
export const isDeployedBuild = NODE_ENV === 'production';

const { hostname } = window.location;

export const isProd = [
  'neighbors.ring.com',
  'web.publicsafety.ring.com',
].includes(hostname);
export const isQA = [
  'neighbors.qa.ring.com',
  'web.publicsafety.qa.ring.com',
].includes(hostname);
export const isDev = [
  'neighbors.dev.ring.com',
  'web.publicsafety.dev.ring.com',
].includes(hostname);
export const isLocalNpss = hostname === 'localnpss.dev.ring.com';
export const isLocalNeighbors = hostname === 'localneighbors.dev.ring.com';
