import { border } from 'polished';
import { forwardRef } from 'react';
import { Theme } from 'src/shared/theme';
import { FormControlContextValue, useFormControl } from './FormControlContext';
import { Box, BoxBasedComponentProps } from '../Box';

export type InputType = 'input' | 'textarea';

export type InputProps<T extends InputType = 'input', P extends {} = {}> =
  BoxBasedComponentProps<
    T,
    P &
      FormControlContextValue & {
        size?: 'sm' | 'md' | 'lg';
        fullWidth?: boolean;
      }
  >;

export const Input = forwardRef<
  HTMLInputElement | HTMLTextAreaElement,
  InputProps<'input'> | InputProps<'textarea'>
>(
  (
    {
      as = 'input',
      size = 'md',
      fullWidth = true,
      height,
      'aria-label': ariaLabel,
      'aria-describedby': ariaDescribedBy,
      ...props
    },
    ref,
  ) => {
    const { readOnly, disabled, invalid, required } = useFormControl(props);

    return (
      <Box
        {...border('1px', 'solid', 'tertiary.700')}
        {...getInputSize({ size, height })}
        alignItems="center"
        appearance="none"
        aria-describedby={ariaDescribedBy}
        aria-disabled={disabled}
        aria-invalid={invalid}
        aria-label={ariaLabel}
        aria-readonly={readOnly}
        aria-required={required}
        as={as}
        bg="white"
        borderRadius="round"
        color="neutral.500"
        disabled={disabled}
        display="flex"
        fontWeight="regular"
        outline="none"
        readOnly={readOnly}
        ref={ref}
        required={required}
        resize="none"
        transition="all 0.2s"
        width={fullWidth ? '100%' : undefined}
        _readOnly={{
          bg: 'transparent',
          boxShadow: 'none !important',
          userSelect: 'all',
        }}
        _placeholder={{
          fontSize: 14,
          color: 'neutral.300',
        }}
        _disabled={{
          opacity: '0.4',
          cursor: 'not-allowed',
        }}
        _focus={(theme: Theme) => ({
          zIndex: 1,
          borderColor: theme.colors.primary[500],
          boxShadow: `0 0 0 1px ${theme.colors.primary[500]}`,
        })}
        _invalid={(theme: Theme) => ({
          borderColor: theme.colors.negative[500],
          boxShadow: `0 0 0 1px ${theme.colors.negative[500]}`,
        })}
        {...props}
      />
    );
  },
);

const inputSizes = {
  lg: {
    px: 3,
    height: 51,
    fontSize: 2,
    lineHeight: 2,
  },
  md: {
    px: 3,
    height: 48,
    fontSize: 1,
  },
  sm: {
    px: 2,
    height: 32,
    fontSize: 1,
  },
} as const;

const getInputSize = ({
  height,
  size,
}: Pick<InputProps, 'height' | 'size'>) => {
  const sizeProps = inputSizes[size!];

  return {
    ...sizeProps,
    height: height ?? sizeProps.height,
  };
};
