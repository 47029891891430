import copy from 'copy-to-clipboard';
import { useCallback } from 'react';
import { toast as showToast } from 'src/shared/components/Toast';

interface UseCopyToClipboardProps {
  value: string;
  toast?: { successMessage: string; errorMessage: string };
}

export const useCopyToClipboard = ({ value, toast }: UseCopyToClipboardProps) =>
  useCallback(() => {
    const success = copy(value);

    if (!toast) {
      return;
    }

    const { successMessage, errorMessage } = toast;

    const toastFunction = success ? showToast.success : showToast.error;
    const text = success ? successMessage : errorMessage;

    toastFunction(text, { toastId: 'copy-to-clipboard-toast' });
  }, [value, toast]);

export const useCopyLinkToClipboard = (value: string) =>
  useCopyToClipboard({
    value,
    toast: {
      successMessage: 'Link copied successfully',
      errorMessage: 'An error ocurred while copying the link',
    },
  });

export const useCopyWindowLocationToClipboard = (shareUrl: string) =>
  useCopyLinkToClipboard(shareUrl);
