import NPSS_SCHEDULED_POST_DELETE from '@amzn/ring-neighbors-api-orchestrator-gql-schema/operations/authenticated/npss/npssScheduledPostDelete.graphql';
import { useMutation } from '@apollo/client';
import type {
  NpssScheduledPostDeleteMutation,
  NpssScheduledPostDeleteMutationVariables,
} from '@amzn/ring-neighbors-api-orchestrator-gql-schema';

export interface UseEventScheduledPostDeleteProps {
  eventId: string;
  onMutationCompleted?: () => void;
  onMutationError?: () => void;
}

export const useEventScheduledPostDelete = ({
  eventId,
  onMutationCompleted,
  onMutationError,
}: UseEventScheduledPostDeleteProps) =>
  useMutation<
    NpssScheduledPostDeleteMutation,
    NpssScheduledPostDeleteMutationVariables
  >(NPSS_SCHEDULED_POST_DELETE, {
    variables: {
      input: {
        id: eventId,
      },
    },
    onCompleted() {
      onMutationCompleted?.();
    },
    onError() {
      onMutationError?.();
    },
  });
