import { routes as routesBestOfRing } from 'src/features/BestOfRing/routes';
import { DENIAL_PREFIX } from './shared/utils';

const event = 'event';

const WEB_VIEW_PREFIX = 'wv';

export const AppRoutes = {
  BestOfRing: '/best-of-ring',
  BestOfRingVideo: '/best-of-ring/:id_video(\\w+)',
  BetterNeighborhoods: '/better-neighborhoods',
  Deals: '/deals',
  Discover: '/discover',
  EmergencyInfo: '/emergency-info/:id',
  EmergencyInfoMap: '/emergency-info/:id/map',
  EmergencyInfoTopic: '/emergency-info/:id/topic/:tid',
  EmergencyInfoTopics: '/emergency-info/:id/topics',
  EmergencyInfoUpdates: '/emergency-info/:id/updates',
  EventCreate: `/${event}/create`,
  EventDetailOrSharePage: `/(${event}|a|c|d|e|n)/:id(\\w+)`,
  Feed: '/',
  Login: '/login',
  Logout: '/logout',
  MyPosts: '/settings/my-posts',
  PetTag: '/qr/:code',
  PetProfile: `/pet-profile/:id`,
  PetTagFlyer: `/pet-flyer/:id`,
  PetMarketingPage: '/nav/setup_tag',
  Terms: '/terms',
  NotFound: '/404',
  DeniedPost: '/' + DENIAL_PREFIX + '/:denial_category',
  EmailSettings: '/settings/email',
} as const;

export const pathWithWV = (path: string) => [
  path,
  `/${WEB_VIEW_PREFIX}${path}`,
];

export const getPathWithBaseURL = (path: string, baseUrl: string, wv = false) =>
  wv ? pathWithWV(`${baseUrl}${path}`) : `${baseUrl}${path}`;

export const getEventDetailPath = (id: string) => `/${event}/${id}`;

//add any new paths to this array to continue correctly grouping bugsnag errors
export const pathsForBugsnagGrouping = [
  AppRoutes.BestOfRing,
  AppRoutes.BestOfRingVideo,
  AppRoutes.BetterNeighborhoods,
  AppRoutes.Deals,
  AppRoutes.Discover,
  AppRoutes.EmergencyInfo,
  AppRoutes.EmergencyInfoMap,
  AppRoutes.EmergencyInfoTopic,
  AppRoutes.EmergencyInfoTopics,
  AppRoutes.EmergencyInfoUpdates,
  AppRoutes.EventCreate,
  AppRoutes.EventDetailOrSharePage,
  AppRoutes.Feed,
  AppRoutes.Login,
  AppRoutes.Logout,
  AppRoutes.MyPosts,
  AppRoutes.PetTag,
  AppRoutes.PetMarketingPage,
  AppRoutes.Terms,
  AppRoutes.NotFound,
  AppRoutes.DeniedPost,
  AppRoutes.EmailSettings,
  AppRoutes.PetTagFlyer,
  AppRoutes.PetProfile,
  AppRoutes.BestOfRing + routesBestOfRing.BestOfRingFeed,
  AppRoutes.BestOfRing + routesBestOfRing.BestOfRingVideoFullscreen,
] as const;
