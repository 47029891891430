import { border } from 'polished';
import { KeyboardEventHandler, useCallback } from 'react';
import { useDropdownContext } from './DropdownContext';
import { Box, BoxBasedComponentProps } from '../Box';

export type DropdownMenuProps = BoxBasedComponentProps<'div'>;

export const DropdownMenu = ({ children, ...props }: DropdownMenuProps) => {
  const { isOpen, menuRef, onClose, closeOnEsc } = useDropdownContext();

  const onKeyDown = useCallback<KeyboardEventHandler<HTMLDivElement>>(
    (event) => {
      if (closeOnEsc && event.key === 'Escape') {
        event.stopPropagation();
        onClose();
      }
    },
    [closeOnEsc, onClose],
  );

  if (!isOpen) {
    return null;
  }

  return (
    <Box
      data-testid="dropdown-menu"
      {...border('1px', 'solid', 'rgba(66, 66, 66, 0.35)')}
      backgroundColor="white"
      borderRadius="round"
      boxShadow="dropdown"
      minWidth={160}
      onKeyDown={onKeyDown}
      position="absolute"
      right={0}
      userSelect="none"
      whiteSpace="nowrap"
      zIndex="dropdown"
      {...props}
      ref={menuRef}
    >
      <Box py={3}>{children}</Box>
    </Box>
  );
};
