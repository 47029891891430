import { FitBounds } from '@amzn/ring-react-mapbox-gl/lib/map';
import bbox from '@turf/bbox';
import center from '@turf/center';
import distance from '@turf/distance';
import { Polygon, polygon, Position } from '@turf/helpers';
import { equals } from 'ramda';
import type {
  Location,
  Maybe,
  Pin,
} from '@amzn/ring-neighbors-api-orchestrator-gql-schema';

const defaultCoordsTuple = [0, 0] as [number, number];

export const lowerThanMaxDistanceInMiles = (distance: number) => distance < 5;

export const equalsDefaultCoordsTuple = equals(defaultCoordsTuple);

export const getCoordinatesTuple = (
  location: Maybe<Location> | undefined,
): [number, number] => {
  if (!location?.pin) {
    return defaultCoordsTuple;
  }

  const { longitude, latitude } = location.pin;

  return [longitude, latitude];
};

export interface GetDistanceInMilesProps {
  from: [number, number];
  to: [number, number];
}

export const getDistanceInMiles = ({ from, to }: GetDistanceInMilesProps) =>
  distance(from, to, { units: 'miles' });

export const isCenterValid = (center: [number, number] | undefined) => {
  if (!center) {
    return false;
  }

  return !equalsDefaultCoordsTuple(center);
};

interface AreCenterOrBoundsValid {
  bounds: Pin[][][] | undefined;
  center: Parameters<typeof isCenterValid>[0];
}

export const areCenterOrBoundsValid = ({
  center,
  bounds,
}: AreCenterOrBoundsValid) => {
  const validCenter = isCenterValid(center);

  return validCenter || Boolean(bounds);
};

export const calculateCenterFromBounds = (
  bounds: Pin[][][] | undefined,
): [number, number] => {
  if (!bounds) return [0, 0];
  const positions = bounds[0][0].map(
    (pin) => [pin.longitude, pin.latitude] as Position,
  );
  const poly = polygon([positions]);
  const centerCoords = center(poly);

  return (centerCoords.geometry?.coordinates ?? [0, 0]) as [number, number];
};

export const polygonToFitBounds = (polygon: Polygon): FitBounds => {
  const areaBox = bbox(polygon);
  return [areaBox.slice(0, 2), areaBox.slice(2)] as FitBounds;
};
