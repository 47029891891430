export const Progress = {
  baseStyle: {
    filledTrack: {
      transitionProperty: 'width',
    },
  },
  variants: {
    active: {
      filledTrack: {
        backgroundColor: 'primary.400',
      },
    },
    error: {
      filledTrack: {
        backgroundColor: 'negative.500',
      },
    },
  },
  defaultProps: {
    variant: 'active',
  },
};
