import { createContext } from '@chakra-ui/react-utils';
import { ModalProps } from './Modal';
import { UseModalReturn } from './useModal';

type ModalContext = UseModalReturn &
  Pick<ModalProps, 'isCentered' | 'scrollBehavior' | 'size'>;

export const [ModalContextProvider, useModalContext] =
  createContext<ModalContext>({
    name: 'ModalContext',
  });
