import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { config } from 'src/config';
import { useAuth } from 'src/context/AuthContext';
import { useReturnSearchParam } from 'src/hooks';
import { Box } from 'src/shared/components/Box';
import { Flex } from 'src/shared/components/Flex';
import { Loading } from 'src/shared/components/Loading';
const { NPSS_BASE_URL } = config;

export const LoginPage = () => {
  const history = useHistory();
  const { refetch, isPending, isAuthenticated, isNpssUser } = useAuth();
  const returnSearchParam = useReturnSearchParam();
  const redirected = useRef(false);

  useEffect(() => {
    // TODO: remove ref usage when figuring out why this component renders like 3 or 4 times
    if (isPending || redirected.current) {
      return;
    }

    if (isNpssUser && !isAuthenticated) {
      window.location.replace(NPSS_BASE_URL);
      return;
    }

    const goBack = () => {
      redirected.current = true;

      history.push(returnSearchParam);
    };

    if (isAuthenticated) {
      goBack();
      return;
    }
  }, [
    refetch,
    history,
    isPending,
    isAuthenticated,
    returnSearchParam,
    isNpssUser,
  ]);

  return (
    <Flex
      flexDirection="column"
      flex={1}
      alignItems="center"
      justifyContent="center"
    >
      <Box mb={2}>Logging you in...</Box>
      <Loading />
    </Flex>
  );
};
