import { useMemo } from 'react';
import { useUserAgentParser } from 'src/hooks';

const POSSIBLE_IOS = ['iOS'];

export const useIsiOS = () => {
  const UAParser = useUserAgentParser();

  const OSName = useMemo(() => UAParser?.getOS()?.name, [UAParser]);
  const isIOS = useMemo(
    () => (OSName !== undefined ? POSSIBLE_IOS.includes(OSName) : false),
    [OSName],
  );

  return isIOS;
};
