import { matchPath } from 'react-router-dom';
import { AppRoutes, pathsForBugsnagGrouping } from 'src/routes';

export const ignoreError = (errorMessage: string, url?: string) => {
  if (
    url?.includes('fbclid') &&
    errorMessage.includes('Invalid LngLat object: (NaN, NaN)')
  ) {
    return true;
  }

  if (
    errorMessage.includes(
      'The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.',
    ) ||
    errorMessage.includes('Play() can only be initiated by user gesture')
  ) {
    return true;
  }

  return false;
};

export const labelErrorByRoute = (errorMessage: string) => {
  const pathName = filterPathName(window.location.pathname);
  const filteredErrorMessage = filterErrorMessage(errorMessage);

  const matchedPath = matchPath(pathName, {
    path: pathsForBugsnagGrouping,
  });

  const pathForGrouping =
    matchedPath?.path && matchedPath?.isExact ? matchedPath?.path : pathName;

  return pathForGrouping + ':' + filteredErrorMessage;
};

export const filterPathName = (pathName: string) => {
  if (pathName.includes('/best-of-ring')) {
    return AppRoutes.BestOfRing;
  }

  if (pathName.includes('/qr/')) {
    return AppRoutes.PetTag;
  }

  if (pathName.includes('/pet-profile/')) {
    return AppRoutes.PetProfile;
  }

  return pathName;
};

export const filterErrorMessage = (message: string) => {
  if (message.includes('Loading chunk')) {
    return 'Loading chunk failed';
  }

  if (message.includes('Loading CSS chunk')) {
    return 'Loading CSS chunk failed';
  }

  return message;
};
