import type { Mutable } from 'utility-types';

const keys = ['xss', 'xs', 's', 'm', 'l', 'xl', 'xxl'] as const;
const values = [
  '320px',
  '376px',
  '768px',
  '1024px',
  '1366px',
  '1600px',
  '1920px',
] as const;

export type Breakpoint = typeof keys[number];
export type BreakpointValue = typeof values[number];

type BreakpointArray = Mutable<typeof values> &
  Record<Breakpoint, BreakpointValue>;

export const breakpoints = values.reduce(
  (acc, v, i) => {
    acc[keys[i] as unknown as number] = v;
    return acc;
  },
  [...values] as BreakpointArray,
);
