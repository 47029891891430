import { useMemo } from 'react';
import { useUserAgentParser } from 'src/hooks';

const POSSIBLE_MOBILE_OS = ['iOS', 'Android', 'Android[-x86]'];

export const useIsMobileOS = () => {
  const UAParser = useUserAgentParser();

  const OSName = useMemo(() => UAParser?.getOS()?.name, [UAParser]);
  const isMobile = useMemo(
    () =>
      OSName !== undefined ? POSSIBLE_MOBILE_OS.includes(OSName) : undefined,
    [OSName],
  );

  return isMobile;
};
