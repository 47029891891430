import { forwardRef } from 'react';
import { isLocalBuild } from 'src/utils';
import { IntrinsicElementsKeys } from 'styled-components';
import { Box, BoxProps, ForwardRefBoxComponent } from '../Box';

export type FlexProps<T extends IntrinsicElementsKeys = 'div'> = BoxProps<T>;

export const Flex = forwardRef<HTMLDivElement, FlexProps>((props, ref) => (
  <Box display="flex" {...props} ref={ref} />
)) as ForwardRefBoxComponent;

if (isLocalBuild) {
  Flex.displayName = 'Flex';
}
