import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html, body {
    width: 100%;
    height: 100%;
  }

  #root {
    display: flex;
    height: 100%;
    overflow: hidden;
    flex-direction: column;
  }

  body {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.neutral[500]};
    font-size: 1rem;
    font-weight: ${({ theme }) => theme.fontWeights.regular};
    line-height: 1.5;
    margin: 0;
    text-align: left;
    text-rendering: geometricPrecision;
  }

  body.has-banner:after {    
    display: none;
  }

  .consent-banner {
    z-index: 151 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    max-width: none !important;
    padding-bottom: 18px !important;
    padding-left: 44px !important;
    padding-right: 44px !important;
    padding-top: 18px !important;
  }

  .consent-banner .consent-banner-buttons button.btn--outlined {
    margin-top: 6px !important;

    ${({ theme }) => theme.mediaQueries.m} {
      margin-top: 0px !important;
    }
  }
`;
