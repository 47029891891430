import { useCallback } from 'react';
import { useHistory } from 'react-router';

export const usePushToHistory = (route: string) => {
  const history = useHistory();

  return useCallback(() => {
    history.push(route);
  }, [history, route]);
};
