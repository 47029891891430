import { breakpoints } from './breakpoints';
import { buttons } from './buttons';
import { colors } from './colors';
import { fonts, fontSizes, fontWeights } from './fonts';
import { mediaQueries } from './mediaQueries';
import { radii } from './radii';
import { shadows } from './shadows';
import { space } from './space';
import { textStyles } from './textStyles';
import { zIndices } from './zIndices';

export const theme = {
  breakpoints,
  buttons,
  colors,
  fonts,
  fontSizes,
  fontWeights,
  mediaQueries,
  radii,
  shadows,
  space,
  textStyles,
  zIndices,
} as const;
