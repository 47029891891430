import {
  defaultBorderTop,
  Flex,
  FlexProps,
} from '@amzn/ring-ui-react-components';

export type CardFooterProps = FlexProps;

export const CardFooter = (props: CardFooterProps) => (
  <Flex {...defaultBorderTop} p={3} {...props} />
);
