import { forwardRef } from 'react';
import { Box } from '../Box';
import { Icon, IconProps } from '../Icon';

type DropdownIndicatorProps = Omit<IconProps, 'name'>;

export const DropdownIndicator = forwardRef<
  HTMLDivElement,
  DropdownIndicatorProps
>((props: DropdownIndicatorProps, ref) => (
  <Box cursor="pointer" ref={ref} data-testid="dropdown-indicator">
    <Icon
      color="tertiary.700"
      cursor="pointer"
      size={24}
      {...props}
      aria-label="More"
      name="more"
      tabIndex={0}
    />
  </Box>
));
