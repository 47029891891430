import { useCallback } from 'react';
import { scrollIntoView } from 'src/shared/utils';
import { useFeedPageContext } from '../FeedPageContext';

export const useScrollFeedEventIntoView = (eventId: string | null) => {
  const { getEventRef } = useFeedPageContext();

  return useCallback(() => {
    const ref = eventId ? getEventRef(eventId) : null;

    scrollIntoView(ref);
  }, [eventId, getEventRef]);
};
