import { MouseEventHandler, useMemo } from 'react';
import { createClickKeyDownHandler } from 'src/shared/utils';

export const useClickAndKeyDownHandlers = (
  onClick: MouseEventHandler<HTMLElement> | undefined,
  disabled?: boolean,
) =>
  useMemo(() => {
    if (disabled) {
      return {
        onClick: undefined,
        onKeyDown: undefined,
      };
    }

    return {
      onClick,
      onKeyDown: createClickKeyDownHandler(onClick),
    };
  }, [disabled, onClick]);
