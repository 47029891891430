import { useMemo } from 'react';
import { useTheme } from '../context';
import { Theme } from '../theme';

export const useThemeStyles = <T>(getStyles: (theme: Theme) => T) => {
  const theme = useTheme();
  const result = useMemo(() => getStyles(theme), [getStyles, theme]);

  return result;
};

export const useGetThemeStyles = <T>(getStyles: (theme: Theme) => T) => {
  const theme = useTheme();
  const result = useMemo(() => () => getStyles(theme), [getStyles, theme]);

  return result;
};
