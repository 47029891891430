import {
  defaultBorderBottom,
  Flex,
  FlexProps,
} from '@amzn/ring-ui-react-components';

export type CardHeaderProps = FlexProps;

export const CardHeader = (props: CardHeaderProps) => (
  <Flex {...defaultBorderBottom} p={3} {...props} />
);
