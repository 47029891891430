import { callAll } from '@chakra-ui/utils';
import { useMemo } from 'react';
import { usePushToFeed } from 'src/hooks';
import { useAuth } from '../AuthContext';

export const usePushToFeedIfAuthenticated = (beforePush?: () => void) => {
  const { isAuthenticated } = useAuth();
  const pushToFeedIfAuthenticated = usePushToFeed(isAuthenticated);

  return useMemo(
    () => callAll(beforePush, pushToFeedIfAuthenticated) as () => void,
    [beforePush, pushToFeedIfAuthenticated],
  );
};
