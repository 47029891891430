export const hrefDownloadAppAttribution =
  'https://nh-attrib.ring.com/api/events/click/dda75c3d-d12c-467a-bb41-875f3c7a945a';

export const helpCenterNeighboprsUrl =
  'https://support.ring.com/hc/en-us/categories/360002020811-Neighbors';

export const crimeIncidentCategoryNonviolent = 'NONVIOLENT';
export const crimeIncidentCategoryViolent = 'VIOLENT';

const OTHER_CATEGORY = 'other';
export const AUTO_DECLINE = 'autodecline';
export const DENIAL_PREFIX = 'denial';
export const DEFAULT_CONTENTFUL_CATEGORY = DENIAL_PREFIX + ':' + OTHER_CATEGORY;
