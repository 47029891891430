import { Config } from './types';

export const prod: Config = {
  CONSENT_MANAGER_SCRIPT_URL:
    'https://d39xvdj9d5ntm1.cloudfront.net/common/js/consent-manager-50632c5442c34437f12470bcba5f10ce.js',
  HEAP_ID: '1155664863',
  KATAL_API_URL:
    'https://cl9yku1k75.execute-api.us-east-1.amazonaws.com/prod/v1/log',
  MAPBOX_API_KEY:
    'pk.eyJ1IjoicmluZ21hcHMiLCJhIjoiY2pmYTl0aXZvMTM3NjQ0cWgzdnJiY2g2eSJ9.6DI54yHi79n28q4ZN8tRzQ',
  NEIGHBORS_URL: 'https://neighbors.ring.com',
  RING_ACCOUNT_URL: 'https://account.ring.com',
  RING_DASHBOARD_URL: 'https://account.ring.com/account/dashboard',
  RING_URL: 'https://ring.com',
  ENABLE_RESOLVE_SWITCH: false,
  ENABLE_MARKDOWN: false,
  ENABLE_UNIFIED_MY_POSTS_PAGE: false,
  ENABLE_NHWEB_NPSS_FEED_QUERY_UNIFICATION: false,
  CONTENTFUL_API_BASE_URL: 'https://graphql.contentful.com',
  CONTENTFUL_SPACE_ID: 'cpcn7rmzq5g6',
  CONTENTFUL_API_TOKEN: '9lifIWR8BgqNp0EX_t4A2EbMKqlgjymV7P0Delyoxr0',
  CONTENTFUL_SPACE_ENVIRONMENT: 'master',
  EVENT_STREAM_API_BASE_URL: 'https://eventstream.ring.com',
  NPSS_BASE_URL: 'https://publicsafety.ring.com',
  NPSS_REDIRECTION_URL: 'https://web.publicsafety.ring.com',
  NPSS_EMAIL_SETTINGS_URL: 'https://settings.neighbors.ring.com/settings/email',
  NPSS_HELP_URL: 'https://help.publicsafety.ring.com/',
  NEIGHBORS_COMMUNITY_GUIDELINES_URL:
    'https://support.ring.com/hc/en-us/articles/115004851266-Neighbors-by-Ring-Community-Guidelines',
  BUGSNAG_API_KEY: '62132b4fa9189bec66bd1602ab5c43e0',
  BUGSNAG_NOTIFY_URL: 'https://events.bugsnag.prod.ring.com',
  BUGSNAG_SESSIONS_URL: 'https://sessions.bugsnag.prod.ring.com',
  BOR_VIDEO_SUBMISSION_EMAIL: 'submissions@ring.com',
  LOCATION_LEARN_MORE_URL:
    'https://ring.com/support/articles/e0plk/Who-Can-See-Your-Post',
};
