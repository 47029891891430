import { Flex } from '@amzn/ring-ui-react-components';
import { ReactNode } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { NeighborsWebNavbar } from 'src/components/Navbar';
import { useHasWebViewUserAgent } from 'src/hooks';
import { useNpssAnnouncement } from 'src/hooks/useNpssAnnouncement';
import { AppRoutes } from 'src/routes';

interface AppLayoutProps {
  children: ReactNode;
}

const ROUTES_WITHOUT_APPBAR = [
  AppRoutes.EventCreate,
  AppRoutes.EmergencyInfoTopics,
  AppRoutes.EmergencyInfoTopic,
  AppRoutes.BestOfRingVideo,
];

export const AppLayout = ({ children }: AppLayoutProps) => {
  useNpssAnnouncement();
  const { pathname } = useLocation();
  const isWebViewUserAgent = useHasWebViewUserAgent();
  const isRouteWithoutAppbar = ROUTES_WITHOUT_APPBAR.some((route) =>
    matchPath(pathname, { path: route, exact: true }),
  );
  const hideNavbar = isRouteWithoutAppbar || isWebViewUserAgent;

  return (
    <>
      {!hideNavbar && <NeighborsWebNavbar />}
      <Flex
        as="main"
        width="100%"
        bg="neutral.50"
        flex={1}
        data-testid="app-layout"
        direction="column"
        overflow="scroll"
        children={children}
      />
    </>
  );
};
