import Cookies from 'js-cookie';
import { config } from 'src/config';
const { NPSS_BASE_URL } = config;

const TIME_1_DAY = 1;
const NPSS_SESSION = 'npss_session';
const SESSION_ID = '_session_id';

export const useNpssSessionCookie = () => {
  const sessionIdCookie = Cookies.get(SESSION_ID);
  const npssSessionCookie = Cookies.get(NPSS_SESSION);

  if (
    npssSessionCookie !== undefined &&
    (sessionIdCookie === undefined || sessionIdCookie !== npssSessionCookie)
  ) {
    var expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + TIME_1_DAY);
    expirationDate.setHours(0, 0, 0, 0);

    const npssUrl = new URL(NPSS_BASE_URL);

    Cookies.set(SESSION_ID, npssSessionCookie, {
      expires: expirationDate,
      domain: npssUrl.host.split(':')[0],
    });
  }
};
