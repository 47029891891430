import {
  navbarHeight,
  navEventKey,
  useNavbarContext,
} from '@amzn/ring-ui-react-components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToggle } from 'react-use';
import { config } from 'src/config';
import { useAlertAreaDispatch, useAlertAreaState, useAuth } from 'src/context';
import { usePushToFeed } from 'src/hooks';
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerHeaderProps,
  DrawerMenu,
  DrawerMenuItem,
} from 'src/shared/components/Drawer';
import {
  goToLogout,
  isDeployedBuild,
  isLocalBuild,
  logoutUrl,
} from 'src/utils';
import { NavbarBase, NavMobileButton } from './NavbarBase';
import { AuthenticatedNavbarProps } from './types';

const { RING_DASHBOARD_URL } = config;

const NavbarMobile = ({
  onClickMyPosts,
  onClickSettings,
  onSelectedAreaChanged,
  dispatchEventStreamClick,
}: AuthenticatedNavbarProps) => {
  const { t } = useTranslation('components.navbar', { useSuspense: false });
  const { selectedAlertArea } = useAlertAreaState();
  const [isChangingLocation, toggleIsChangingLocation] = useToggle(false);
  const { activeKey, setActiveKey } = useNavbarContext();
  const { isPending } = useAuth();
  const handlePushToFeed = usePushToFeed();

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => setIsOpen(navEventKey === activeKey), [activeKey]);

  const handleSelectedAreaChanged = () => {
    setActiveKey(navEventKey);
    onSelectedAreaChanged?.();
  };

  return (
    <>
      <NavMobileButton icon="feed" onClick={handlePushToFeed}>
        {t('feed')}
      </NavMobileButton>
      <Drawer isOpen={isOpen} top={!isChangingLocation ? navbarHeight : 0}>
        {!isChangingLocation ? (
          <>
            <DrawerBody>
              <DrawerMenu title={selectedAlertArea?.details.name}>
                <DrawerMenuItem
                  onClick={toggleIsChangingLocation}
                  icon="settings"
                >
                  {t('settings')}
                </DrawerMenuItem>
              </DrawerMenu>
              <DrawerMenu title="Account Settings">
                <DrawerMenuItem
                  onClick={() => {
                    if (isPending !== true)
                      dispatchEventStreamClick?.('nhWeb_myAccount', [], {
                        itemType: 'button',
                      });
                  }}
                  as="a"
                  href={RING_DASHBOARD_URL}
                  icon="account"
                >
                  {t('account')}
                </DrawerMenuItem>
                <DrawerMenuItem
                  icon="settings"
                  onClick={() => {
                    setIsOpen(false);
                    if (isPending !== true)
                      dispatchEventStreamClick?.('nhWeb_myPosts');
                    onClickMyPosts();
                  }}
                >
                  {t('my-posts')}
                </DrawerMenuItem>
                <DrawerMenuItem
                  icon="settings"
                  onClick={() => {
                    if (isPending !== true)
                      dispatchEventStreamClick?.('nhWeb_settings', [], {
                        itemType: 'button',
                      });
                    onClickSettings();
                  }}
                >
                  {t('settings')}
                </DrawerMenuItem>
              </DrawerMenu>
            </DrawerBody>
            <DrawerFooter>
              <DrawerMenu>
                <DrawerMenuItem
                  as="a"
                  href={isDeployedBuild ? logoutUrl : undefined}
                  icon="sign-out"
                  onClick={isLocalBuild ? goToLogout : undefined}
                >
                  {t('sign-out')}
                </DrawerMenuItem>
              </DrawerMenu>
            </DrawerFooter>
          </>
        ) : (
          <MenuChangeLocation
            onClickBack={toggleIsChangingLocation}
            onSelectedAreaChanged={handleSelectedAreaChanged}
          />
        )}
      </Drawer>
    </>
  );
};

export const AuthenticatedNavbarMobile = (props: AuthenticatedNavbarProps) => (
  <NavbarBase>
    <NavbarMobile {...props} />
  </NavbarBase>
);

type MenuChangeLocationProps = Pick<
  AuthenticatedNavbarProps,
  'onSelectedAreaChanged'
> &
  Pick<DrawerHeaderProps, 'onClickBack'>;

const MenuChangeLocation = ({
  onClickBack,
  onSelectedAreaChanged,
}: MenuChangeLocationProps) => {
  const { alertAreas, selectedAlertArea } = useAlertAreaState();
  const setSelectedAlertAreaId = useAlertAreaDispatch();

  return (
    <DrawerBody>
      <DrawerHeader onClickBack={onClickBack}>Change Location</DrawerHeader>
      <DrawerMenu>
        {alertAreas?.map(({ id, details: { name } }) => {
          const isSelected = selectedAlertArea?.id === id;

          const handleClick = () => {
            onSelectedAreaChanged?.();
            setSelectedAlertAreaId(id);
          };

          return (
            <DrawerMenuItem
              color={isSelected ? 'primary.500' : undefined}
              disabled={isSelected}
              icon="marker"
              key={id}
              onClick={handleClick}
            >
              {name}
            </DrawerMenuItem>
          );
        })}
      </DrawerMenu>
    </DrawerBody>
  );
};
