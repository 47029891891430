import { Box, CheckBadge } from '@amzn/ring-ui-react-components';
import { useTranslation } from 'react-i18next';
import { useEventCategory } from 'src/shared/context';
import { useEventCardState } from './context';
import { BoxProps } from '../Box';
import { Flex } from '../Flex';
import { Tag } from '../Tag';

export const EventCardTags = (props: BoxProps) => {
  const {
    event: { category, is_resolved },
  } = useEventCardState();
  const { t } = useTranslation('components.event', { useSuspense: false });
  const { title } = useEventCategory(category)! || {};

  return (
    (title && (
      <Flex data-testid="event-card-tags" {...props}>
        {is_resolved && (
          <Tag bg="positive.100" mr={2}>
            <Flex alignItems="center">
              <CheckBadge color="positive.500" mr={1} boxSize="16px" />
              <Box fontWeight="bold">{t('tag.resolved')}</Box>
            </Flex>
          </Tag>
        )}
        <Tag>{title}</Tag>
      </Flex>
    )) || <></>
  );
};
