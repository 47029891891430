import { logBugsnag, SEVERITY } from 'src/logs';
import { AnonymousEvent } from 'src/shared/services';
import { IStack, Stack } from './utils';
import { config } from '../../../config';

const { EVENT_STREAM_API_BASE_URL } = config;

const API_VERSION = '1.0.0';
const GROUP = 'client.device';
const SUBGROUP = 'ring.neighbors.webapp';

const WATCH_INTERVAL = 1000;

export class EventStreamService {
  events: IStack<AnonymousEvent> = new Stack();

  constructor() {
    this.watchEvents();
  }

  private async callEventStream(event: AnonymousEvent) {
    try {
      await fetch(
        `${EVENT_STREAM_API_BASE_URL}/anon/${API_VERSION}/event/${GROUP}/${SUBGROUP}`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(event),
        },
      );
    } catch {
      logBugsnag(SEVERITY.error, 'failed to post to event stream');
    }
  }

  private watchEvents() {
    try {
      const { events, callEventStream } = this;
      if (window.requestIdleCallback) {
        const watch = function (deadline: any) {
          while (events.size() > 0 && deadline.timeRemaining() > 1) {
            callEventStream(events.pop() as AnonymousEvent);
          }
          window.requestIdleCallback(watch);
        };
        window.requestIdleCallback(watch);
      } else {
        setInterval(() => {
          while (events.size() > 0) {
            callEventStream(events.pop() as AnonymousEvent);
          }
        }, WATCH_INTERVAL);
      }
    } catch {
      const message = 'Unexpected Error in EventStreamService watchEvents';
      logBugsnag(SEVERITY.error, message);
    }
  }

  public sendEvent(event: AnonymousEvent): any {
    this.events.push(event);
  }
}

export const eventStreamService = new EventStreamService();
