import Bugsnag from '@bugsnag/js';
import { getISOTimestamp } from 'src/apollo/utils';
import { getUserIDHmacToken } from 'src/auth';

export enum SEVERITY {
  error = 'error',
  info = 'info',
  warning = 'warning',
}

export const logBugsnag = (
  severity: SEVERITY,
  message: string,
  metaData?: Record<string, unknown>,
) => {
  const skipNotifying =
    typeof metaData?.blockedURI === 'string' &&
    metaData.blockedURI.includes('gstatic.com') &&
    metaData?.violatedDirective === 'font-src';

  if (!skipNotifying) {
    Bugsnag.notify(message, function (event) {
      event.severity = severity;
      metaData && event.addMetadata('metadata', metaData);
    });
  }
};

export const getEventSecurityLogContext = (context: Record<string, any>) => {
  const timestamp = getISOTimestamp();
  const hashedUserID = getUserIDHmacToken();

  return {
    ...context,
    timestamp,
    hashedUserID,
  };
};

export const logContentSecurityPolicyViolation = (
  event: SecurityPolicyViolationEvent,
) => {
  const message = `Content Security Policy Violation of ${event.violatedDirective}`;
  const context = {
    blockedURI: event.blockedURI,
    disposition: event.disposition,
    documentURI: event.documentURI,
    effectiveDirective: event.effectiveDirective,
    statusCode: event.statusCode,
    sourceFile: event.sourceFile,
    violatedDirective: event.violatedDirective,
  };

  logBugsnag(SEVERITY.error, message, context);
};
