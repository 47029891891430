import { Flex, FlexProps } from '../Flex';

export const BottomTray = (props: FlexProps) => (
  <Flex
    bg="white"
    px={6}
    py={4}
    minHeight={BOTTOM_TRAY_MIN_HEIGHT}
    {...props}
    boxShadow="shadow_bottom500"
    position="fixed"
    bottom={0}
    left={0}
    right={0}
    width="100%"
  />
);

export const BOTTOM_TRAY_MIN_HEIGHT = 80;
