import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { AppRoutes } from 'src/routes';

export const usePushToFeed = (condition = true) => {
  const history = useHistory();

  return useCallback(() => {
    if (!condition) {
      return;
    }
    history.push(AppRoutes.Feed);
  }, [history, condition]);
};
