import cookies from 'js-cookie';

// TODO: currently, only production uses cc-privacy-cookie but in the future we will check just for one and not both
const CC_PRIVACY_COOKIE = 'cc-privacy-cookie';
const PRIVACY_COOKIE = 'privacy-cookie';

export interface UserPrivacyPreferences {
  analytics_heap: boolean | string;
}

export const usePrivacyCookie = (
  analyticsProvider: keyof UserPrivacyPreferences,
) => {
  const privacyCookie =
    cookies.get(CC_PRIVACY_COOKIE) ?? cookies.get(PRIVACY_COOKIE);

  // analytics provider will be enabled by default if
  // there're no user's custom preferences but user is logged in
  if (!privacyCookie) {
    return null;
  }

  const settings = JSON.parse(privacyCookie) as UserPrivacyPreferences;

  return settings[analyticsProvider]?.toString() === 'true';
};
