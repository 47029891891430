import { SyntheticEvent } from 'react';
import { CardMedia, CardMediaProps } from 'src/shared/components/Card';
import { withAspectRatio, withMediaFallback } from 'src/shared/hocs';

type EventMediaImageProps = CardMediaProps<'img', {}, 'as' | 'alt'>;

const EventMediaImage = (props: EventMediaImageProps) => (
  <CardMedia {...props} alt="User generated image" as="img" />
);

const EventMediaImageWithFallback = withMediaFallback(EventMediaImage);

const EventMediaImageWithAspectRatio = withAspectRatio(
  EventMediaImageWithFallback,
  ({
    currentTarget: { naturalHeight, naturalWidth },
  }: SyntheticEvent<HTMLImageElement>) => ({
    width: naturalWidth,
    height: naturalHeight,
  }),
  'onLoad',
);

export { EventMediaImageWithAspectRatio as EventMediaImage };
