import {
  ArrowLeft,
  Button,
  ButtonProps,
  IconButton,
  IconButtonProps,
} from '@amzn/ring-ui-react-components';

type BackButtonProps<T extends string | undefined> = (undefined extends T
  ? Omit<IconButtonProps, 'aria-label'>
  : ButtonProps) & { children?: T };

export const BackButton = <T extends string | undefined>({
  children,
  ...props
}: BackButtonProps<T>) => {
  if (children) {
    return (
      <Button
        h={8}
        m={-1}
        size="sm"
        textStyle="body-large-medium"
        {...props}
        alignItems="center"
        display="inline-flex"
        justifyContent="flex-start"
        leftIcon={back}
        variant="unstyled"
      >
        {children}
      </Button>
    );
  }

  return (
    <IconButton
      {...props}
      aria-label="Go back"
      boxSize={10}
      icon={back}
      variant="unstyled"
    />
  );
};

const back = <ArrowLeft boxSize={6} />;
