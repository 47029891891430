import { forwardRef } from 'react';
import { Button, ButtonProps } from '../Button';
import { Icon } from '../Icon';

export const ModalBackButton = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, ...rest }, ref) => (
    <Button
      {...rest}
      aria-label="Back"
      left={3}
      p={0}
      position="absolute"
      ref={ref}
      top={3}
      type="button"
      variant="empty"
    >
      {children ?? back}
    </Button>
  ),
);

ModalBackButton.displayName = 'ModalBackButton';

const back = <Icon name="chevron-left" size={32} />;
