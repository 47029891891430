import { Box } from '../Box';

export interface EventMediaGalleryIndexProps {
  currentSlide?: number;
  slidesCount?: number;
}

export const EventMediaGalleryIndex = ({
  currentSlide,
  slidesCount,
}: EventMediaGalleryIndexProps) => (
  <Box
    bg="blackAlpha.400"
    borderRadius="rounder"
    color="white"
    position="absolute"
    px={2}
    py={1}
    right={6}
    textAlign="center"
    textStyle="footnote-xsmall-regular"
    top={4}
    zIndex={4}
  >
    {currentSlide} / {slidesCount}
  </Box>
);
