import { Maybe } from '@amzn/ring-neighbors-api-orchestrator-gql-schema';
import { Box, Button } from '@amzn/ring-ui-react-components';
import { useLocation } from 'react-router-dom';
import {
  getViewFromRoutePath,
  useNeighborsWebClickEventStreamDispatcher,
} from 'src/components/App/hooks';
import { useIsDesktop } from 'src/shared/hooks';

export interface EventAction {
  text: string;
  url: string;
}

export interface EventCardActionsProps {
  actions: Maybe<EventAction[]> | undefined;
  publisher_id: Maybe<string> | undefined;
}

export const EventCardActions = (props: EventCardActionsProps) => {
  const { actions, publisher_id } = props;
  const location = useLocation();
  const currentView = getViewFromRoutePath(location.pathname);
  const isDetail =
    currentView === 'nhWeb_sharePage' || currentView === 'nhWeb_postDetail';
  const isDesktop = useIsDesktop();
  const dispatchClick = useNeighborsWebClickEventStreamDispatcher(currentView);

  const handleOnClick = (url: string) => () => {
    if (dispatchClick && publisher_id) {
      dispatchClick('nhWeb_CTAButton', [publisher_id], {
        itemType: 'button',
      });
    }
    window.location.replace(url);
  };

  return (
    <Box
      textStyle="button-normal"
      my={2}
      justifyContent={{ base: 'center', m: 'flex-start' }}
      data-testid="event-card-actions"
    >
      {actions?.map((action, i) => (
        <Button
          key={action.url}
          data-testid="event-card-action-button"
          my={1}
          mx={i > 0 && isDetail && isDesktop ? 1 : 0}
          w={{ base: '100%', m: isDetail && isDesktop ? 'auto' : '100%' }}
          variant={i > 0 ? 'outline' : undefined}
          onClick={handleOnClick(action.url)}
        >
          {action.text}
        </Button>
      ))}
    </Box>
  );
};
