import { useSearchParam } from 'src/hooks';
import type { NonNullableAlertArea } from 'src/types';

export const location_IdSearchParam = 'location_id';
export const locationIdSearchParam = 'locationId';

export const useAlertAreaIdByRingLocation = (
  alertAreas: NonNullableAlertArea[],
) => {
  const location_Id = useSearchParam(location_IdSearchParam);
  const locationId = useSearchParam(locationIdSearchParam);

  if (!locationId && !location_Id) {
    return;
  }

  const queryParamRingLocationId = location_Id ?? locationId;
  const alertAreaByRingLocation = alertAreas.find(({ details }) =>
    details.ring_location_ids?.some((id) => id === queryParamRingLocationId),
  );

  return alertAreaByRingLocation?.id;
};
