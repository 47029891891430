import css from '@styled-system/css';
import { isLocalBuild } from 'src/utils';
import styled from 'styled-components';
import { Flex } from '../Flex';
import type { CSSSelectorObject, SystemStyleObject } from '@styled-system/css';
import type { ComponentProps } from 'react';

type ControlBoxPropsKeys =
  | '_checked'
  | '_checkedChild'
  | '_checkedDisabled'
  | '_checkedFocus'
  | '_checkedHover'
  | '_child'
  | '_disabled'
  | '_focus'
  | '_hover'
  | '_invalid';

export type PseudoBoxProps = {
  [_ in ControlBoxPropsKeys]?: SystemStyleObject;
} & { type?: string };

export const ControlBox = styled(Flex)<PseudoBoxProps>(
  ({
    type = 'checkbox',
    _checked,
    _checkedChild = { opacity: 1 },
    _checkedDisabled,
    _checkedFocus,
    _checkedHover,
    _child = { opacity: 0 },
    _disabled,
    _focus,
    _hover,
    _invalid,
  }) => {
    const checked = `input[type=${type}]:checked + &, input[type=${type}][aria-checked=mixed] + &`;
    const checkedDisabled = `input[type=${type}]:checked:disabled + &, input[type=${type}][aria-checked=mixed]:disabled + &`;
    const checkedFocus = `input[type=${type}]:checked:focus + &, input[type=${type}][aria-checked=mixed]:focus + &`;
    const checkedHover = `input[type=${type}]:checked:hover:not(:disabled) + &, input[type=${type}][aria-checked=mixed]:hover:not(:disabled) + &`;
    const disabled = `input[type=${type}]:disabled + &`;
    const focus = `input[type=${type}]:focus + &`;
    const hover = `input[type=${type}]:hover:not(:disabled):not(:checked) + &`;
    const invalid = `input[type=${type}][aria-invalid=true] + &`;

    const object = {
      '& > *': _child,
      [checked]: {
        ..._checked,
        '& > *': _checkedChild,
      },
      [checkedDisabled]: _checkedDisabled,
      [checkedFocus]: _checkedFocus,
      [checkedHover]: _checkedHover,
      [disabled]: _disabled,
      [focus]: _focus,
      [hover]: _hover,
      [invalid]: _invalid,
    } as CSSSelectorObject;

    return css(object);
  },
);

if (isLocalBuild) {
  ControlBox.displayName = 'ControlBox';
}

ControlBox.defaultProps = {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexShrink: 0,
  'aria-hidden': 'true',
};

export type ControlBoxProps = ComponentProps<typeof ControlBox>;
