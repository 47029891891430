import { ApolloClient, setLogVerbosity, InMemoryCache } from '@apollo/client';
import { config } from 'src/config';
import { isLocalBuild } from 'src/utils';
import { cache } from './cache';
import { link } from './link';
import {
  GET_ACTIVE_EVENT_ID,
  GET_OPENED_EVENT_ID,
  GET_READ_EVENTS,
} from './local';

const {
  CONTENTFUL_API_BASE_URL: apiUrl,
  CONTENTFUL_SPACE_ID: spaceId,
  CONTENTFUL_SPACE_ENVIRONMENT: environment,
  CONTENTFUL_API_TOKEN: token,
} = config;

setLogVerbosity('log');

export const client = new ApolloClient({
  cache,
  link,
  connectToDevTools: isLocalBuild,
});

client.writeQuery({
  query: GET_ACTIVE_EVENT_ID,
  data: {
    activeEventId: null,
  },
});

client.writeQuery({
  query: GET_OPENED_EVENT_ID,
  data: {
    openedEventId: null,
  },
});

client.writeQuery({
  query: GET_READ_EVENTS,
  data: {
    readEvents: {},
  },
});

export const contentfulClient = new ApolloClient({
  uri: `${apiUrl}/content/v1/spaces/${spaceId}/environments/${environment}`,
  headers: {
    authorization: `Bearer ${token}`,
  },
  cache: new InMemoryCache(),
});
