import { PublisherData } from '@amzn/ring-neighbors-api-orchestrator-gql-schema';
import { Image } from '@amzn/ring-ui-react-components';
import { ReactElement } from 'react';
import { CardHeader, CardHeaderProps } from '../Card';
import {
  Dropdown,
  DropdownIndicator,
  DropdownMenu,
  DropdownToggle,
} from '../Dropdown';
import { Flex } from '../Flex';
import { EventOwnerHeader, EventOwnerHeaderProps } from '../OwnerHeader';

export interface EventCardHeaderProps extends CardHeaderProps {
  ownerHeaderProps: EventOwnerHeaderProps;
  dropdownOptions?: ReactElement[];
  publisherData?: PublisherData;
}

export const EventCardHeader = ({
  ownerHeaderProps,
  dropdownOptions,
  publisherData,
  ...props
}: EventCardHeaderProps) => (
  <CardHeader data-testid="event-card-header" {...props}>
    <EventOwnerHeader {...ownerHeaderProps} publisherData={publisherData} />
    <Flex
      ml="auto"
      position="relative"
      alignItems="center"
      alignSelf="flex-start"
    >
      {publisherData?.logo_url && (
        <Image
          src={publisherData?.logo_url}
          mr={5}
          maxH="16px"
          width="auto"
          data-testid="event-card-header-logo"
        />
      )}
      {dropdownOptions && (
        <Dropdown>
          <DropdownToggle>
            <DropdownIndicator />
          </DropdownToggle>
          <DropdownMenu top={35} width={243}>
            {dropdownOptions}
          </DropdownMenu>
        </Dropdown>
      )}
    </Flex>
  </CardHeader>
);
