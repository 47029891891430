// https://ring.gitbook.io/ring-design-system/foundations/styles#spacings

// We define our standard spacing scale and then define some extra keys on that array for styling
// convenience (negative paddings, margins, etc)
// Note this won't modify the array lenght, so don't rely on it (you shouldn't have to)

const baseSpace = [
  0, // 0
  4, // 1
  8, // 2
  12, // 3
  16, // 4
  20, // 5
  24, // 6
  32, // 7
  40, // 8
  48, // 9
  56, // 10
  64, // 11
  72, // 12
] as const;

type BaseSpace = typeof baseSpace;

type NegativeSpace = [
  -72, // -12
  -64, // -11
  -56, // -10
  -48, // -9
  -40, // -8
  -32, // -7
  -24, // -6
  -20, // -5
  -16, // -4
  -12, // -3
  -8, // -2
  -4, // -1
];

type SpaceObject = RemoveIndex<
  Readonly<{
    '-12': NegativeSpace[0];
    '-11': NegativeSpace[1];
    '-10': NegativeSpace[2];
    '-9': NegativeSpace[3];
    '-8': NegativeSpace[4];
    '-7': NegativeSpace[5];
    '-6': NegativeSpace[6];
    '-5': NegativeSpace[7];
    '-4': NegativeSpace[8];
    '-3': NegativeSpace[9];
    '-2': NegativeSpace[10];
    '-1': NegativeSpace[11];
  }> &
    { [K in keyof BaseSpace]: BaseSpace[K] }
>;

export type Space = [
  -12,
  -11,
  -10,
  -9,
  -8,
  -7,
  -6,
  -5,
  -4,
  -3,
  -2,
  -1,
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
][number];

export const space = baseSpace.reduce((acc, v, i) => {
  if (i > 0) {
    // @ts-ignore
    acc[-i] = -v;
  }

  return acc;
}, baseSpace) as unknown as SpaceObject;
