import { GetPetIntentUrlByDeviceIdOpenQuery } from '@amzn/ring-neighbors-api-orchestrator-gql-schema';
import GET_PET_INTENT_URL_BY_DEVICE_ID from '@amzn/ring-neighbors-api-orchestrator-gql-schema/operations/open/getPetIntentUrlByDeviceIdOpen.graphql';
import { useQueryWithBoundary, shouldIgnoreUnauthorized } from 'src/hooks';

export const useGetPetIntentUrlByDeviceId = (id: string) => {
  const { data, error, loading } =
    useQueryWithBoundary<GetPetIntentUrlByDeviceIdOpenQuery>(
      GET_PET_INTENT_URL_BY_DEVICE_ID,
      {
        variables: {
          device_id: id,
        },
      },
      shouldIgnoreUnauthorized,
    );
  return { data, error, loading };
};
