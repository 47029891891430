import { plural } from 'src/shared/utils';
import { IntrinsicElementsKeys } from 'styled-components';
import { Box, BoxBasedComponentProps } from '../Box';

export type PluralProps<T extends IntrinsicElementsKeys> =
  BoxBasedComponentProps<
    T,
    {
      count: number;
      children: string;
      inclusive?: boolean;
    }
  >;

export const Plural = <T extends IntrinsicElementsKeys>({
  count,
  children,
  inclusive,
  ...props
}: PluralProps<T>) => {
  const text = plural(count, children, inclusive);

  return (
    <Box {...props} tabIndex={0} data-testid="plural">
      {text}
    </Box>
  );
};
