import { hideVisually } from 'polished';
import { isLocalBuild } from 'src/utils';
import styled, { CSSObject } from 'styled-components';
import { Box, ForwardRefBoxComponent } from '../Box';

export const VisuallyHidden = styled(Box)(
  hideVisually() as CSSObject,
) as ForwardRefBoxComponent;

if (isLocalBuild) {
  VisuallyHidden.displayName = 'VisuallyHidden';
}
