import plur from 'plur';
import { formatCount } from './count';

export function plural(count: number, word: string, inclusive = true) {
  const result = plur(word, count);

  if (!inclusive) {
    return result;
  }

  return `${formatCount(count)} ${result}`;
}
