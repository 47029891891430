import { Box, BoxBasedComponentProps } from '../Box';
import { Flex } from '../Flex';

export type DrawerFooterProps = BoxBasedComponentProps;

export const DrawerFooter = ({ children, ...props }: DrawerFooterProps) => (
  <Flex flex={1} flexDirection="column" justifyContent="flex-end" {...props}>
    <Box position="relative">{children}</Box>
  </Flex>
);
