import { NeighborsErrorCodes } from './codes';
import type { GraphQLError } from 'graphql';

interface GenericErrorWithGqlErrors {
  graphQLErrors?: ReadonlyArray<GraphQLError>;
}

export const hasGqlErrorCode = (
  { graphQLErrors = [] }: GenericErrorWithGqlErrors,
  code: NeighborsErrorCodes,
) => graphQLErrors.some(({ extensions }) => extensions?.code === code);

export const hasGqlErrorCodes = (
  { graphQLErrors = [] }: GenericErrorWithGqlErrors,
  codes: NeighborsErrorCodes[],
) => graphQLErrors.some(({ extensions }) => codes.includes(extensions?.code));

export const getISOTimestamp = (): String => new Date().toISOString();
