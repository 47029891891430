import { useTranslation } from 'react-i18next';
import {
  ReportModalContainer,
  ReportModalContainerProps,
} from './ReportModalContainer';

type EventReportModalProps = Omit<
  ReportModalContainerProps,
  'title' | 'description' | 'getConfirmationText' | 'initialReportCategoryId'
>;

export const EventReportModal = (props: EventReportModalProps) => {
  const { t } = useTranslation('components.modal', { useSuspense: false });
  return (
    <ReportModalContainer
      {...props}
      title={t('report-modal.title')}
      description={t('report-modal.description')}
      getConfirmationText={(flag) =>
        t('report-modal.confirmation-text', { flag })
      }
      initialReportCategoryId="post_flag_categories"
    />
  );
};
