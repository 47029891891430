import {
  Box,
  ChatBubble,
  Divider,
  Flex,
  PhoneOn,
  SecureProtectMode,
  Show,
} from '@amzn/ring-ui-react-components';
import { ReactElement, ReactNode, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useNeighborsWebClickEventStreamDispatcher } from 'src/components/App/hooks';
import { useAuth } from 'src/context';
import { usePetProfileByIdAuth } from 'src/features/PetTag/hooks';
import { BottomTray } from 'src/shared/components/BottomTray/BottomTray';
import { CenteredLoading } from 'src/shared/components/Loading';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from 'src/shared/components/Modal';
import { useIsDesktop } from 'src/shared/hooks';
import { ResponsiveValue, Space } from 'src/shared/theme';
import { useGetAnonymousContactInfoByIdOpen } from './hooks/useGetAnonymousContactInfoByIdOpen';
import { Button } from '../Button';

interface ContactOwnerBottomTrayProps {
  phoneId: string;
  message: string;
  infoText?: string;
  clickHandler?: () => void;
  sendMessageHandler?: () => void;
  callOwnerHandler?: () => void;
}

export const ContactOwnerBottomTray = ({
  phoneId,
  message,
  infoText = '',
  clickHandler,
  sendMessageHandler,
  callOwnerHandler,
  ...props
}: ContactOwnerBottomTrayProps) => {
  const { t } = useTranslation('shared.contactOwner');
  const [isModalOpen, setModalOpen] = useState(false);

  const toggleModal = useCallback(() => {
    setModalOpen((current) => !current);
  }, [setModalOpen]);

  const shouldShowInfoText = infoText && infoText !== '';

  return (
    <>
      <BottomTray {...props} zIndex="bottomTrayCta">
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent={{
            base: 'center',
            ...(!shouldShowInfoText
              ? { md: 'center' }
              : { md: 'space-between' }),
          }}
        >
          {shouldShowInfoText && (
            <Show above="md">
              <Box textStyle="body-large-light">{infoText}</Box>
            </Show>
          )}
          <Button
            data-testid="contact-owner-button"
            as="button"
            onClick={() => {
              toggleModal();
              if (clickHandler) clickHandler();
            }}
            variant="primary"
            size="lg"
            width="100%"
            maxWidth="327px"
          >
            {t('contact-owner')}
          </Button>
        </Box>
      </BottomTray>
      {isModalOpen && (
        <ContactOwnerModal
          phoneId={phoneId}
          message={message}
          isOpen={isModalOpen}
          onClose={toggleModal}
          sendMessageHandler={sendMessageHandler}
          callOwnerHandler={callOwnerHandler}
          blockScrollOnMount={false}
        />
      )}
    </>
  );
};

type ContactOwnerModalProps = Omit<ModalProps, 'isCentered' | 'size'> & {
  phoneId: string;
  message: string;
  sendMessageHandler?: () => void;
  callOwnerHandler?: () => void;
};

export const ContactOwnerModal = ({
  onClose,
  phoneId,
  message,
  sendMessageHandler,
  callOwnerHandler,
  ...props
}: ContactOwnerModalProps) => {
  const isDesktop = useIsDesktop();
  const { isAuthenticated, userId } = useAuth();

  if (isDesktop) {
    return (
      <ContactOwnerDesktopModal
        phoneId={phoneId}
        onClose={onClose}
        {...props}
      />
    );
  }
  return isAuthenticated ? (
    <ContactOwnerMobileModalAuth
      phoneId={phoneId}
      message={message}
      onClose={onClose}
      userId={String(userId)}
      sendMessageHandler={sendMessageHandler}
      callOwnerHandler={callOwnerHandler}
      {...props}
    />
  ) : (
    <ContactOwnerMobileModal
      phoneId={phoneId}
      message={message}
      onClose={onClose}
      sendMessageHandler={sendMessageHandler}
      callOwnerHandler={callOwnerHandler}
      {...props}
    />
  );
};

const ContactOwnerMobileModal = ({
  onClose,
  phoneId,
  message,
  sendMessageHandler,
  callOwnerHandler,
  ...props
}: ContactOwnerModalProps) => {
  const { t } = useTranslation('shared.contactOwner');
  const { data, loading } = useGetAnonymousContactInfoByIdOpen({
    variables: { phone_id: phoneId },
  });
  const dispatchClick = useNeighborsWebClickEventStreamDispatcher(
    'nhWeb_contactOwnerDialog',
  );

  const handleCallOwnerClick = () => {
    if (callOwnerHandler) {
      // In case telemetry behavior should be overwritten
      callOwnerHandler();
    } else {
      // Default telemetry behavior
      if (dispatchClick) {
        dispatchClick('nhWeb_contactOwnerPhone', [], {
          itemType: 'button',
        });
      }
    }
  };

  const handleSendMessageClick = () => {
    if (sendMessageHandler) {
      // In case telemetry behavior should be overwritten
      sendMessageHandler();
    } else {
      // Default telemetry behavior
      if (dispatchClick) {
        dispatchClick('nhWeb_contactOwnerSMS', [], {
          itemType: 'button',
        });
      }
    }
  };

  return (
    <Modal {...props} isCentered size="md" onClose={onClose}>
      <ModalOverlay>
        <ModalContent p={0} data-testid="contact-owner-mobile-modal">
          <ModalHeader
            justifyContent="center"
            px={9}
            py={4}
            textStyle="body-normal-medium"
            width={1}
          >
            {t('contact-owner')}
          </ModalHeader>
          <ModalCloseButton
            data-testid="contact-owner-modal-close-button"
            top={4}
            right={6}
            color="neutral.500"
          />
          <ModalBody display="flex" justifyContent="center" px={8} py={7}>
            {loading ? (
              <CenteredLoading data-testid="loading-spinner" />
            ) : (
              <Box maxWidth="340px">
                <Box
                  textStyle="heading-normal-medium"
                  position="relative"
                  mb={2}
                  mt="8px"
                >
                  {t('choose-way-to-contact-owner')}
                </Box>
                <ContactOwnerBanner
                  title={t('contact-neighbor-callout-title')}
                  description={t('contact-neighbor-callout-description')}
                  icon={
                    <SecureProtectMode
                      data-testid="alarm-icon"
                      color="primary.500"
                      width="15px"
                      height="17px"
                      top="4px"
                      left="4.5px"
                      boxSize={6}
                    />
                  }
                />
                <Divider />
                <ContactOwnerModalItem
                  icon={<PhoneOn color="primary.500" boxSize={8} />}
                  button={
                    <Button
                      data-testid="call-owner-link"
                      as="a"
                      variant="primary"
                      size="lg"
                      width="100%"
                      maxWidth="239px"
                      textStyle="body-large-bold"
                      href={`tel:${data?.anonymousContactInfoById?.phone_number}`}
                      onClick={handleCallOwnerClick}
                    >
                      {t('call-owner')}
                    </Button>
                  }
                  title={t('call-neighbor')}
                  description={t('contact-neighbor-via-phone-call')}
                />
                <Divider />
                <ContactOwnerModalItem
                  icon={<ChatBubble color="primary.500" boxSize={8} />}
                  button={
                    <Button
                      as="a"
                      data-testid="send-message-link"
                      variant="outline-primary"
                      size="lg"
                      width="100%"
                      maxWidth="239px"
                      textStyle="body-large-bold"
                      href={`sms:${
                        data?.anonymousContactInfoById?.phone_number
                      };?&body=${encodeURI(message)}`}
                      onClick={handleSendMessageClick}
                    >
                      {t('send-message')}
                    </Button>
                  }
                  title={t('send-sms')}
                  description={t('contact-neighbor-via-sms')}
                />
              </Box>
            )}
          </ModalBody>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

type ContactOwnerModalAuthProps = Omit<ModalProps, 'isCentered' | 'size'> & {
  phoneId: string;
  message: string;
  userId: string;
  sendMessageHandler?: () => void;
  callOwnerHandler?: () => void;
};

const ContactOwnerMobileModalAuth = ({
  onClose,
  phoneId,
  message,
  userId,
  sendMessageHandler,
  callOwnerHandler,
  ...props
}: ContactOwnerModalAuthProps) => {
  const { t } = useTranslation('shared.contactOwner');
  const { data, loading } = useGetAnonymousContactInfoByIdOpen({
    variables: { phone_id: phoneId },
  });
  const dispatchClick = useNeighborsWebClickEventStreamDispatcher(
    'nhWeb_contactOwnerDialog',
  );
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const { id } = useParams<{ id: string }>();
  const { petProfile } = usePetProfileByIdAuth(id);

  const isTheOwnerTheCurrentUser =
    petProfile !== null && userId === petProfile.ownerId;

  const handleCallOwnerClick = () => {
    if (isTheOwnerTheCurrentUser) {
      setShowErrorMsg(isTheOwnerTheCurrentUser);
      return;
    }
    if (callOwnerHandler) {
      // In case telemetry behavior should be overwritten
      callOwnerHandler();
    } else {
      // Default telemetry behavior
      if (dispatchClick) {
        dispatchClick('nhWeb_contactOwnerPhone', [], {
          itemType: 'button',
        });
      }
    }
  };

  const handleSendMessageClick = () => {
    if (isTheOwnerTheCurrentUser) {
      setShowErrorMsg(isTheOwnerTheCurrentUser);
      return;
    }
    if (sendMessageHandler) {
      // In case telemetry behavior should be overwritten
      sendMessageHandler();
    } else {
      // Default telemetry behavior
      if (dispatchClick) {
        dispatchClick('nhWeb_contactOwnerSMS', [], {
          itemType: 'button',
        });
      }
    }
  };

  return (
    <Modal {...props} isCentered size="md" onClose={onClose}>
      <ModalOverlay>
        <ModalContent p={0} data-testid="contact-owner-mobile-modal">
          <ModalHeader
            justifyContent="center"
            px={9}
            py={4}
            textStyle="body-normal-medium"
            width={1}
          >
            {t('contact-owner')}
          </ModalHeader>
          <ModalCloseButton
            data-testid="contact-owner-modal-close-button"
            top={4}
            right={6}
            color="neutral.500"
          />
          <ModalBody display="flex" justifyContent="center" px={8} py={7}>
            {loading ? (
              <CenteredLoading data-testid="loading-spinner" />
            ) : (
              <Box maxWidth="340px">
                {showErrorMsg && (
                  <ContactOwnerError>
                    {t('contact-owner-error-contact-me')}
                  </ContactOwnerError>
                )}
                <Box textStyle="heading-normal-medium" mb={2}>
                  {t('choose-way-to-contact-owner')}
                </Box>
                <ContactOwnerBanner
                  title={t('contact-neighbor-callout-title')}
                  description={t('contact-neighbor-callout-description')}
                  icon={
                    <SecureProtectMode
                      data-testid="alarm-icon"
                      color="primary.500"
                      width="15px"
                      height="17px"
                      top="4px"
                      left="4.5px"
                      boxSize={6}
                    />
                  }
                />
                <Divider />
                <ContactOwnerModalItem
                  icon={<PhoneOn color="primary.500" boxSize={8} />}
                  button={
                    <Button
                      data-testid="call-owner-link"
                      as="a"
                      variant="primary"
                      size="lg"
                      width="100%"
                      maxWidth="239px"
                      textStyle="body-large-bold"
                      href={
                        isTheOwnerTheCurrentUser
                          ? '#'
                          : `tel:${data?.anonymousContactInfoById?.phone_number}`
                      }
                      onClick={handleCallOwnerClick}
                    >
                      {t('call-owner')}
                    </Button>
                  }
                  title={t('call-neighbor')}
                  description={t('contact-neighbor-via-phone-call')}
                />
                <Divider />
                <ContactOwnerModalItem
                  icon={<ChatBubble color="primary.500" boxSize={8} />}
                  button={
                    <Button
                      as="a"
                      data-testid="send-message-link"
                      variant="outline-primary"
                      size="lg"
                      width="100%"
                      maxWidth="239px"
                      textStyle="body-large-bold"
                      href={
                        isTheOwnerTheCurrentUser
                          ? '#'
                          : `sms:${
                              data?.anonymousContactInfoById?.phone_number
                            };?&body=${encodeURI(message)}`
                      }
                      onClick={handleSendMessageClick}
                    >
                      {t('send-message')}
                    </Button>
                  }
                  title={t('send-sms')}
                  description={t('contact-neighbor-via-sms')}
                />
              </Box>
            )}
          </ModalBody>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

type ContactOwnerBannerModalProps = {
  icon: ReactNode;
  title: string;
  description: string;
};

export function ContactOwnerBanner({
  icon,
  title,
  description,
}: ContactOwnerBannerModalProps) {
  return (
    <Box
      display="flex"
      justifyContent="center"
      bg="primary.100"
      paddingTop="8px"
      height="94px"
      padding="16px 24px"
      borderRadius="4px"
      gap="12px"
    >
      <Flex flexDirection="row" flex={2} justifyContent="space-between">
        <Box display="flex" position="relative">
          {icon}
        </Box>
        <Flex flexDirection="column" justifyContent="center">
          <Box
            display="flex"
            width="243px"
            height="24px"
            textStyle="body-normal-medium"
          >
            {title}
          </Box>
          <Box
            display="flex"
            width="243px"
            height="36px"
            textStyle="body-small-light"
          >
            {description}
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
}

interface ContactOwnerErrorProps {
  children: ReactNode;
}

export const ContactOwnerError = ({ children }: ContactOwnerErrorProps) => (
  <Box
    display="flex"
    justifyContent="center"
    bg="negative.100"
    paddingTop="57px"
    height="104px"
    padding="16px 24px"
    borderRadius="4px"
    gap="16px"
    position="relative"
    mb="8px"
  >
    <Box textStyle="body-normal-medium" color="negative.500">
      {children}
    </Box>
  </Box>
);

type ContactOwnerModalItemProps = {
  icon: ReactElement;
  button: ReactElement;
  title: string;
  description: string;
};

export function ContactOwnerModalItem({
  icon,
  button,
  title,
  description,
}: ContactOwnerModalItemProps) {
  return (
    <Box display="flex" py={6} px={10} justifyContent="center">
      <Box position="relative">
        <Box position="absolute" left={-10}>
          {icon}
        </Box>
        <Box textStyle="body-large-medium">{title}</Box>
        <Box mb={4} textStyle="body-normal-regular">
          {description}
        </Box>
        <Box>{button}</Box>
      </Box>
    </Box>
  );
}

type ContactOwnerDesktopModalProps = Omit<ModalProps, 'isCentered' | 'size'> & {
  phoneId: string;
};

export const ContactOwnerDesktopModal = ({
  onClose,
  phoneId,
  ...props
}: ContactOwnerDesktopModalProps) => {
  const { t } = useTranslation('shared.contactOwner');

  const { data, loading } = useGetAnonymousContactInfoByIdOpen({
    variables: { phone_id: phoneId },
  });

  return (
    <Modal {...props} isCentered size="xlg" onClose={onClose}>
      <ModalOverlay>
        <ModalContent p={0} pb={0} data-testid="contact-owner-desktop-modal">
          <ModalHeader justifyContent="center" width={1} py={4} px={9}>
            {t('contact-owner')}
          </ModalHeader>
          <ModalCloseButton top={4} right={6} color="neutral.500" />
          <ModalBody py={py} px={px} textAlign="left">
            {loading ? (
              <CenteredLoading data-testid="loading-spinner" />
            ) : (
              <>
                <PhoneOn color="primary.500" boxSize={8} />
                <Box
                  textStyle={{
                    base: 'heading-small-medium',
                  }}
                >
                  {t('contact-owner')}
                </Box>
                <Box
                  data-testid="contact-owner-modal-details"
                  textStyle={{
                    base: 'body-medium-regular',
                  }}
                  mb={3}
                >
                  {t('contact-neighbor-message', {
                    phoneNumber: data?.anonymousContactInfoById?.phone_number,
                  })}
                </Box>
              </>
            )}
          </ModalBody>
          <ModalFooter
            borderTopWidth={0}
            px={px}
            pb={px}
            pt={{ base: 4, m: 0 }}
            boxShadow={{ base: 'shadow_bottom500', m: 'none' }}
          >
            <Button
              data-testid="contact-owner-modal-got-it-button"
              size="lg"
              onClick={onClose}
              width={{ base: '100%', m: '25%' }}
            >
              {t('got-it')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

const py = { base: 6, m: 7 } as ResponsiveValue<Space>;
const px = { base: 6, m: 8 } as ResponsiveValue<Space>;
