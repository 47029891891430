import { Box, BoxBasedComponentProps } from '../Box';
import { Flex } from '../Flex';
import { Icon } from '../Icon';

export type DrawerHeaderProps = BoxBasedComponentProps<
  'div',
  { onClickBack?: () => void }
>;

export const DrawerHeader = ({
  children,
  onClickBack,
  ...props
}: DrawerHeaderProps) => (
  <Box p={5} position="relative" textAlign="center" {...props}>
    {onClickBack && (
      <Flex
        alignItems="center"
        justifyContent="center"
        left={4}
        onClick={onClickBack}
        p={1}
        position="absolute"
        top="50%"
        transform="translateY(-50%)"
      >
        <Icon name="chevron-left" size="1.8em" />
      </Flex>
    )}
    <Box textStyle="body-normal-medium">{children}</Box>
  </Box>
);
