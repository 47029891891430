export const zIndices = {
  // misc components
  dropdown: 99,
  modal: 9999,
  bottomTrayCta: 150,
  wizardHeader: 10,

  // video player
  videoFullscreen: 1000,
  videoFullscreenButton: 5,
  videoPlayer: 10,
  videoPlayerButton: 11,

  // map
  mapMarker: 3,
  mapMarkerActive: 4,
  mapMarkerHover: 5,
  mapZoomControl: 3,
  mapControls: 6,
  mapCarousel: 6,
  mapDisclaimer: 6,
};
