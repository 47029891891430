import { useId } from '@amzn/ring-ui-react-components';
import { ReactNode } from 'react';
import { WrapIf } from 'src/shared/components/WrapIf';
import { Box, BoxBasedComponentProps } from '../Box';
import { Tooltip } from '../Tooltip';

export type HelperTextProps = BoxBasedComponentProps<
  'div',
  {
    children?: string | ReactNode | null;
    label?: string | ReactNode | null;
  }
>;

export const HelperText = ({
  color = 'neutral.300',
  children,
  label,
  ...props
}: HelperTextProps) => {
  const id = useId();
  const tooltipId = `tooltip-${id}`;

  if (!children) {
    return null;
  }

  return (
    <WrapIf
      condition={label}
      wrapper={(children) => (
        <>
          {children}
          <Tooltip id={tooltipId}>{label}</Tooltip>
        </>
      )}
    >
      <Box
        color={color}
        {...props}
        data-tip={label ? true : undefined}
        data-for={label ? tooltipId : undefined}
        tabIndex={0}
      >
        {children}
      </Box>
    </WrapIf>
  );
};
