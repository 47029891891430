import GET_MARKETING_PAGE_COLLECTION from '@amzn/ring-neighbors-contentful-gql-schema/operations/pet-tag/getMarketingPage.graphql';
import { useContentfulQuery } from 'src/hooks/useContentfulQuery';
import { MarketingPage } from '../types';
import type { PetTagMarketingPage as GqlMarketingPage } from '@amzn/ring-neighbors-contentful-gql-schema';

const mapCollectionToInterface = (items: GqlMarketingPage[]): MarketingPage[] =>
  items.map((item: GqlMarketingPage) => ({
    cards: item.cardsCollection?.items,
    hero: { url: item.hero?.url, type: item.hero?.contentType },
    instructions: item.instructionsCollection?.items,
    instructionsHeader: item.instructionsHeader,
    steps: item.stepsCollection?.items,
    stepsHeader: item.stepsHeader,
    stepsSecondaryHeader: item.stepsSecondaryHeader,
    cardsHeader: item.cardsHeader,
  })) as MarketingPage[];

export const useMarketingPageCollection = () => {
  const { data, ...rest } = useContentfulQuery<any>(
    GET_MARKETING_PAGE_COLLECTION,
  );

  return {
    ...rest,
    data: (data?.petTagMarketingPageCollection?.items
      ? mapCollectionToInterface(
          data?.petTagMarketingPageCollection?.items as GqlMarketingPage[],
        )
      : []) as MarketingPage[],
  };
};
