import { Event } from '@amzn/ring-neighbors-api-orchestrator-gql-schema';
import {
  Button,
  CalloutCard,
  DisableCircleIcon,
  QuestionmarkCircleIcon,
  InfodotIcon,
} from '@amzn/ring-safe-web';
import { useTranslation } from 'react-i18next';
import {
  formatDateFromString,
  formatTimeAndTZFromString,
} from 'src/shared/utils';
import { PostStatus } from 'src/types';
import { ScheduledEvent } from 'src/utils';

interface CommonProps {
  clickHandler?: (() => void) | null;
  'data-testid': string;
  event: Event;
}

interface EventCardCalloutProps extends CommonProps {
  status: PostStatus | undefined;
}

const ScheduledPostCallout = ({ event }: CommonProps) => {
  const { t } = useTranslation('features.myPosts');
  if (!event) {
    return null;
  }
  const scheduledEvent = event as ScheduledEvent;
  return scheduledEvent.scheduled_for ? (
    <CalloutCard
      title={t('scheduled-posts-callout', {
        date: formatDateFromString(scheduledEvent.scheduled_for),
        time: formatTimeAndTZFromString(scheduledEvent.scheduled_for),
      })}
      status="default"
      icon={<InfodotIcon />}
    />
  ) : (
    <></>
  );
};
const InReviewCallout = () => {
  const { t } = useTranslation('features.myPosts');

  return (
    <CalloutCard
      title={t('posts-in-review-modal.title')}
      status="default"
      icon={<QuestionmarkCircleIcon />}
    >
      {t('posts-in-review-modal.description')}
    </CalloutCard>
  );
};

const DeniedCallout = ({
  clickHandler,
  'data-testid': testId,
}: CommonProps) => {
  const { t } = useTranslation('features.myPosts');

  return (
    <CalloutCard
      data-testid={testId}
      title={t('post-denied.title')}
      status="error"
      icon={<DisableCircleIcon />}
    >
      {clickHandler && (
        <Button
          variant="link"
          color="negative"
          size="s"
          onClick={clickHandler}
          style={{ paddingLeft: 0, fontSize: '14px' }}
        >
          {t('post-denied.link')}
        </Button>
      )}
    </CalloutCard>
  );
};

export const EventCardCallout = ({
  status,
  ...props
}: EventCardCalloutProps) => {
  if (status === PostStatus.DENIAL) return <DeniedCallout {...props} />;
  if (status === PostStatus.PENDING) return <InReviewCallout {...props} />;
  if (status === PostStatus.SCHEDULED)
    return <ScheduledPostCallout {...props} />;
  return null;
};
