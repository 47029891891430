interface useNavigatorShareProps {
  title: string;
  shareUrl?: string;
}

export const useNavigatorShare = ({
  title,
  shareUrl = '',
}: useNavigatorShareProps) => {
  const { navigator } = window;

  return () => {
    navigator?.share?.({ title, url: shareUrl }).catch(() => {});
  };
};
