import { DeniedPostPageReason } from '@amzn/ring-neighbors-contentful-gql-schema';
import { Image } from '@amzn/ring-safe-web';
import { Box as BoxChackra } from '@amzn/ring-ui-react-components';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import { useEffect, useRef } from 'react';
import { ResponsiveValue, Space } from 'src/shared/theme';
import { Box } from '../Box';
import { Button } from '../Button';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  ModalProps,
  ModalHeader,
  ModalCloseButton,
} from '../Modal';

export interface DeniedPostModalProps extends ModalProps {
  headerText?: string;
  title: string;
  bodyText?: DeniedPostPageReason['json'];
  primaryBtnText: string;
  secondaryBtnText: string;
  imageUrl: string;
  onClose?: (...args: any[]) => void;
  onOpen?: (...args: any[]) => void;
  onPrimaryClick: (...args: any[]) => void;
  onSecondaryClick: (...args: any[]) => void;
}

const options = {
  renderNode: {
    [BLOCKS.UL_LIST]: (_: any, children: any) => (
      <BoxChackra as="ul" pl={10}>
        {children}
      </BoxChackra>
    ),
    [BLOCKS.LIST_ITEM]: (_: any, children: any) => (
      <BoxChackra as="li">{children}</BoxChackra>
    ),
    [BLOCKS.PARAGRAPH]: (_: any, children: any) => (
      <BoxChackra as="p" display="block" mb="1rem">
        {children}
      </BoxChackra>
    ),
  },
};

export const DeniedPostModal = ({
  secondaryBtnText,
  bodyText,
  headerText,
  title,
  onClose,
  onPrimaryClick,
  onSecondaryClick,
  primaryBtnText,
  imageUrl,
  onOpen,
  ...props
}: DeniedPostModalProps) => {
  const pageViewRef = useRef<boolean>(false);
  useEffect(() => {
    if (pageViewRef.current) return;

    if (props.isOpen === true && onOpen) {
      onOpen();
      pageViewRef.current = true;
    }
  }, [props.isOpen, onOpen]);

  return (
    <Modal
      data-testid="denied-post-modal"
      onClose={onClose}
      isCentered
      size="xlg"
      {...props}
    >
      <ModalOverlay>
        <ModalContent height="100%" p={0}>
          <ModalHeader justifyContent="center" width={1}>
            {headerText}
          </ModalHeader>
          {onClose && (
            <ModalCloseButton
              data-testid="denied-post-modal-close"
              color="neutral.500"
            />
          )}
          <ModalBody
            data-testid="denied-post-modal-body"
            py={py}
            px={px}
            textAlign="left"
          >
            <Box mb={2}>
              <Image width="36px" src={imageUrl} alt="denied post icon" />
            </Box>
            <Box
              textStyle={{
                _: 'heading-small-medium',
                m: 'heading-normal-medium',
              }}
              mb={2}
            >
              {title}
            </Box>
            {bodyText && (
              <Box textStyle="body-large-light">
                {documentToReactComponents(bodyText, options)}
              </Box>
            )}
          </ModalBody>
          <ModalFooter
            borderTopWidth={0}
            px={px}
            pb={px}
            pt={{ _: 4, m: 0 }}
            boxShadow={{ _: 'shadow_bottom500', m: 'none' }}
          >
            <Button
              data-testid="denied-post-modal-secondary-button"
              size="lg"
              onClick={onSecondaryClick}
              variant="outline-primary"
              mr={{ _: 0, m: 4 }}
              mb={{ _: 3, m: 0 }}
              width={{ _: 1, m: 1 / 2 }}
            >
              {secondaryBtnText}
            </Button>
            <Button
              data-testid="denied-post-modal-primary-button"
              size="lg"
              variant="primary"
              onClick={onPrimaryClick}
              width={{ _: 1, m: 1 / 2 }}
            >
              {primaryBtnText}
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

const py = { _: 6, m: 7 } as ResponsiveValue<Space>;
const px = { _: 6, m: 8 } as ResponsiveValue<Space>;
