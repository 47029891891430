import {
  CreateEventAssetHandlingUrlsMutation,
  CreateEventAssetHandlingUrlsMutationVariables,
} from '@amzn/ring-neighbors-api-orchestrator-gql-schema';
import EVENT_ASSET_HANDLING_URLS_CREATE from '@amzn/ring-neighbors-api-orchestrator-gql-schema/operations/authenticated/event/createEventAssetHandlingUrls.graphql';
import { useMutation } from '@apollo/client';

export const useEventAssetHandlingUrlsCreateMutation = () => {
  const [createEventAssetHandlingUrl] = useMutation<
    CreateEventAssetHandlingUrlsMutation,
    CreateEventAssetHandlingUrlsMutationVariables
  >(EVENT_ASSET_HANDLING_URLS_CREATE);

  return createEventAssetHandlingUrl;
};
