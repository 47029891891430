import {
  CreateEventMutation,
  CreateEventMutationVariables,
} from '@amzn/ring-neighbors-api-orchestrator-gql-schema';
import EVENT_CREATE from '@amzn/ring-neighbors-api-orchestrator-gql-schema/operations/authenticated/event/createEvent.graphql';
import { useMutation } from '@apollo/client';

export const useEventCreateMutation = () => {
  const [createEvent] =
    useMutation<CreateEventMutation, CreateEventMutationVariables>(
      EVENT_CREATE,
    );

  return createEvent;
};
