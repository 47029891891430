import {
  FeedFilters,
  GetFeedQuery,
  GetFeedQueryVariables,
} from '@amzn/ring-neighbors-api-orchestrator-gql-schema';
import GET_FEED from '@amzn/ring-neighbors-api-orchestrator-gql-schema/operations/authenticated/event/getFeed.graphql';
import { getNpssUser, useIsNpssUser } from 'src/auth';
import { useQueryWithBoundary } from 'src/hooks';

export const useGetNpssPublishedPosts = (input: FeedFilters = {}) => {
  const isNpssUser = useIsNpssUser();
  const npssUser = getNpssUser();

  const { data, ...rest } = useQueryWithBoundary<
    GetFeedQuery,
    GetFeedQueryVariables
  >(GET_FEED, {
    notifyOnNetworkStatusChange: true,
    skip: !isNpssUser,
    variables: {
      input: {
        ...input,
        include_agencies:
          npssUser && npssUser.agency_uuid
            ? [npssUser?.agency_uuid]
            : undefined,
        exclude_sponsored_posts: true,
      },
      alert_area_id: '',
    },
  });

  return {
    ...rest,
    data: data?.feed?.edges?.map((edge) => edge?.node) || [],
  };
};
