import { calc, useToken } from '@amzn/ring-ui-react-components';
import { StringOrNumber } from '@chakra-ui/utils';

export const useContainerHeight = <T extends StringOrNumber>(
  tokens: T | T[],
) => {
  const heights = useToken(
    'space',
    Array.isArray(tokens) ? tokens : [tokens],
  ) as string[];

  let chain = calc(
    CSS.supports && CSS.supports('height: 100dvh') ? '100dvh' : '100vh',
  );

  heights.forEach((height) => {
    chain = chain.subtract(height);
  });

  const height = chain.toString();

  return height;
};
