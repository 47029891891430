import { Box, BoxProps } from '@amzn/ring-ui-react-components';
import { forwardRef, useMemo } from 'react';
import {
  FormControlContextProvider,
  FormControlContextValue,
} from './FormControlContext';

export type AnyFormControlContextValue = OverwriteTypes<
  FormControlContextValue,
  any
>;

export interface FormControlProps
  extends Omit<BoxProps, 'role'>,
    AnyFormControlContextValue {}

export const FormControl = forwardRef<HTMLDivElement, FormControlProps>(
  ({ disabled, invalid, readOnly, required, ...props }, ref) => {
    const context = useMemo(
      () => ({
        disabled: Boolean(disabled),
        invalid: Boolean(invalid),
        readOnly: Boolean(readOnly),
        required: Boolean(required),
      }),
      [disabled, invalid, readOnly, required],
    );

    return (
      <FormControlContextProvider value={context}>
        <Box _notLast={{ mb: 3 }} {...props} ref={ref} />
      </FormControlContextProvider>
    );
  },
);
