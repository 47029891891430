import { useHistory } from 'react-router-dom';

export const useGoTo = () => {
  const history = useHistory();
  const goTo = (url: string, external: boolean) => {
    const intentUrl = url.toLocaleLowerCase();
    if (intentUrl.startsWith('http')) {
      window.open(url, external ? '_blank' : '_self');
    }
    if (intentUrl.startsWith('/')) {
      history.push(url);
    }
  };
  return goTo;
};
