import { getSpectrometerScriptTags } from '@amzn/cathode-versions-javascript';
import { useEffect } from 'react';
import { isLocalBuild, isProd } from 'src/utils';

const name = 'RingNeighborsWeb';

export const useSpectrometer = () => {
  useEffect(() => {
    const spectrometerTags = getSpectrometerScriptTags(
      {
        siteName: name,
        appName: name,
      },
      isProd,
    );

    try {
      Object.values(spectrometerTags).forEach((tag) => {
        const element = document.createElement('script');
        element.innerHTML = tag.replace(/<\/?script[^>]*>/g, '');
        document.head.appendChild(element);
      });
    } catch (error) {
      if (isLocalBuild) {
        console.error('Cannot initialize Spectrometer:', error);
      }
    }
  }, []);
};
