import { DateTime, DurationUnits, ToRelativeUnit } from 'luxon';
import i18n from 'src/i18n';

const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

const monthAndDayFormat = 'MM/dd';
const dateFormat = `${monthAndDayFormat}/yy` as const;
const timeAndTZFormat = 'h:mm a ZZZZ';
const timeFormat = 'h:mm a';
const fullFormat = `${dateFormat} ${timeAndTZFormat}` as const;
const monthAbbrFormat = 'MMM';
const monthFullFormat = 'MMMM';

export const createDateTimeFromJSDate = (date: Date) =>
  DateTime.fromJSDate(date, {
    zone: timeZone,
  });

export const createDateTimeFromISO = (date: string) =>
  DateTime.fromISO(date, {
    zone: timeZone,
    locale: i18n.language,
  });

export const formatTimeAndTZ = (dt: DateTime) => dt.toFormat(timeAndTZFormat);

export const formatTimeAndTZFromString = (dtString: string) => {
  const dt = createDateTimeFromISO(dtString);
  return dt.toFormat(timeAndTZFormat);
};

export const formatTime = (dt: DateTime) => dt.toFormat(timeFormat);

export const formatMonthAndDay = (dt: DateTime) =>
  dt.toFormat(monthAndDayFormat);

export const formatMonthAbbr = (dt: DateTime) => dt.toFormat(monthAbbrFormat);

export const formatMonthFull = (dt: DateTime) => dt.toFormat(monthFullFormat);

export const formatDate = (dt: DateTime) => dt.toFormat(dateFormat);

export const formatDateFromString = (dtString: string) => {
  const dt = createDateTimeFromISO(dtString);
  return dt.toFormat(dateFormat);
};

export const formatFull = (dt: DateTime) => dt.toFormat(fullFormat);

export const areSameDay = (dt1: DateTime, dt2: DateTime) =>
  dt1.hasSame(dt2, 'day');

export const getTimeRange = (start: string, end: string) => {
  const startDt = createDateTimeFromISO(start);
  const endDt = createDateTimeFromISO(end);

  const sameDay = areSameDay(startDt, endDt);
  const endFormatted = sameDay ? formatTimeAndTZ(endDt) : formatFull(endDt);
  const startFormatted = formatFull(startDt);

  return `${startFormatted} to ${endFormatted}`;
};

export const getDay = (dt: DateTime) => dt.weekdayShort;

export const getMonthAndDayFromDate = (date: string) =>
  formatMonthAndDay(createDateTimeFromISO(date).setZone('UTC'));

export const getTimeSinceDate = (dateString: string) => {
  const units = [
    'years',
    'months',
    'days',
    'hours',
    'minutes',
    'seconds',
  ] as DurationUnits;
  const date = createDateTimeFromISO(dateString);
  const now = DateTime.now();
  const duration = now.diff(date, units);

  return now.minus(duration).toRelative({
    unit: units as ToRelativeUnit,
    locale: i18n.language,
    style: 'narrow',
  });
};
