import { Box, Divider } from '@amzn/ring-ui-react-components';

export interface PetProfileHeaderProps {
  children: React.ReactNode;
}

export const PetProfileHeader = ({
  children,
  ...props
}: PetProfileHeaderProps) => (
  <>
    <Box
      {...props}
      textStyle="body-normal-medium"
      color="neutral.300"
      as="span"
      px={6}
      pt={6}
      pb={3}
    >
      {children}
    </Box>
    <Divider />
  </>
);
