import { navbarHeight, theme } from '@amzn/ring-ui-react-components';
import type {
  GlobalStyleProps,
  SystemStyleFunction,
} from '@chakra-ui/theme-tools';

export const styles = {
  global: (props: GlobalStyleProps) => ({
    ...(theme.styles.global as SystemStyleFunction)(props),
    '.Toastify__toast-container': {
      p: 0,
      position: 'fixed',
      top: navbarHeight,
      transition: 'all 2s ease',
      width: '100%',
      zIndex: 'toast',
    },
    '.Toastify__toast': {
      boxShadow: 'sm',
      color: 'neutral.500',
      m: 0,
      maxHeight: 'unset',
      minHeight: 'unset',
      px: 10,
      py: 4,
      textStyle: 'body-normal-medium',
      '&.Toastify__toast--success': {
        bg: 'positive.100',
        color: 'positive.500',
        '& button path': {
          fill: 'positive.500',
        },
      },
      '&.Toastify__toast--error': {
        bg: 'negative.100',
        color: 'negative.500',
        '& button path': {
          fill: 'negative.500',
        },
      },
      '& .Toastify__toast-body': {
        p: 0,
        m: 0,
      },
    },
  }),
};
