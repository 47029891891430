import { callAllHandlers } from '@chakra-ui/utils';
import { forwardRef, useMemo } from 'react';
import { isLocalBuild } from 'src/utils';
import { useModalContext } from './ModalContext';
import { Button, ButtonProps } from '../Button';
import { Icon } from '../Icon';

type ModalCloseButtonProps = Omit<ButtonProps, 'type' | 'aria-label'>;

export const ModalCloseButton = forwardRef<
  HTMLButtonElement,
  ModalCloseButtonProps
>(({ onClick, children, ...props }, ref) => {
  const { onClose } = useModalContext();

  const handleClick = useMemo(
    () => callAllHandlers(onClick, onClose),
    [onClick, onClose],
  );

  return (
    <Button
      data-testid="modal-close-button"
      right={3}
      top={3}
      size={24}
      {...props}
      aria-label="Close"
      backgroundColor="transparent"
      border="none"
      onClick={handleClick}
      p={0}
      position="absolute"
      ref={ref}
      type="button"
      variant={false}
    >
      {children ?? close}
    </Button>
  );
});

if (isLocalBuild) {
  ModalCloseButton.displayName = 'ModalCloseButton';
}

const close = <Icon name="close" size={14} />;
