import { Box, BoxBasedComponentProps } from '../Box';
import { Icon } from '../Icon';

type EventGalleryArrowButtonProps = BoxBasedComponentProps<'div'>;

const EventGalleryArrowButton = ({
  children,
  ...props
}: EventGalleryArrowButtonProps) => (
  <Box
    position="absolute"
    py={5}
    top="50%"
    transform="translateY(-50%)"
    cursor="pointer"
    zIndex={4}
    {...props}
  >
    <Box bg="white" borderRadius="circle" opacity={0.8} p={0} width="auto">
      {children}
    </Box>
  </Box>
);

export const EventGalleryLeftNav = (
  onClick: EventGalleryArrowButtonProps['onClick'],
) => (
  <EventGalleryArrowButton
    data-testid="event-media-gallery-left-button"
    left={6}
    onClick={onClick}
  >
    <Icon color="neutral.500" name="chevron-left" size={32} />
  </EventGalleryArrowButton>
);

export const EventGalleryRightNav = (
  onClick: EventGalleryArrowButtonProps['onClick'],
) => (
  <EventGalleryArrowButton
    data-testid="event-media-gallery-right-button"
    onClick={onClick}
    right={6}
  >
    <Icon color="neutral.500" name="chevron-right" size={32} />
  </EventGalleryArrowButton>
);
