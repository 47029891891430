import { Maybe } from '@amzn/ring-neighbors-api-orchestrator-gql-schema';
import {
  Divider,
  MedicalInfoIcon,
  OtherEventIcon,
  Box,
  TagIcon,
  DogIcon,
  PetBreedIcon,
  PetGenderIcon,
  CalendarIcon,
  LegalIcon,
  DropIcon,
} from '@amzn/ring-ui-react-components';
import { useCallback, useState, useEffect, useRef, useMemo } from 'react';
import { useParams } from 'react-router';
import {
  useNeighborsWebClickEventStreamDispatcher,
  useNeighborsWebViewEventStreamDispatcher,
} from 'src/components/App/hooks';
import { MedicalInfoModal } from 'src/features/PetTag/components/PetProfile/MedicalInfoModal';
import { PetProfileHeader } from 'src/features/PetTag/components/PetProfileHeader';
import { PetProfileItem } from 'src/features/PetTag/components/PetProfileItem';
import { usePetProfileById } from 'src/features/PetTag/hooks';
import { calculateAge } from 'src/features/PetTag/utils';
import { ContactOwnerBottomTray } from 'src/shared/components/ContactOwnerBottomTray';
import { EventMediaGallery } from 'src/shared/components/EventMediaGallery';
import { Loading } from 'src/shared/components/Loading';
import { OtherInfoModal } from './OtherInfoModal';
import { PetProfileMenuItem } from './PetProfileMenuItem';

export const getPetAge = (dateOfBirth: Maybe<string> | undefined) => {
  if (dateOfBirth) {
    return calculateAge(dateOfBirth);
  }
  return '-';
};

const eventStreamParams = {
  friendlyName: 'Pet Profile Public Page',
  referrer: 'nhWeb_lostPetFlyerPage',
  referringItem: 'nh_viewPetProfile',
  referringAction: 'click',
};

export const PetProfile = () => {
  const [isMedicalInfoOpen, setMedicalInfoOpen] = useState(false);
  const [isOtherInfoOpen, setOtherInfoOpen] = useState(false);
  const { id } = useParams<{ id: string }>();
  const { petProfile } = usePetProfileById(id);

  const toggleMedicalInfo = useCallback(() => {
    setMedicalInfoOpen((current) => !current);
  }, [setMedicalInfoOpen]);

  const toggleOtherInfo = useCallback(() => {
    setOtherInfoOpen((current) => !current);
  }, [setOtherInfoOpen]);

  const pageViewRef = useRef(false);
  const dispatchView = useNeighborsWebViewEventStreamDispatcher(
    'nhWeb_petProfilePublicPage',
  );
  const dispatchClick = useNeighborsWebClickEventStreamDispatcher(
    'nhWeb_petProfilePublicPage',
  );

  useEffect(() => {
    if (pageViewRef.current === true) return;
    dispatchView([], eventStreamParams);
    pageViewRef.current = true;
  }, [dispatchView]);

  const handleTrayClick = () => {
    if (dispatchClick)
      dispatchClick('nhWeb_contactOwner', [], { itemType: 'button' });
  };

  const age = useMemo(
    () => getPetAge(petProfile?.dateOfBirth),
    [petProfile?.dateOfBirth],
  );

  if (!petProfile) {
    return (
      <Box display="flex" justifyContent="center" mt={2}>
        <Loading data-testid="pet-profile-loading-indicator" />
      </Box>
    );
  }

  return (
    <Box mb={20}>
      <EventMediaGallery
        borderRadius="regular"
        eventId=""
        media={petProfile.media}
        onMediaClick={() => {}}
        showIndex
        thumbnailType="dashes"
        showDeviceBanner={false}
      />
      <PetProfileHeader data-testid="pet-profile-header">
        General Information
      </PetProfileHeader>
      <PetProfileItem
        Icon={TagIcon}
        title="Name"
        data-testid="pet-profile-name"
        description={petProfile.name}
      />
      {petProfile.type && petProfile.type !== '' && (
        <PetProfileItem
          data-testid="pet-profile-type"
          Icon={DogIcon}
          title="Type"
          description={petProfile.type}
        />
      )}
      {(petProfile.breed ||
        petProfile.gender ||
        petProfile.dateOfBirth ||
        petProfile.weight ||
        petProfile.color ||
        petProfile.medicalInfo ||
        petProfile.otherInfo) && (
        <PetProfileHeader data-testid="pet-profile-details">
          Pet's Details
        </PetProfileHeader>
      )}
      {petProfile.breed && petProfile.breed !== '' && (
        <PetProfileItem
          Icon={PetBreedIcon}
          title="Breed"
          description={petProfile.breed}
          data-testid="pet-profile-breed"
        />
      )}
      {petProfile.gender && petProfile.gender !== '' && (
        <PetProfileItem
          Icon={PetGenderIcon}
          title="Gender"
          description={petProfile.gender}
          data-testid="pet-profile-gender"
        />
      )}
      {petProfile.dateOfBirth && petProfile.dateOfBirth !== '' && (
        <PetProfileItem
          Icon={CalendarIcon}
          title="Age"
          description={age}
          data-testid="pet-profile-age"
        />
      )}
      {petProfile.weight && (
        <PetProfileItem
          Icon={LegalIcon}
          title="Weight"
          description={petProfile.weight}
          data-testid="pet-profile-weight"
        />
      )}
      {petProfile.color && petProfile.color !== '' && (
        <PetProfileItem
          Icon={DropIcon}
          title="Color"
          description={petProfile.color}
          data-testid="pet-profile-color"
        />
      )}
      {petProfile.medicalInfo && petProfile.medicalInfo !== '' && (
        <>
          <PetProfileMenuItem
            icon={<MedicalInfoIcon color="primary.500" boxSize={8} />}
            title="Medical Info"
            description={petProfile.medicalInfo}
            onClick={toggleMedicalInfo}
            data-testid="pet-profile-medical-info"
          />
          <Divider />
        </>
      )}
      {petProfile.otherInfo && petProfile.otherInfo !== '' && (
        <>
          <PetProfileMenuItem
            icon={<OtherEventIcon color="primary.500" boxSize={8} />}
            title="Other Info"
            description={petProfile.otherInfo}
            onClick={toggleOtherInfo}
            data-testid="pet-profile-other-info"
          />
          <Divider />
        </>
      )}
      <MedicalInfoModal
        isOpen={isMedicalInfoOpen}
        onClose={toggleMedicalInfo}
        petName={petProfile.name}
        description={petProfile.medicalInfo}
        data-testid="pet-profile-medical-info-modal"
      />
      <OtherInfoModal
        isOpen={isOtherInfoOpen}
        onClose={toggleOtherInfo}
        petName={petProfile.name}
        description={petProfile.otherInfo}
        data-testid="pet-profile-other-info-modal"
      />
      {petProfile!.phoneId && (
        <ContactOwnerBottomTray
          data-testid="pet-profile-contact-owner"
          message=""
          phoneId={petProfile!.phoneId}
          clickHandler={handleTrayClick}
        />
      )}
    </Box>
  );
};

export default PetProfile;
