import { rgba } from 'polished';
import { colors } from './colors';

export const shadows = {
  dropDown: '0px 2px 8px 0px rgba(0, 0, 0, 0.15)',
  header: '0px 1px 4px rgba(0, 0, 0, 0.15)',
  sm: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
  md: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
  lg: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
  xl: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
  '2xl': '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
  outline: `0 0 0 3px ${rgba(colors.primary[500], 0.6)}`,
  inner: 'inset 0 2px 4px 0 rgba(0,0,0,0.06)',
  none: 'none',
  mapbox: '0px 2px 4px rgba(0, 0, 0, 0.15)',
  shadow_raised300: '0px 1px 2px rgba(0, 0, 0, 0.15)',
  shadow_bottom500: '0px -2px 8px rgba(0, 0, 0, 0.15)',
} as const;
