import {
  navbarHeight,
  navEventKey,
  useNavbarContext,
} from '@amzn/ring-ui-react-components';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getNpssUser } from 'src/auth';
import { NavbarBase, NavMobileButton } from 'src/components/Navbar/NavbarBase';
import { config } from 'src/config';
import { usePushToFeed } from 'src/hooks';
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  NpssDrawerMenu,
  NpssDrawerMenuItem,
} from 'src/shared/components/Drawer';
import { goToLogout } from 'src/utils';
const { NPSS_BASE_URL, NPSS_HELP_URL } = config;

const NpssNavBar = () => {
  const { t } = useTranslation('components.navbar', { useSuspense: false });
  const { activeKey } = useNavbarContext();
  const handlePushToFeed = usePushToFeed();

  const isOpen = navEventKey === activeKey;

  const nppssUser = getNpssUser();
  const agencyUuid = useMemo(() => {
    if (nppssUser != null) {
      return nppssUser.agency_uuid;
    }
    return '';
  }, [nppssUser]);
  return (
    <>
      <NavMobileButton
        icon="feed"
        onClick={handlePushToFeed}
        data-testid="feed-navbar-link"
      >
        {t('feed')}
      </NavMobileButton>
      <Drawer isOpen={isOpen} top={navbarHeight}>
        <DrawerBody>
          <NpssDrawerMenu title="">
            <NpssDrawerMenuItem
              icon="post_neighborhoods"
              href="/settings/my-posts"
              data-testid="my-posts-navbar-link"
              as="a"
            >
              {t('my-posts')}
            </NpssDrawerMenuItem>
            <NpssDrawerMenuItem
              icon="report"
              href={NPSS_BASE_URL + '/agency-reports'}
              data-testid="report-navbar-link"
              as="a"
            >
              {t('report')}
            </NpssDrawerMenuItem>
            <NpssDrawerMenuItem
              icon="agency"
              href={NPSS_BASE_URL + '/agencies/' + agencyUuid}
              data-testid="profile-navbar-link"
              as="a"
            >
              {t('profile')}
            </NpssDrawerMenuItem>
            <NpssDrawerMenuItem
              icon="question_circle"
              href={NPSS_HELP_URL}
              data-testid="question-help-navbar-link"
              as="a"
            >
              {t('help')}
            </NpssDrawerMenuItem>
          </NpssDrawerMenu>
          <NpssDrawerMenu title="Settings">
            <NpssDrawerMenuItem
              icon="account-mgmt"
              href={NPSS_BASE_URL + '/profile'}
              data-testid="account-settings-navbar-link"
              as="a"
            >
              {t('account-settings')}
            </NpssDrawerMenuItem>
            <NpssDrawerMenuItem
              icon="email_settings"
              href="/settings/email"
              data-testid="email-setting-navbar-link"
              as="a"
            >
              {t('email-settings')}
            </NpssDrawerMenuItem>
            <NpssDrawerMenuItem
              icon="geofencing"
              href={NPSS_BASE_URL + '/assigned_areas'}
              data-testid="assigned-areas-navbar-link"
              as="a"
            >
              {t('assigned-areas')}
            </NpssDrawerMenuItem>
            <NpssDrawerMenuItem
              icon="shared_user"
              href={NPSS_BASE_URL + '/alerts/my/published'}
              data-testid="manage-users-navbar-link"
              as="a"
            >
              {t('manage-users')}
            </NpssDrawerMenuItem>
          </NpssDrawerMenu>
        </DrawerBody>
        <DrawerFooter>
          <NpssDrawerMenu>
            <NpssDrawerMenuItem
              as="a"
              onClick={goToLogout}
              data-testid="sign-out-navbar-link"
              icon="sign-out"
            >
              {t('sign-out')}
            </NpssDrawerMenuItem>
          </NpssDrawerMenu>
        </DrawerFooter>
      </Drawer>
    </>
  );
};

export const NpssNavBarMobile = () => (
  <NavbarBase pl={4} pr={2} iconSize={6}>
    <NpssNavBar />
  </NavbarBase>
);
