import { forwardRef } from 'react';
import {
  Button as ButtonVariant,
  Radii,
  ResponsiveValue,
} from 'src/shared/theme';
import { isLocalBuild } from 'src/utils';
import { Box, BoxBasedComponentProps } from '../Box';

type ButtonType = 'a' | 'button';

export type ButtonProps<T extends ButtonType = 'button'> =
  BoxBasedComponentProps<
    T,
    {
      block?: boolean;
      shape?: Radii;
      size?: 'sm' | 'md' | 'lg' | number;
      variant?: ResponsiveValue<ButtonVariant> | false;
      disabled?: boolean;
    }
  >;

export const Button = forwardRef<
  HTMLAnchorElement | HTMLButtonElement,
  ButtonProps<'a'> | ButtonProps<'button'>
>(
  (
    {
      as = 'button',
      block = false,
      disabled = false,
      shape = 'round',
      size = 'md',
      sx,
      variant = 'primary',
      ...props
    },
    ref,
  ) => {
    const asProps = {
      disabled: as === 'button' ? disabled : undefined,
    };

    const sizeStyles = getSizeStyles(size);

    const borderRadius = (variant === 'empty' ? 'regular' : shape) as Radii;

    return (
      <Box
        appearance="none"
        display="inline-block"
        textAlign="center"
        lineHeight="inherit"
        textDecoration="none"
        {...sizeStyles}
        {...props}
        {...asProps}
        ref={ref}
        as={as}
        variant={variant ? variant : undefined}
        cursor="pointer"
        borderRadius={borderRadius}
        tabIndex={0}
        sx={[
          sx,
          block && { display: 'block', width: '100%' },
          disabled && {
            bg: 'primary.200',
            pointerEvents: 'none',
          },
        ]}
      />
    );
  },
);

if (isLocalBuild) {
  Button.displayName = 'Button';
}

const getSizeStyles = (
  size: ButtonProps['size'],
): Pick<ButtonProps, 'py' | 'px' | 'textStyle' | 'size'> => {
  switch (size) {
    case 'lg':
      return { py: 3, px: 4, textStyle: 'body-large-medium' };
    case 'md':
      return { py: 2, px: 3, textStyle: 'body-normal-medium' };
    case 'sm':
      return { py: 1, px: 2, textStyle: 'body-small-medium' };
    default:
      return { size };
  }
};
