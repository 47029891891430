import { Box } from '../Box';
import {
  Modal,
  ModalBackButton,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from '../Modal';

export interface ReportModalProps extends ModalProps {
  description: string;
  isLoading?: boolean;
  onBackClick?: () => void;
  title: string;
}

export const ReportModal = ({
  children,
  description,
  isLoading,
  onBackClick,
  title,
  ...props
}: ReportModalProps) => (
  <Modal {...props} isCentered>
    <ModalOverlay>
      <ModalContent>
        {onBackClick && !isLoading && <ModalBackButton onClick={onBackClick} />}
        <ModalHeader justifyContent="center">{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody p={3}>
          <Box color="backup" textStyle="body-normal-regular">
            {description}
          </Box>
          {children}
        </ModalBody>
      </ModalContent>
    </ModalOverlay>
  </Modal>
);
