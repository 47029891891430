import { useMemo } from 'react';

const regex =
  '/^[\xF0-\xF7][\x80-\xBF][\x80-\xBF][\x80-\xBF]|^[\xE0-\xEF][\x80-\xBF][\x80-\xBF]|^[\xC0-\xDF][\x80-\xBF]|^[\x00-\x7f]*/gu';

export const useNormalizedText = (text: string) =>
  useMemo(() => {
    const matches = text.match(regex);
    const replaced = matches ? text.replace(regex, '') : text;
    const trimmed = replaced.trim();

    return trimmed;
  }, [text]);
