import { createContext } from '@chakra-ui/react-utils';
import { isArray } from '@chakra-ui/utils';
import { ReactNode, useMemo } from 'react';
import { config } from 'src/config';
import { isLocalBuild } from 'src/utils';

const {
  ENABLE_RESOLVE_SWITCH,
  ENABLE_MARKDOWN,
  ENABLE_UNIFIED_MY_POSTS_PAGE,
  ENABLE_NHWEB_NPSS_FEED_QUERY_UNIFICATION,
} = config;

const features = {
  ENABLE_RESOLVE_SWITCH,
  ENABLE_MARKDOWN,
  ENABLE_UNIFIED_MY_POSTS_PAGE,
  ENABLE_NHWEB_NPSS_FEED_QUERY_UNIFICATION,
} as const;

type ConfigFeatures = typeof features;

type DynamicFeatures = {
  ENABLE_INTERNAL_PREVIEW: boolean;
};

export type Features = keyof ConfigFeatures | keyof DynamicFeatures;

type FeaturesMap = ConfigFeatures & DynamicFeatures;

const [FeatureFlagsContextProvider, useFeatureContext] =
  createContext<FeaturesMap>({
    name: 'FeatureFlagsContext',
  });

export interface FeatureFlagsProviderProps {
  children: ReactNode;
  featureFlags: DynamicFeatures;
}

export const FeatureFlagsProvider = ({
  children,
  featureFlags: featureFlagsProp,
}: FeatureFlagsProviderProps) => {
  const { ENABLE_INTERNAL_PREVIEW } = featureFlagsProp;

  const featureFlags = useMemo(
    () => ({
      ...features,
      ENABLE_INTERNAL_PREVIEW: isLocalBuild ? true : ENABLE_INTERNAL_PREVIEW, // TODO: remove local env check
    }),
    [ENABLE_INTERNAL_PREVIEW],
  );

  return (
    <FeatureFlagsContextProvider value={featureFlags}>
      {children}
    </FeatureFlagsContextProvider>
  );
};

export const useFeature = <T extends Features | Features[]>(
  keys: T,
): T extends Features[] ? boolean[] : boolean => {
  const context = useFeatureContext();

  if (!isArray(keys)) {
    return context[keys as Features] as any;
  }

  return keys.map((k) => context[k]) as any;
};
