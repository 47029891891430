import { Input } from '@amzn/ring-safe-web';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Textarea,
  useTheme,
} from '@amzn/ring-ui-react-components';
import { useBreakpointValue } from '@chakra-ui/react';
import { callAllHandlers } from '@chakra-ui/utils';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  MAX_CHARACTERS_DESCRIPTION,
  MAX_CHARACTERS_TITLE,
} from 'src/components/Posting/Steps/schema';
import { useIsDesktop } from 'src/shared/hooks';
import { ScheduledEvent } from 'src/utils';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from '../Modal';

export interface EventCardUpdateScheduledPostModalProps
  extends Omit<ModalProps, 'children'> {
  onSubmit: (id: string, title: string, description: string) => void;
  event: ScheduledEvent;
}

export const EventCardUpdateScheduledPostModal = ({
  isOpen,
  onClose,
  onSubmit,
  event,
}: EventCardUpdateScheduledPostModalProps) => {
  const eventTitle = event.details?.title || '';
  const eventDescription = event.details?.description || '';
  const { t } = useTranslation('features.myPosts');
  const theme = useTheme();
  const isDesktop = useIsDesktop();
  const cancelButtonVariant = isDesktop ? 'outline' : 'ghost';
  const [lengthTextArea, setLengthTextArea] = useState(eventDescription.length);
  const [lengthInput, setLengthInput] = useState(eventTitle.length);
  const [title, setTitle] = useState(eventTitle);
  const [description, setDescription] = useState(eventDescription);
  const widthValues = useBreakpointValue({
    base: '546px',
    m: '327px',
  });

  const handleChangeTextArea = callAllHandlers(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      setDescription(e.target.value);
      setLengthTextArea(e.target.value.length);
    },
  );

  const handleChangeInput = callAllHandlers(
    (e: ChangeEvent<HTMLInputElement>) => {
      setTitle(e.target.value);
      setLengthInput(e.target.value.length);
    },
  );

  const handleSubmit = callAllHandlers(
    () => onSubmit?.(event.id, title, description),
    onClose,
  );

  const handleCancel = () => {
    setTitle(eventTitle);
    setDescription(eventDescription);
    onClose?.();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleCancel} isCentered>
      <ModalOverlay>
        <ModalContent maxWidth="1366px" maxHeight="768px">
          <ModalHeader justifyContent="center" py={4}>
            <Box textStyle="body-normal-medium">
              {t('edit-scheduled-post-modal.title')}
            </Box>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody px={{ base: 6, m: 8 }} py={6}>
            <Box textStyle="heading-normal-medium" mb={2}>
              {t('edit-scheduled-post-modal.subtitle')}
            </Box>
            <FormControl>
              <Box px={0} maxWidth={widthValues}>
                <Box width="100%">
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <FormHelperText textStyle="body-small-regular" mb={1}>
                      {t('edit-scheduled-post-modal.title-label')}
                    </FormHelperText>
                    <FormHelperText textStyle="body-small-light" mb={1}>
                      {`${lengthInput}/${MAX_CHARACTERS_TITLE}`}
                    </FormHelperText>
                  </Box>
                  <Input
                    id="scheduled-post-title"
                    data-testid="scheduled-post-title"
                    value={title}
                    label=""
                    status="default"
                    style={{
                      minWidth: widthValues,
                      marginTop: '-20px',
                    }}
                    maxLength={MAX_CHARACTERS_TITLE}
                    onChange={handleChangeInput}
                  />
                </Box>
                <Box width="100%" mt="16px">
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mb="2px"
                  >
                    <FormHelperText textStyle="body-small-regular" mb={1}>
                      {t('edit-scheduled-post-modal.description-label')}
                    </FormHelperText>
                    <FormHelperText textStyle="body-small-light" mb={1}>
                      {`${lengthTextArea}/${MAX_CHARACTERS_DESCRIPTION}`}
                    </FormHelperText>
                  </Box>
                  <Textarea
                    id="scheduled-post-description"
                    data-testid="scheduled-post-description"
                    maxLength={MAX_CHARACTERS_DESCRIPTION}
                    onChange={handleChangeTextArea}
                    label="Description"
                    borderColor="tertiary.700"
                    rows={6}
                    _hover={{
                      borderColor: 'tertiary.700',
                    }}
                    _focus={{
                      borderColor: 'primary.500',
                      boxShadow: `0 0 0 1px ${theme.colors.primary[500]}`,
                    }}
                    style={{ maxWidth: widthValues }}
                    value={description}
                  />
                </Box>
              </Box>
            </FormControl>
          </ModalBody>
          <ModalFooter
            px={6}
            py={4}
            flexDirection={{
              base: 'column-reverse',
              m: 'row',
            }}
            borderTop={{ m: 'none' }}
          >
            <Box
              as={Button}
              variant={cancelButtonVariant}
              onClick={handleCancel}
              mr={{ base: 0, m: 2 }}
              width={{ base: '100%', m: 200 }}
            >
              {t('general-modal.cancel')}
            </Box>
            <Box
              as={Button}
              onClick={handleSubmit}
              mb={{ base: 3, m: 0 }}
              width={{ base: '100%', m: 200 }}
            >
              {t('general-modal.save')}
            </Box>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};
