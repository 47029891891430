import { gql } from '@apollo/client';

export const GET_ACTIVE_EVENT_ID = gql`
  query getActiveEvent {
    activeEventId @client
  }
`;

export const GET_OPENED_EVENT_ID = gql`
  query getOpenedEvent {
    openedEventId @client
  }
`;

export const GET_READ_EVENTS = gql`
  query getReadEvents {
    readEvents @client
  }
`;
