import { IconButton } from '@amzn/ring-ui-react-components';
import { CloseButtonProps } from 'react-toastify/dist/components';
import { Icon } from '../Icon';

export const CloseToastButton = ({ closeToast }: CloseButtonProps) => (
  <IconButton
    aria-label="Close toast"
    boxSize={10}
    icon={<Icon name="close" color="neutral.300" />}
    onClick={closeToast}
    variant="unstyled"
  />
);
