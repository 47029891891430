import { useBoolean } from '@amzn/ring-ui-react-components';
import { useEffect, useRef } from 'react';
import useOnClickOutside from 'use-onclickoutside';

export interface UseVideoPlayerProps {
  reproducible?: boolean;
}

export const useVideoPlayer = ({
  reproducible = false,
}: UseVideoPlayerProps) => {
  const [isInteracting, { on: setInteractingOn, off: setInteractingOff }] =
    useBoolean();

  const [isVideoHover, { on: setVideoHoverTrue, off: setVideoHoverFalse }] =
    useBoolean();

  const [isPlayingState, { on: setPlay, off: setPause }] = useBoolean();

  const containerRef = useRef(null);

  const isPlaying = reproducible && isPlayingState;
  const showControls = reproducible && isVideoHover && isInteracting;

  useEffect(() => {
    if (isPlaying && !isInteracting) {
      setInteractingOn();
    }
  }, [isPlaying, isInteracting, setInteractingOn]);

  useOnClickOutside(containerRef, setInteractingOff);

  return {
    containerRef,
    setVideoHoverFalse,
    isPlaying,
    isVideoHover,
    setInteractingOff,
    setPause,
    setPlay,
    showControls,
    setVideoHoverTrue,
  };
};

export type UseVideoPlayerReturn = ReturnType<typeof useVideoPlayer>;
