import { useBoolean } from '@amzn/ring-ui-react-components';
import { useEffect } from 'react';
import { config } from 'src/config';
import { useAuth } from 'src/context';
import { useHasWebViewUserAgent } from 'src/hooks';

const { CONSENT_MANAGER_SCRIPT_URL } = config;

const CM_SCRIPT_TAG_ID = 'consent-script';
const CM_BANNER_BUTTON_ACCEPT_CLASSNAME = 'consent--accept';

export const useConsentManager = (locale = 'en-US') => {
  const { authStatus } = useAuth();
  const isWebView = useHasWebViewUserAgent();

  const [isTrackingAccepted, { on: setIsTrackingAcceptedOn }] = useBoolean();

  useEffect(() => {
    if (isWebView) {
      return;
    }

    if (document.getElementById(CM_SCRIPT_TAG_ID)) {
      return;
    }

    const script = document.createElement('script');

    script.id = CM_SCRIPT_TAG_ID;
    script.type = 'text/javascript';
    script.src = `${CONSENT_MANAGER_SCRIPT_URL}?loggedIn=1&locale=${locale}`;
    script.async = true;

    document.head.appendChild(script);

    const handleClickAccept = ({ target }: MouseEvent) => {
      if (
        target instanceof HTMLElement &&
        target.className.includes(CM_BANNER_BUTTON_ACCEPT_CLASSNAME)
      ) {
        setIsTrackingAcceptedOn();
      }
    };

    document.addEventListener('click', handleClickAccept);

    return () => {
      document.removeEventListener('click', handleClickAccept);
    };
  }, [authStatus, isWebView, locale, setIsTrackingAcceptedOn]);

  return isTrackingAccepted;
};
