import { createContext } from '@chakra-ui/react-utils';
import { Dispatch, ReactNode, SetStateAction, useMemo, useState } from 'react';
import { useCounter } from 'react-use';
import { AugmentedEvent } from 'src/types/event';
import { NormalizedEvent, useNormalizedEvent } from './hooks';

export interface EventCardStateContextValue {
  event: NormalizedEvent;
  isDetail?: boolean;
  eventAreaCenter: [number, number]; // TODO: provide at a higher level
}

const [EventCardStateProvider, useEventCardState] =
  createContext<EventCardStateContextValue>({
    name: 'EventCardStateContext',
  });

interface EventCardUpdateContextValue {
  incrementVoteCount: () => void;
  decrementVoteCount: () => void;
  setHasUpvoted: Dispatch<SetStateAction<boolean>>;
  setIsResolved: Dispatch<SetStateAction<boolean>>;
  setResolvedMessage: Dispatch<SetStateAction<string>>;
}

const [EventCardUpdateProvider, useEventCardUpdate] =
  createContext<EventCardUpdateContextValue>({
    name: 'EventCardUpdateContext',
  });

export interface EventCardProviderProps
  extends Omit<EventCardStateContextValue, 'event'> {
  event: AugmentedEvent;
  children: ReactNode;
}

export const EventCardProvider = ({
  event,
  isDetail,
  eventAreaCenter,
  children,
}: EventCardProviderProps) => {
  const normalized = useNormalizedEvent(event);

  const [resolvedMessage, setResolvedMessage] = useState<string>(
    normalized.resolved_message as string,
  );

  const [isResolved, setIsResolved] = useState<boolean>(
    normalized.is_resolved as boolean,
  );

  const [hasUpvoted, setHasUpvoted] = useState(normalized.has_upvoted);

  const [upvoteCount, { inc: incrementVoteCount, dec: decrementVoteCount }] =
    useCounter(normalized.upvote_count);

  const finalEvent = useMemo(
    () => ({
      ...normalized,
      has_upvoted: hasUpvoted,
      is_resolved: isResolved,
      resolved_message: resolvedMessage,
      upvote_count: upvoteCount,
    }),
    [normalized, hasUpvoted, isResolved, resolvedMessage, upvoteCount],
  );

  const stateValue = useMemo(
    () => ({ event: finalEvent, isDetail, eventAreaCenter }),
    [finalEvent, isDetail, eventAreaCenter],
  );

  const updateValue = useMemo(
    () => ({
      incrementVoteCount,
      decrementVoteCount,
      setHasUpvoted,
      setIsResolved,
      setResolvedMessage,
    }),
    [incrementVoteCount, decrementVoteCount],
  );

  return (
    <EventCardStateProvider value={stateValue}>
      <EventCardUpdateProvider value={updateValue}>
        {children}
      </EventCardUpdateProvider>
    </EventCardStateProvider>
  );
};

export { useEventCardState, useEventCardUpdate };
