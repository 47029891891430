import { useLocation } from 'react-router-dom';
import { AppRoutes } from 'src/routes';

const PREVIOUS_LOCATION_KEY = 'previousLocation';

const findMatchingRoute = (location: string): string => {
  const routePatterns = Object.values(AppRoutes);

  for (const routePattern of routePatterns) {
    const regexPattern = routePattern
      .replace(/\(\\w\+\)/g, '\\w+')
      .replace(/\/:([a-zA-Z_]+)\(\\w\+\)/g, '/\\w+')
      .replace(/\/:([a-zA-Z_]+)/g, '/[^/]+');

    const regex = new RegExp(`^${regexPattern}$`);

    if (regex.test(location)) {
      return routePattern;
    }
  }
  return '';
};

export const useRouteTracker = () => {
  const { pathname } = useLocation();
  const storeLocation = () =>
    sessionStorage.setItem(PREVIOUS_LOCATION_KEY, pathname);
  const getPreviousRoute = () =>
    findMatchingRoute(sessionStorage.getItem(PREVIOUS_LOCATION_KEY) as string);
  const getPreviousLocation = () =>
    sessionStorage.getItem(PREVIOUS_LOCATION_KEY) as string;
  const getCurrentRoute = () => findMatchingRoute(pathname);
  return {
    storeLocation,
    getPreviousRoute,
    getCurrentRoute,
    getPreviousLocation,
  };
};
