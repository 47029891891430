import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { Announcement } from 'src/components/Announcement';
import { useGetAnnouncement } from 'src/components/Announcement/hooks/useGetAnnouncement';
import type { ToastOptions } from 'react-toastify/dist/types';

const toastOptions = {
  icon: false,
  autoClose: 5000,
  style: {
    textAlign: 'center',
  },
} as ToastOptions;

export const useNpssAnnouncement = () => {
  const announcement = useGetAnnouncement();

  useEffect(() => {
    if (announcement && !sessionStorage.getItem(announcement.title)) {
      const formattedMessage =
        announcement.message?.nodeType === 'document'
          ? documentToReactComponents(announcement.message)
          : announcement.message;

      const Content = (
        <Announcement
          message={formattedMessage}
          link={announcement.link}
          data-testid="app-announcement-content"
        />
      );

      announcement.is_urgent
        ? toast.error(Content, toastOptions)
        : toast.info(Content, toastOptions);

      sessionStorage.setItem(announcement.title, 'true');
    }
  }, [announcement]);
};
