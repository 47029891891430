import { Box, BoxProps, Flex } from '@amzn/ring-ui-react-components';
import { Portal, PortalProps } from '../Portal';

export interface DrawerProps extends BoxProps, Pick<PortalProps, 'container'> {
  isOpen: boolean;
}

export const Drawer = ({
  children,
  container,
  isOpen,
  ...props
}: DrawerProps) => {
  if (!isOpen) {
    return null;
  }

  return (
    <Portal container={container}>
      <Box
        bg="white"
        bottom={0}
        left={0}
        position="fixed"
        right={0}
        top={0}
        zIndex="drawer"
        {...props}
      >
        <Flex direction="column" height="100%" position="relative" width="100%">
          {children}
        </Flex>
      </Box>
    </Portal>
  );
};
