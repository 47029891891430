import {
  GetReportCategoriesByIdQuery,
  GetReportCategoriesByIdQueryVariables,
  ReportCategory,
} from '@amzn/ring-neighbors-api-orchestrator-gql-schema';
import GET_REPORT_CATEGORIES_BY_ID from '@amzn/ring-neighbors-api-orchestrator-gql-schema/operations/authenticated/reportCategory/getReportCategoriesById.graphql';
import { useDisclosure } from '@amzn/ring-ui-react-components';
import { useLazyQuery } from '@apollo/client';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { AppRoutes } from 'src/routes';
import { Box } from 'src/shared/components/Box';
import { ConfirmationModal } from 'src/shared/components/ConfirmationModal';
import { CenteredLoading } from 'src/shared/components/Loading';
import {
  ReportModal,
  ReportModalFlag,
  ReportModalProps,
} from 'src/shared/components/ReportModal';
import { TextareaModal } from 'src/shared/components/TextareaModal';
import { trackEventWithHeap } from 'src/utils/heap';
import {
  FLAG_POST_PRIVACY_CONCERN,
  FLAG_VIOLENCE_OR_THREATS,
  REPORT_FINAL_STEP_1,
  REPORT_FINAL_STEP_2,
  REPORT_FINAL_STEP_3,
} from './constants';
import { useNeighborsWebClickEventStreamDispatcher } from '../App/hooks';

type Flag = ReportCategory;
type FlagId = Flag['id'];

export interface ReportModalContainerProps
  extends Pick<
    ReportModalProps,
    'isOpen' | 'onClose' | 'title' | 'description'
  > {
  getConfirmationText: (flagId: string) => string;
  initialReportCategoryId: string;
  onSubmit: (flag: Flag, flagDetails?: string) => void;
}

export const ReportModalContainer = ({
  description,
  getConfirmationText,
  initialReportCategoryId,
  isOpen,
  onClose,
  onSubmit,
  title,
  ...props
}: ReportModalContainerProps) => {
  const [parentIds, setParentIds] = useState<FlagId[]>([]);
  const [currentFlags, setCurrentFlags] = useState<Flag[]>([]);
  const [flag, setFlag] = useState<Flag | null>(null);
  const [flagDetails, setFlagDetails] = useState('');

  const {
    isOpen: isConfirmModalOpen,
    onOpen: onOpenConfirmModal,
    onClose: onCloseConfirmModal,
  } = useDisclosure();
  const {
    isOpen: isTextareaModalOpen,
    onOpen: onOpenTextareaModal,
    onClose: onCloseTextareaModal,
  } = useDisclosure();

  const [getFlags, { data, loading, error }] = useLazyQuery<
    GetReportCategoriesByIdQuery,
    GetReportCategoriesByIdQueryVariables
  >(GET_REPORT_CATEGORIES_BY_ID, {
    fetchPolicy: 'network-only',
    onCompleted(data) {
      if (!data) {
        return;
      }

      const { reportCategoriesById } = data as unknown as {
        reportCategoriesById: Flag[];
      };

      if (
        reportCategoriesById[0].id === REPORT_FINAL_STEP_1 ||
        reportCategoriesById[0].id === REPORT_FINAL_STEP_2 ||
        reportCategoriesById[0].id === REPORT_FINAL_STEP_3 ||
        reportCategoriesById[0].id === FLAG_VIOLENCE_OR_THREATS
      ) {
        onOpenConfirmModal();
      } else {
        setCurrentFlags(reportCategoriesById);
      }
    },
  });

  const getReportCategoriesById = useCallback(
    (reasonId: FlagId) => {
      getFlags({
        variables: {
          report_category_id: reasonId,
        },
      });
    },
    [getFlags],
  );

  const goToPrevStep = () => {
    const pathIds = parentIds.slice(0, parentIds.length - 1);

    setParentIds(pathIds);
    getReportCategoriesById(pathIds[pathIds.length - 1]);
  };

  const goToNextStep = (reasonId: FlagId) => {
    const pathIds = [...parentIds, reasonId];

    setParentIds(pathIds);
    getReportCategoriesById(reasonId);
  };

  const setInitialState = () => {
    setParentIds([]);
    setCurrentFlags([]);
    setFlag(null);
  };

  useEffect(() => {
    const areFlagsEmpty = currentFlags.length === 0;

    if (isOpen) {
      if (areFlagsEmpty) {
        getReportCategoriesById(initialReportCategoryId);
        setParentIds([initialReportCategoryId]);
      }
      return;
    }

    if (!areFlagsEmpty) {
      setInitialState();
    }
  }, [getReportCategoriesById, initialReportCategoryId, isOpen, currentFlags]);

  const handleReport = useCallback<ReportModalContainerProps['onSubmit']>(
    (flag: Flag, details?: string) => {
      trackEventWithHeap(`${title} Confirm Click`);

      onClose?.();
      onSubmit(flag, details);
    },
    [onClose, onSubmit, title],
  );

  const handleFlagClick = (reasonId: FlagId) => {
    const parentId = parentIds[parentIds.length - 1];

    const selectedFlag = data?.reportCategoriesById.find(
      (item) => item!.id === reasonId,
    );

    if (selectedFlag) {
      setFlag(selectedFlag);
    }

    if (parentId === FLAG_POST_PRIVACY_CONCERN) {
      onOpenTextareaModal();
      return;
    }

    goToNextStep(reasonId);
  };

  const handleBackClick = () => {
    goToPrevStep();
  };

  const handleCloseConfirmationModal = () => {
    setFlagDetails('');
    onCloseConfirmModal();
    goToPrevStep();
  };

  const handleDetailChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const flagDetails = e.target.value.trim();

    setFlagDetails(flagDetails);
  };

  const handleTextareaClose = () => {
    onCloseTextareaModal();
    setFlagDetails('');
  };

  const handleSubmit = () => {
    if (!flag) {
      return;
    }

    handleReport(flag, flagDetails);
  };

  const confirmationText = flag ? getConfirmationText(flag.title) : '';
  const showBackButton = parentIds.length > 1;

  const isPostDetail = useRouteMatch(AppRoutes.EventDetailOrSharePage);

  const dispatchClick = useNeighborsWebClickEventStreamDispatcher(
    isPostDetail ? 'nhWeb_postDetail' : 'nhWeb_mainFeed',
  );

  return (
    <>
      <ReportModal
        description={description}
        isLoading={loading}
        isOpen={isOpen}
        onBackClick={showBackButton ? handleBackClick : undefined}
        onClose={onClose}
        title={title}
        {...props}
      >
        {currentFlags.length > 0 ? (
          <Box>
            {currentFlags.map(({ id, text, title }) => {
              const handleOnClick = () => handleFlagClick(id);
              return (
                <ReportModalFlag
                  disabled={loading}
                  key={id}
                  onClick={handleOnClick}
                  text={text}
                  title={title}
                />
              );
            })}
          </Box>
        ) : (
          !error && (
            <Box py={8}>
              <CenteredLoading data-testid="loading-spinner" />
            </Box>
          )
        )}
      </ReportModal>

      <TextareaModal
        description={flag?.title}
        isOpen={isTextareaModalOpen}
        onChange={handleDetailChange}
        onClose={handleTextareaClose}
        onSubmit={handleSubmit}
        title={title}
      />

      <ConfirmationModal
        confirmationText={confirmationText}
        isOpen={isConfirmModalOpen}
        onClose={handleCloseConfirmationModal}
        onSubmit={() => {
          if (dispatchClick && flag?.id) {
            dispatchClick('nhWeb_reportPostWeb', [flag.id]);
          }
          handleSubmit();
        }}
      />
    </>
  );
};
