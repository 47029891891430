import { ReactNode } from 'react';
import doorbell from 'src/assets/images/doorbell.png';
import { Box } from '../Box';
import { Button } from '../Button';
import { Flex, FlexProps } from '../Flex';

export interface ErrorProps extends FlexProps {
  buttonText: string;
  details: string;
  error: ReactNode;
  onClick?: () => void;
}

export const ErrorLogo = () => (
  <Flex
    mb={2}
    fontSize={['100px', null, null, null, '220px']}
    fontWeight={{ _: 700, m: 500 }}
    lineHeight={['110px', null, null, null, '240px']}
    letterSpacing={['0px', null, null, null, '-10px']}
    justifyContent="center"
    color="primary.200"
  >
    <Box>O</Box>
    <Box
      backgroundImage={`url(${doorbell})`}
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      backgroundSize="contain"
      display="flex"
      width={['80px', null, null, null, '160px']}
    />
    <Box
      css={{
        textIndent: '-5px',
      }}
    >
      ps!
    </Box>
  </Flex>
);

export const Error = ({
  buttonText,
  details,
  error,
  onClick,
  ...props
}: ErrorProps) => (
  <Flex
    alignItems="center"
    backgroundColor="tertiary.300"
    flex={1}
    flexDirection="column"
    justifyContent="center"
    p={4}
    textAlign="center"
    {...props}
  >
    <ErrorLogo />
    <Box textStyle="heading-large-medium" fontSize={4}>
      {error}
    </Box>
    <Box color="backup" textStyle="body-large-light" mb={2}>
      {details}
    </Box>
    {onClick && (
      <Button variant="outline-primary" mt={3} size="lg" onClick={onClick}>
        {buttonText}
      </Button>
    )}
  </Flex>
);
