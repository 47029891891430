import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useSearchParam = (paramName: string) => {
  const { search } = useLocation();

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const param = searchParams.get(paramName);

  return param;
};
