import { Box, Flex, ArrowLeft } from '@amzn/ring-ui-react-components';

export interface PetProfileMenuItemProps {
  onClick: () => void;
  title: string;
  description: string;
  icon: React.ReactElement;
}

export const PetProfileMenuItem = ({
  title,
  description,
  onClick,
  icon,
  ...props
}: PetProfileMenuItemProps) => (
  <Flex
    {...props}
    justifyContent="space-between"
    alignItems="center"
    px={6}
    py={4}
    cursor="pointer"
    bgColor="white"
    onClick={onClick}
  >
    <Box display="flex" justifyContent="space-between">
      <Box mr={4}>{icon}</Box>
      <Box display="flex" flexDirection="column">
        <Box
          textStyle="body-large-medium"
          as="span"
          data-testid="pet-profile-item-title"
        >
          {title}
        </Box>
        <Box
          color="neutral.300"
          textStyle="body-normal-regular"
          as="span"
          wordBreak="break-word"
          data-testid="pet-profile-item-description"
        >
          {description}
        </Box>
      </Box>
    </Box>
    <ArrowLeft ml={4} transform="rotate(180deg)" boxSize="24px" />
  </Flex>
);
