import type {
  KeyboardEventHandler,
  MouseEvent,
  MouseEventHandler,
  SyntheticEvent,
} from 'react';

export const createClickKeyDownHandler = <T extends HTMLElement | SVGElement>(
  onClick?: MouseEventHandler<T>,
): KeyboardEventHandler<T> | undefined => {
  if (!onClick) {
    return;
  }

  return (e) => {
    if (e.key === 'Enter') {
      return;
    }

    e.stopPropagation();

    onClick(e as unknown as MouseEvent<T>);
  };
};

export const preventDefault = (e: SyntheticEvent) => {
  e.preventDefault();
};
