import { callAllHandlers } from '@chakra-ui/utils';
import { ChangeEvent, ChangeEventHandler, useState } from 'react';
import { Box } from '../Box';
import { Button } from '../Button';
import { FormControl, FormHelperText, FormLabel, Textarea } from '../Form';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from '../Modal';

export interface TextareaModalProps extends ModalProps {
  onChange: ChangeEventHandler<HTMLTextAreaElement>;
  onSubmit: () => void;
  title: string;
  cancelText?: string;
  description?: string;
  label?: string;
  maxLength?: number;
  placeholder?: string;
  submitText?: string;
}

export const TextareaModal = ({
  cancelText = 'Cancel',
  placeholder = 'Please provide more detail...',
  submitText = 'Submit',
  maxLength = 1000,
  description,
  label,
  onClose,
  onChange,
  onSubmit,
  title,
  ...props
}: TextareaModalProps) => {
  const [length, setLength] = useState(0);

  const handleChange = callAllHandlers(
    onChange,
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      setLength(e.target.value.length);
    },
  );

  const handleClose = callAllHandlers(() => setLength(0), onClose);

  const handleSubmit = callAllHandlers(onSubmit, onClose);

  return (
    <Modal {...props} onClose={handleClose} isCentered size="lg">
      <ModalOverlay>
        <ModalContent>
          <ModalHeader border="none" flexDirection="column" textAlign="center">
            <Box fontSize={3}>{title}</Box>
            {description && (
              <Box color="backup" fontSize={2} fontWeight={300}>
                {description}
              </Box>
            )}
          </ModalHeader>

          <ModalBody py={0}>
            <FormControl>
              {label && <FormLabel htmlFor="textarea">{label}</FormLabel>}
              <Textarea
                id="textarea"
                maxLength={maxLength}
                onChange={handleChange}
                placeholder={placeholder}
              />
              <FormHelperText>{`${length}/${maxLength}`}</FormHelperText>
            </FormControl>
          </ModalBody>

          <ModalFooter borderTopWidth="0">
            <Button variant="inverted-primary" mr={3} onClick={handleClose}>
              {cancelText}
            </Button>

            <Button variant="primary" onClick={handleSubmit}>
              {submitText}
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};
