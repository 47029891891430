import { mapResponsive } from '@chakra-ui/utils';
import { Children, forwardRef } from 'react';
import { ResponsiveValue } from 'src/shared/theme';
import { isLocalBuild } from 'src/utils';
import { Box, BoxBasedComponentProps } from '../Box';

export type AspectRatioProps = BoxBasedComponentProps<
  'div',
  {
    ratio?: ResponsiveValue<number>;
  }
>;

/**
 * AspectRatio component used to cropping media (videos, images and maps)
 * Ratio prop value could be: `21/9`, `16/9`, `9/16`, `4/3`, `1.85/1`
 */
export const AspectRatio = forwardRef<HTMLDivElement, AspectRatioProps>(
  ({ ratio = 16 / 9, children, ...props }, ref) => {
    const child = Children.only(children);

    return (
      <Box
        {...props}
        ref={ref}
        position="relative"
        _before={{
          content: '""',
          display: 'block',
          height: 0,
          paddingBottom: mapResponsive(ratio, (r) => `${(1 / r) * 100}%`),
        }}
        css={{
          '& > *': {
            alignItems: 'center',
            bottom: 0,
            display: 'flex',
            height: '100%',
            justifyContent: 'center',
            left: 0,
            margin: 'auto',
            overflow: 'hidden',
            position: 'absolute',
            right: 0,
            top: 0,
            width: '100%',
          },
        }}
      >
        {child}
      </Box>
    );
  },
);

if (isLocalBuild) {
  AspectRatio.displayName = 'AspectRatio';
}
