import { Breakpoint, BreakpointValue, breakpoints } from './breakpoints';

const mq = (w: BreakpointValue) => `@media screen and (min-width: ${w})`;

export const mediaQueries = Object.entries(breakpoints).reduce(
  (acc, [k, v]) => {
    acc[k as Breakpoint] = mq(v);
    return acc;
  },
  {} as Record<Breakpoint, string>,
);
