import { mergeWith } from 'ramda';
import { createContext, HTMLProps, useContext } from 'react';

export interface FormControlContextValue
  extends Pick<HTMLProps<HTMLElement>, 'required' | 'readOnly' | 'disabled'> {
  invalid?: boolean;
}

const FormControlContext =
  createContext<FormControlContextValue | undefined>(undefined);

FormControlContext.displayName = 'FormControlContext';

const FormControlContextProvider = FormControlContext.Provider;

const useFormControl = <T extends FormControlContextValue>(props: T) => {
  const context = useContext(FormControlContext);

  const { disabled, invalid, readOnly, required } = props;

  const propsContext = {
    disabled,
    invalid,
    readOnly,
    required,
  } as FormControlContextValue;

  if (!context) {
    return propsContext;
  }

  return mergeWith((a, b) => b ?? a, context, propsContext);
};

export { FormControlContextProvider, useFormControl };
