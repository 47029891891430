import { createContext } from '@chakra-ui/react-utils';
import { ReactNode, useMemo } from 'react';
import { FEED_EVENTS_CONTAINER_ID } from './constants';
import { getEventsWithValidLocations, getFeedCardId } from './utils';
import type { EventsEdge } from '@amzn/ring-neighbors-api-orchestrator-gql-schema';

export interface FeedPageContextValue {
  events: EventsEdge[];
  eventsWithValidLocations: EventsEdge[];
  getEventRef: (id: string) => Element | null;
}

const [Provider, useFeedPageContext] = createContext<FeedPageContextValue>({
  name: 'FeedPageContext',
});

interface FeedPageContextProviderProps {
  events: EventsEdge[];
  children: ReactNode;
}

const getEventRef = (id: string) => {
  const cardId = getFeedCardId(id);

  return document.querySelector(`#${FEED_EVENTS_CONTAINER_ID} #${cardId}`);
};

export const FeedPageContextProvider = ({
  events,
  children,
}: FeedPageContextProviderProps) => {
  const value = useMemo(() => {
    const eventsWithValidLocations = getEventsWithValidLocations(events);

    return {
      events,
      eventsWithValidLocations,
      getEventRef,
    };
  }, [events]);

  return <Provider value={value}>{children}</Provider>;
};

export { useFeedPageContext };
