import Bowser from 'bowser';
import { mergeDeepRight } from 'ramda';
import { SyntheticEvent, useMemo } from 'react';
import {
  VideoAttributes,
  VideoPlayer,
  VideoPlayerProps,
} from 'src/shared/components/VideoPlayer';
import { withAspectRatio, withMediaFallback } from 'src/shared/hocs';
import { preventDefault } from 'src/shared/utils';

interface EventMediaVideoProps
  extends Omit<VideoPlayerProps, 'config' | 'url'>,
    Pick<VideoAttributes, 'onLoadedMetadata'> {
  src?: string;
}

const EventMediaVideo = ({
  onLoadedMetadata,
  src,
  ...props
}: EventMediaVideoProps) => {
  const config = useMemo(
    () =>
      mergeDeepRight(staticConfig, {
        attributes: { onLoadedMetadata },
      }),
    [onLoadedMetadata],
  );

  return <VideoPlayer {...props} url={src} config={config} />;
};

const EventMediaVideoWithFallback = withMediaFallback(EventMediaVideo);

const EventMediaVideoWithAspectRatio = withAspectRatio(
  EventMediaVideoWithFallback,
  ({
    currentTarget: { videoWidth, videoHeight },
  }: SyntheticEvent<HTMLVideoElement>) => ({
    width: videoWidth,
    height: videoHeight,
  }),
  'onLoadedMetadata',
);

export { EventMediaVideoWithAspectRatio as EventMediaVideo };

// Disabling the following lines to solve this issue: https://jira.atl.ring.com/browse/NHWEB-1733

const browser = Bowser.getParser(window.navigator.userAgent);
const isSafari = browser.getBrowserName() === 'Safari';
const preload = 'metadata';

const staticConfig = {
  attributes: {
    controlsList: 'nodownload',
    disablePictureInPicture: true,
    onContextMenu: preventDefault,
    preload,
    poster: isSafari ? '/default-post-media.png' : '',
  },
} as const;
