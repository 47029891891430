import { useIds } from '@amzn/ring-ui-react-components';
import { mergeRefs } from '@chakra-ui/react-utils';
import { callAllHandlers } from '@chakra-ui/utils';
import {
  KeyboardEvent,
  MouseEvent,
  RefAttributes,
  useCallback,
  useRef,
  useState,
} from 'react';
import { useAriaHidden, useLockBodyScroll } from 'src/shared/hooks';
import { manager, useModalManager } from './ModalManager';
import { BoxProps } from '../Box';

export interface UseModalProps {
  /**
   * If `true`, the modal will be opened.
   */
  isOpen: boolean;
  /**
   * The `id` of the modal
   */
  id?: string;
  /**
   * Callback invoked to close the modal.
   */
  onClose?(...args: any[]): void;
  /**
   * If `true`, scrolling will be disabled on the `body` when the modal opens.
   *  @default true
   */
  blockScrollOnMount?: boolean;
  /**
   * If `true`, the modal will close when the overlay is clicked
   * @default true
   */
  closeOnOverlayClick?: boolean;
  /**
   * If `true`, the modal will close when the `Esc` key is pressed
   * @default true
   */
  closeOnEsc?: boolean;
  /**
   * Callback fired when the overlay is clicked.
   */
  onOverlayClick?(): void;
  /**
   * Callback fired when the escape key is pressed and focus is within modal
   */
  onEsc?(): void;
  /**
   * A11y: If `true`, the siblings of the `modal` will have `aria-hidden`
   * set to `true` so that screen readers can only see the `modal`.
   *
   * This is commonly known as making the other elements **inert**
   *
   *  @default true
   */
  useInert?: boolean;
}

/**
 * Modal hook that manages all the logic for the modal dialog widget
 * and returns prop getters, state and actions.
 */
export const useModal = ({
  blockScrollOnMount = true,
  closeOnEsc = true,
  closeOnOverlayClick = true,
  id,
  isOpen,
  onClose,
  onEsc,
  onOverlayClick: onOverlayClickProp,
  useInert = true,
}: UseModalProps) => {
  const [headerMounted, setHeaderMounted] = useState(false);
  const [bodyMounted, setBodyMounted] = useState(false);

  const dialogRef = useRef<HTMLDivElement | null>(null);
  const overlayRef = useRef<HTMLDivElement | null>(null);
  const mouseDownTarget = useRef<EventTarget | null>(null);

  const [contentId, headerId, bodyId] = useIds(
    id,
    `modal`,
    `modal-header`,
    `modal-body`,
  );

  const onMouseDown = useCallback((event: MouseEvent) => {
    mouseDownTarget.current = event.target;
  }, []);

  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        event.stopPropagation();

        if (closeOnEsc) {
          onClose?.();
        }

        onEsc?.();
      }
    },
    [closeOnEsc, onClose, onEsc],
  );

  const onOverlayClick = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();

      /**
       * Make sure the event starts and ends on the same DOM element.
       *
       * This is used to prevent the modal from closing when you
       * start dragging from the content, and release drag outside the content.
       *
       * We prevent this because it's technically not a considered "click outside"
       */
      if (mouseDownTarget.current !== event.target) {
        return;
      }

      /**
       * When you click on the overlay, we want to remove only the topmost modal
       */
      if (manager.isTopModal(dialogRef)) {
        if (closeOnOverlayClick) {
          onClose?.();
        }

        onOverlayClickProp?.();
      }
    },
    [onClose, closeOnOverlayClick, onOverlayClickProp],
  );

  useLockBodyScroll(dialogRef, isOpen && blockScrollOnMount);

  useAriaHidden(dialogRef, isOpen && useInert);

  useModalManager(dialogRef, isOpen);

  return {
    isOpen,
    onClose,
    headerId,
    bodyId,
    setBodyMounted,
    setHeaderMounted,
    dialogRef,
    getContentProps: (props: BoxProps & RefAttributes<HTMLElement> = {}) => ({
      ...props,
      ref: mergeRefs(props.ref, dialogRef),
      id: contentId,
      role: props.role || 'dialog',
      tabIndex: -1,
      'aria-modal': true,
      'aria-labelledby': headerMounted ? headerId : undefined,
      'aria-describedby': bodyMounted ? bodyId : undefined,
      onClick: callAllHandlers(props.onClick, (event: MouseEvent) =>
        event.stopPropagation(),
      ),
    }),
    getOverlayProps: (props: Record<string, any> = {}) => ({
      ...props,
      ref: mergeRefs(props.ref, overlayRef),
      onClick: callAllHandlers(props.onClick, onOverlayClick),
      onKeyDown: callAllHandlers(props.onKeyDown, onKeyDown),
      onMouseDown: callAllHandlers(props.onMouseDown, onMouseDown),
    }),
  };
};

export type UseModalReturn = ReturnType<typeof useModal>;
