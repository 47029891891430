import { Box } from 'src/shared/components/Box';
import type { NpssAnnouncement } from '@amzn/ring-neighbors-api-orchestrator-gql-schema';

interface AnnouncementProps {
  message: NpssAnnouncement['message'];
  link?: NpssAnnouncement['link'];
}

export const Announcement = ({ message, link }: AnnouncementProps) =>
  link ? (
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
      data-testid="app-announcement-link"
    >
      <Box data-testid="app-announcement-message">{message}</Box>
    </a>
  ) : (
    <Box data-testid="app-announcement-message">{message}</Box>
  );
