import GET_USER_ID_HMAC_TOKEN from '@amzn/ring-neighbors-api-orchestrator-gql-schema/operations/authenticated/authentication/getUserIdHmacToken.graphql';
import GET_CONFIG from '@amzn/ring-neighbors-api-orchestrator-gql-schema/operations/authenticated/config/getConfig.graphql';
import GET_USER_ID from '@amzn/ring-neighbors-api-orchestrator-gql-schema/operations/authenticated/me/getUserId.graphql';
import GET_MY_NPSS_ACCOUNT from '@amzn/ring-neighbors-api-orchestrator-gql-schema/operations/authenticated/npss/getMyNPSSAccount.graphql';
import { QueryResult, useQuery } from '@apollo/client';
import { createContext } from '@chakra-ui/react-utils';
import turfCenter from '@turf/center';
import { points } from '@turf/helpers';
import { ReactNode, useCallback, useMemo } from 'react';
import { useLocalStorage } from 'react-use';
import {
  AuthStatus,
  setAuthStatus,
  setIsNpssUser,
  setNpssUser,
  setUserID,
  setUserIdHmacToken,
  useAuthStatus,
  useIsNpssUser,
} from 'src/auth';
import { useQueryWithBoundary, useSearchParam } from 'src/hooks';
import { EventCategoriesProvider } from 'src/shared/context';
import { AlertAreaProvider } from './AlertAreaContext';
import { FeatureFlagsProvider } from './FeatureFlags';
import type {
  AlertArea,
  Geometry,
  GetConfigQuery,
  GetConfigQueryVariables,
  Pin,
} from '@amzn/ring-neighbors-api-orchestrator-gql-schema';

type QueryResultRefetch = Pick<
  QueryResult<GetConfigQuery, GetConfigQueryVariables>,
  'refetch'
>;

export interface AuthContextValue extends QueryResultRefetch {
  authStatus: AuthStatus;
  isPending: boolean;
  isAuthenticated: boolean;
  isUnauthenticated: boolean;
  userId?: number;
  isNpssUser: boolean;
}

const [AuthContextProvider, useAuth] = createContext<AuthContextValue>({
  name: 'AuthContext',
});

// The purpose of this function is to create an AlertArea object using NPSS feedBoundaryGeoJson object.
// NHWeb app heavily relies in alert areas, so we need to create a fake.
// AlertArea is specially used during the Post creation to center the map in the alert area, in this case the alert area
// will be NPSS user jurisdiction bounds
const npssFeedBoundaryToFakeAlertArea = (
  feedBoundaryGeoJson: Geometry,
): Omit<AlertArea, 'feed'> => {
  const features = points(
    feedBoundaryGeoJson.bounds[0][0].map(
      ({ latitude, longitude }) => [longitude, latitude], // mapbox requires [lng, lat] format
    ),
  );
  const centerFeature = turfCenter(features);
  const fakeAlertArea: Omit<AlertArea, 'feed'> = {
    id: 'fakeId',
    // @ts-ignore
    details: { ring_location_ids: [] },
    location: { address: {} },
    // @ts-ignore
    preferences: { radius_in_meters: 8000 },
  };
  fakeAlertArea.location!.geom = feedBoundaryGeoJson;
  fakeAlertArea.location!.pin = {
    __typename: 'Pin',
    latitude: centerFeature.geometry!.coordinates[1],
    longitude: centerFeature.geometry!.coordinates[0],
  } as Pin;

  return fakeAlertArea;
};

interface AuthProviderProps {
  children: ReactNode;
}

interface NeighborsUserData {
  me: {
    id: string;
  };
}

const npssViewLocalStorageKey = 'npss-view';

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const authStatus = useAuthStatus();
  const isNpssUser = useIsNpssUser();

  const [isNpssView, setNpssView] = useLocalStorage(
    npssViewLocalStorageKey,
    false,
  );

  // Initial query to initialize categories and feed settings
  let configQuery = GET_CONFIG;

  if (useSearchParam('redirected_from') === 'npss' || isNpssView === true) {
    setIsNpssUser(true);
    configQuery = GET_MY_NPSS_ACCOUNT;
  }

  // we use the event categories query to detect wether the user is authenticated or not
  // TODO: fast refresh or manual hmr seems to break apollo for some reason, this DOES NOT happen in production
  // related issues
  // https://github.com/apollographql/apollo-client/issues/5870
  // https://github.com/apollographql/apollo-client/issues/6661
  const { data, refetch: originalRefetch } = useQuery(configQuery, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true, // https://github.com/apollographql/react-apollo/issues/3709
    onCompleted() {
      setAuthStatus(AuthStatus.authenticated);
      if (isNpssUser) {
        setNpssView(true);
        setNpssUser(data?.myNPSSAccount);
      }
    },
    onError() {
      setAuthStatus(AuthStatus.unauthenticated);
      setNpssView(false);
    },
  });

  const refetch = useCallback(
    (variables?: Partial<GetConfigQueryVariables>) => {
      setAuthStatus(AuthStatus.pending);
      return originalRefetch(variables);
    },
    [originalRefetch],
  );

  const value = useMemo(() => {
    const isAuthenticated = authStatus === AuthStatus.authenticated;
    const isPending = authStatus === AuthStatus.pending;
    const isUnauthenticated = authStatus === AuthStatus.unauthenticated;

    return {
      authStatus,
      isAuthenticated,
      isPending,
      isUnauthenticated,
      refetch,
    };
  }, [authStatus, refetch]);

  const { isAuthenticated } = value;

  const { eventCategories, myNPSSAccount } = data || defaultQueryData;

  let { alertAreas, isInternalPreviewEnabled } = isAuthenticated
    ? data
    : defaultQueryData;

  if (isNpssView) {
    alertAreas = myNPSSAccount
      ? [npssFeedBoundaryToFakeAlertArea(myNPSSAccount.feed_boundary_geojson)]
      : [];
  } else {
    isInternalPreviewEnabled = false;
  }

  const featureFlags = useMemo(
    () => ({
      ENABLE_INTERNAL_PREVIEW: isInternalPreviewEnabled,
    }),
    [isInternalPreviewEnabled],
  );

  const { data: meData, loading } = useQueryWithBoundary(GET_USER_ID, {
    skip: !isAuthenticated || isNpssView,
  });

  const getUserId = (meData: NeighborsUserData) => {
    if (!isNpssView) {
      return getMyUserId(meData);
    }

    return getDataMyNpssAccountId();
  };

  const getMyUserId = (meData: NeighborsUserData) =>
    (meData || { me: { id: undefined } }).me.id;
  const getDataMyNpssAccountId = () => data?.myNPSSAccount?.id;
  const userId = getUserId(meData);

  setUserID(userId);

  const { data: dataHmac } = useQueryWithBoundary(GET_USER_ID_HMAC_TOKEN, {
    skip: !isAuthenticated,
  });

  if (dataHmac?.userIdHmacToken) {
    setUserIdHmacToken(dataHmac.userIdHmacToken);
  }

  return (
    <AuthContextProvider
      value={{
        ...value,
        userId,
        isNpssUser,
        isPending: loading ? true : value.isPending,
      }}
    >
      <EventCategoriesProvider value={{ categories: eventCategories, loading }}>
        <AlertAreaProvider
          alertAreas={alertAreas}
          npssUser={data?.myNPSSAccount}
        >
          <FeatureFlagsProvider featureFlags={featureFlags}>
            {children}
          </FeatureFlagsProvider>
        </AlertAreaProvider>
      </EventCategoriesProvider>
    </AuthContextProvider>
  );
};

export { useAuth };

const defaultQueryData = {
  eventCategories: [],
  alertAreas: [],
  isInternalPreviewEnabled: false,
};
