// WARNING: can't be an enum because of TS reverse mapping of enums
// https://www.typescriptlang.org/docs/handbook/enums.html#reverse-mappings
export const fontWeights = {
  light: 300,
  regular: 500,
  medium: 600,
  bold: 700,
} as const;

export const fontSizes = [
  12, // 0
  14, // 1
  16, // 2
  18, // 3
  20, // 4
  24, // 5
  28, // 6
  32, // 7
] as const;

export const fonts = ['Equip'] as const;
