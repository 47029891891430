/* eslint-disable */
import { useEffect } from 'react';

function obfuscateHeap(props: any) {
  const replacement = '$1' + 'REDACTED';
  const pathRegex =
    /(((account\/)(plan\/|app\/)(devices\/|payment\/|location\/|confirmation\/|plans\/(?:subscribe\/|upgrade\/|cancel\/|remove\/|)))|(cases\/|alerts\/|share\/|user\/viewprofilepage\/user-id\/|orders\/|checkouts\/))([A-Za-z0-9_@.-]+?)(?=\/|\?|$)/g;
  const queryParamsRegex =
    /([&?]deviceId=|[&?]locationId=|[&?]PayerID=|[&?]user_email=|[&?]msclkid=|[&?]mkt_tok=|[&?]fbclid=|[&?]token=|[&?]l=|[&?]discount=|[&?]location=|[&?]devices=|[&?]checkout\[email\]=|[&?]invitation_token=|[&?]code=)([A-Za-z0-9_@.-]+?)(?=\/|$|\&|])/g;

  function applyRegex(str: string | undefined, regex: RegExp) {
    return str ? str.replace(regex, replacement) : undefined;
  }

  if (props.event_properties) {
    if (props.event_properties.href) {
      props.event_properties.href = applyRegex(
        applyRegex(props.event_properties.href, pathRegex),
        queryParamsRegex,
      );
    }
    if (
      props.event_properties.hierarchy &&
      props.event_properties.hierarchy.indexOf('[href') > -1
    ) {
      props.event_properties.hierarchy = applyRegex(
        applyRegex(props.event_properties.hierarchy, pathRegex),
        queryParamsRegex,
      );
    }
  }
  return props;
}

export const useHeapAnalytics = (
  heapAnalyticsId: string,
  shouldTrack: boolean | null,
) => {
  useEffect(() => {
    (window.heap = window.heap || []),
      // @ts-ignore
      (heap.load = function (e: string, t: any) {
        (window.heap.appid = e), (window.heap.config = t = t || {});
        var r = document.createElement('script');
        (r.type = 'text/javascript'),
          (r.async = !0),
          (r.src = 'https://cdn.heapanalytics.com/js/heap-' + e + '.js');
        var a = document.getElementsByTagName('script')[0];
        // @ts-ignore
        a?.parentNode?.insertBefore(r, a);
        for (
          var n = function (e: string) {
              return function () {
                // @ts-ignore
                if (window.heap && window.heap.push) {
                  // @ts-ignore
                  window.heap.push(
                    [e].concat(Array.prototype.slice.call(arguments, 0)),
                  );
                }
              };
            },
            p = [
              'addEventProperties',
              'addUserProperties',
              'clearEventProperties',
              'identify',
              'resetIdentity',
              'removeEventProperty',
              'setEventProperties',
              'track',
              'unsetEventProperty',
            ],
            o = 0;
          o < p.length;
          o++
        )
          // @ts-ignore
          window.heap[p[o]] = n(p[o]);
      });

    if (shouldTrack) {
      window.heap.load(heapAnalyticsId, {
        rewrite: obfuscateHeap,
        disableTextCapture: true,
      });
    }
  }, [heapAnalyticsId]);
};

export default useHeapAnalytics;
