import { MediaItem } from '@amzn/ring-neighbors-api-orchestrator-gql-schema';
import { AspectRatioProps } from 'src/shared/components/AspectRatio';
import { Box, BoxBasedComponentProps } from 'src/shared/components/Box';
import { EventMediaImage } from 'src/shared/components/EventMediaImage';
import { EventMediaVideo } from 'src/shared/components/EventMediaVideo';
import { Flex } from 'src/shared/components/Flex';
import { Icon } from 'src/shared/components/Icon';

export interface EventGalleryMediaThumbnailProps
  extends Pick<AspectRatioProps, 'borderRadius'>,
    Pick<ThumbnailDashProps, 'isActive'>,
    Pick<MediaItem, 'type'> {
  showDash: boolean;
  src: string;
}

export const EventGalleryMediaThumbnail = ({
  borderRadius,
  isActive,
  showDash,
  src,
  type,
}: EventGalleryMediaThumbnailProps) => {
  if (showDash) {
    return <ThumbnailDash isActive={isActive} />;
  }

  return (
    <Box cursor="pointer" pb={2} overflow="hidden" position="relative">
      {type === 'image' ? (
        <EventMediaImage
          borderRadius={borderRadius}
          objectFit="cover"
          src={src}
        />
      ) : (
        <EventMediaVideo
          borderRadius={borderRadius}
          pButton={ThumbnailPlayButton}
          reproducible={false}
          src={src}
        />
      )}
      {isActive && (
        <ThumbnailDash
          bottom={0}
          isActive={isActive}
          left={0}
          position="absolute"
        />
      )}
    </Box>
  );
};

const ThumbnailPlayButton = () => (
  <Flex
    height="100%"
    justifyContent="center"
    position="absolute"
    width={1}
    zIndex={1}
  >
    <Flex
      alignItems="center"
      alignSelf="center"
      bg="white"
      borderRadius="circle"
      justifyContent="center"
      size={24}
    >
      <Icon color="primary.500" name="play" ml={1} size={12} />
    </Flex>
  </Flex>
);

interface ThumbnailDashProps extends BoxBasedComponentProps<'div'> {
  isActive: boolean;
}

const ThumbnailDash = ({ isActive, ...props }: ThumbnailDashProps) => (
  <Box
    bg={isActive ? 'primary.500' : 'tertiary.500'}
    borderRadius="rounder"
    cursor="pointer"
    height={4}
    width={1}
    _hover={{
      bg: 'primary.500',
    }}
    {...props}
  />
);
