import { Box, MedicalInfoIcon } from '@amzn/ring-ui-react-components';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from 'src/shared/components/Modal';

type MedicalInfoModalProps = Omit<ModalProps, 'isCentered' | 'size'> & {
  petName: string;
  description: string;
};

export const MedicalInfoModal = ({
  onClose,
  petName,
  description,
  ...props
}: MedicalInfoModalProps) => (
  <Modal
    {...props}
    isCentered
    size="xlg"
    onClose={onClose}
    blockScrollOnMount={false}
  >
    <ModalOverlay>
      <ModalContent p={0} data-testid="medical-info-modal">
        <ModalHeader
          justifyContent="center"
          px={9}
          py={4}
          textStyle="body-normal-medium"
          width={1}
          data-testid="modal-header"
        >
          Medical Info
        </ModalHeader>
        <ModalCloseButton
          top={4}
          right={6}
          color="neutral.500"
          data-testid="modal-close-button"
        />
        <ModalBody flexDirection="column" px={8} py={7}>
          <MedicalInfoIcon color="primary.500" boxSize={12} mb={3} />
          <Box
            textStyle="heading-normal-medium"
            mb={2}
            data-testid="modal-title"
          >
            {`${petName}'s Medical Info`}
          </Box>
          <Box
            textStyle={{ base: 'body-large-light', m: 'body-normal-light' }}
            wordBreak="break-word"
            data-testid="modal-description"
          >
            {description}
          </Box>
        </ModalBody>
      </ModalContent>
    </ModalOverlay>
  </Modal>
);
