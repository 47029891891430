import { VehicleDescription } from '@amzn/ring-neighbors-api-orchestrator-gql-schema';
import { Box } from '@amzn/ring-ui-react-components';
import { useTranslation } from 'react-i18next';
import { EventProperty } from './EventProperty';

export interface EventCardPersonInfoProps {
  vehicleDescription: VehicleDescription;
  index: number;
}

export const EventCardVehicleInfo = ({
  index,
  vehicleDescription: { description, license_plate_info, other },
}: EventCardPersonInfoProps) => {
  const { t } = useTranslation('components.event');
  return (
    <Box mt={4} data-testid="event-card-vehicle-info">
      {(description || license_plate_info || other) && (
        <Box
          textStyle="body-normal-medium"
          data-testid="vehicle-descripition-header"
        >
          {t('vehicle-info.title', { spacing: index ? ` ${index} ` : ' ' })}
        </Box>
      )}
      <EventProperty
        label={t('vehicle-info.type')}
        value={description}
        show={!!description}
      />
      <EventProperty
        label={t('vehicle-info.plate')}
        value={license_plate_info}
        show={!!license_plate_info}
      />
      <EventProperty
        label={t('vehicle-info.other')}
        value={other}
        show={!!other}
      />
    </Box>
  );
};
