import { DisabledComments as DisabledCommentsIcon } from '@amzn/ring-ui-react-components';
import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath, useHistory } from 'react-router-dom';
import { useIsNpssUser } from 'src/auth';
import { AppRoutes } from 'src/routes';
import { useClickAndKeyDownHandlers } from 'src/shared/hooks';
import { Box, BoxBasedComponentProps } from '../Box';
import { Flex } from '../Flex';
import { Icon } from '../Icon';

const formatter = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 1,
  notation: 'compact',
  compactDisplay: 'short',
});

const iconSize = 20;

type EventCardFooterActionProps = BoxBasedComponentProps<
  'div',
  {
    children?: number;
    disabled?: boolean;
    icon: ReactElement;
    isActive?: boolean;
  }
>;

const EventCardFooterAction = ({
  isActive,
  icon,
  children,
  disabled,
  onClick,
  ...props
}: EventCardFooterActionProps) => {
  const formatted = children != null ? formatter.format(children) : undefined;

  const handlers = useClickAndKeyDownHandlers(onClick, disabled);

  return (
    <Flex
      tabIndex={0}
      {...props}
      {...handlers}
      aria-disabled={disabled}
      alignItems="center"
      color={isActive ? 'primary.500' : 'neutral.300'}
      cursor="pointer"
      textStyle="body-normal-light"
      _disabled={{
        cursor: 'initial',
        opacity: 0.5,
        pointerEvents: 'none',
      }}
    >
      {icon}
      {formatted && <Box as="span">{formatted}</Box>}
    </Flex>
  );
};

type EventCardFooterUpvoteActionProps = Omit<
  EventCardFooterActionProps,
  'icon'
>;

export const EventCardFooterUpvoteAction = ({
  children,
  ...props
}: EventCardFooterUpvoteActionProps) => {
  const isNpssUser = useIsNpssUser();
  const { disabled, ...otherProps } = props;
  return (
    <EventCardFooterAction
      {...otherProps}
      disabled={isNpssUser ? true : disabled}
      data-testid="event-card-footer-vote-action"
      aria-label={`${children} upvotes`}
      mr={4}
      icon={<Icon name="thanks_neighborhoods" mb={1} mr={2} size={iconSize} />}
      _hover={{ color: 'primary.500' }}
    >
      {children}
    </EventCardFooterAction>
  );
};

export const EventCardFooterCommentAction = ({
  children,
  ...props
}: EventCardFooterUpvoteActionProps) => (
  <EventCardFooterAction
    {...props}
    data-testid="event-card-footer-comment-action"
    aria-label={`${children ?? 0} comments`}
    icon={<Icon name="chat_bubble" mb={1} mr={2} size={iconSize} />}
    _hover={{
      color: 'primary.500',
    }}
  >
    {children}
  </EventCardFooterAction>
);

export const EventCardFooterDisabledCommentAction = ({
  children,
  ...props
}: EventCardFooterUpvoteActionProps) => (
  <EventCardFooterAction
    {...props}
    onClick={undefined}
    aria-label="Comments are disabled"
    icon={<DisabledCommentsIcon boxSize={6} mb={1} mr={2} />}
  >
    {children}
  </EventCardFooterAction>
);

type EventCardFooterShareActionProps = Omit<
  EventCardFooterActionProps,
  'icon' | 'ml' | 'children'
>;

export const EventCardFooterShareAction = ({
  onClick,
  ...props
}: EventCardFooterShareActionProps) => {
  const handlers = useClickAndKeyDownHandlers(onClick);
  const { t } = useTranslation('common', { useSuspense: false });

  const {
    location: { pathname },
  } = useHistory();

  const isDetailOrSharePage = useMemo(
    () =>
      matchPath(pathname, {
        path: AppRoutes.EventDetailOrSharePage,
        strict: false,
        exact: true,
      }),
    [pathname],
  );

  return (
    <Flex
      data-testid="event-card-footer-share-action"
      {...handlers}
      cursor="pointer"
      ml="auto"
      alignItems="center"
      tabIndex={0}
      {...(() =>
        isDetailOrSharePage
          ? {
              backgroundColor: 'primary.500',
              height: { base: 32 },
              px: { base: 3 },
              borderRadius: 25,
            }
          : {
              role: 'group',
              _groupHover: {
                color: 'primary.500',
              },
            })()}
    >
      {isDetailOrSharePage ? (
        <>
          <EventCardFooterAction
            {...props}
            icon={<Icon name="share-2" color="white" size={iconSize} />}
            tabIndex={-1}
            mr={2}
          />
          <Box as="span" textStyle="body-large-bold" color="white">
            {t('share')}
          </Box>
        </>
      ) : (
        <>
          <Box as="span" mt={1} mr={1} textStyle="footnote-xsmall-light">
            {t('share')}
          </Box>
          <EventCardFooterAction
            {...props}
            icon={<Icon name="share-2" size={iconSize} />}
            tabIndex={-1}
          />
        </>
      )}
    </Flex>
  );
};
