import moment from 'moment-timezone';
import { FeedFiltersState } from 'src/components/Feed/components/FeedSubNavbar/types';

export const STORED_FEED_FILTERS_KEY = 'npssFeedFilters';

export const getStoredFeedFiltersState = () => {
  const storedFeedFiltersString = window.sessionStorage.getItem(
    STORED_FEED_FILTERS_KEY,
  );
  if (storedFeedFiltersString) {
    const storedFilters = JSON.parse(
      storedFeedFiltersString,
    ) as FeedFiltersState;
    if (storedFilters.startDate) {
      storedFilters.startDate = moment(storedFilters.startDate);
    }
    if (storedFilters.endDate) {
      storedFilters.endDate = moment(storedFilters.endDate);
    }
    return storedFilters;
  }
  return null;
};
