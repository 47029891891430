import { useEffect } from 'react';
import { useConsentManager } from 'src/components/App/hooks/useConsentManager';
import { config } from 'src/config';
import { useAuth } from 'src/context';
import { usePrivacyCookie, useHeapAnalytics } from 'src/hooks';
import { addEventProperties } from 'src/utils/heap';
import { useSpectrometer } from './useSpectrometer';

export const useThirdPartyAnalytics = () => {
  const { isPending, isAuthenticated, isNpssUser } = useAuth();
  const isHeapAllowed = usePrivacyCookie('analytics_heap');

  // calling useConsentManager is necessary or heap calls don't fire
  const isTrackingAccepted = useConsentManager();

  useEffect(() => {
    if (!isPending && isHeapAllowed) {
      addEventProperties(isAuthenticated, isNpssUser);
    }
  }, [
    isPending,
    isAuthenticated,
    isTrackingAccepted,
    isHeapAllowed,
    isNpssUser,
  ]);

  useSpectrometer();
  useHeapAnalytics(config.HEAP_ID, isHeapAllowed);
};
