import { useBoolean } from '@amzn/ring-ui-react-components';
import { FunctionComponent, MouseEventHandler } from 'react';
import { Box } from '../components/Box';

export const withMediaFallback =
  <P extends { src?: string; onClick?: MouseEventHandler<HTMLElement> }>(
    Component: FunctionComponent<P>,
  ) =>
  ({ src, onClick, ...props }: P) => {
    const [error, { on: setError }] = useBoolean();

    const shouldRenderPlaceholder = !src || error;

    if (shouldRenderPlaceholder) {
      return <Box height={500} width={1} onClick={onClick} />;
    }

    return (
      <Component
        {...(props as P)}
        src={src}
        onClick={onClick}
        onError={setError}
      />
    );
  };
