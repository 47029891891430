import { MouseEventHandler } from 'react';
import { createClickKeyDownHandler } from 'src/shared/utils';
import { Box } from '../Box';
import { Icon } from '../Icon';

export const EventGalleryFullscreenButton = (
  onClick: MouseEventHandler<HTMLDivElement>,
  isFullscreen: boolean,
) => {
  const handleClickKeyDownHandler = createClickKeyDownHandler(onClick);

  return (
    <Box
      bottom="auto"
      className="event-gallery-fullscreen-button"
      cursor="pointer"
      onClick={onClick}
      onKeyDown={handleClickKeyDownHandler}
      p={{ _: 4, m: 5 }}
      position="absolute"
      right={0}
      tabIndex={0}
      top={0}
      zIndex={isFullscreen ? 'videoFullscreenButton' : 0}
    >
      <Icon
        color="whiteAlpha.900"
        ml={0}
        filter="drop-shadow(0 2px 2px black)"
        name={isFullscreen ? 'close' : 'fullscreen'}
        size={{ _: 28, m: 32 }}
      />
    </Box>
  );
};
