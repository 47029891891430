import {
  ReportModalContainer,
  ReportModalContainerProps,
} from './ReportModalContainer';

type CommentReportModalProps = Omit<
  ReportModalContainerProps,
  'title' | 'description' | 'getConfirmationText' | 'initialReportCategoryId'
>;

export const CommentReportModal = (props: CommentReportModalProps) => (
  <ReportModalContainer
    {...props}
    title="Report Comment"
    description="Choose a reason for reporting this comment."
    getConfirmationText={(flag) =>
      `You are about to report a comment as ${flag}.`
    }
    initialReportCategoryId="comment_flag_categories"
  />
);
