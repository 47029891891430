import { Warning } from '@amzn/ring-ui-react-components';
import { callAllHandlers } from '@chakra-ui/utils';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ResponsiveValue, Space } from 'src/shared/theme';
import { Box } from '../Box';
import { Button } from '../Button';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  ModalProps,
  ModalHeader,
  ModalCloseButton,
} from '../Modal';

export interface ConfirmationModalProps extends ModalProps {
  cancelText?: string;
  confirmationText?: string;
  modalTitleText?: string;
  headerText?: string;
  onSubmit: (...args: any[]) => void;
  submitText?: string;
  hasWarning?: boolean;
}

export const ConfirmationModal = ({
  cancelText,
  confirmationText,
  headerText,
  modalTitleText,
  onClose,
  onSubmit,
  submitText,
  hasWarning = true,
  ...props
}: ConfirmationModalProps) => {
  const handleSubmit = useMemo(
    () => callAllHandlers(onSubmit, onClose),
    [onSubmit, onClose],
  );

  const { t } = useTranslation('components.modal', { useSuspense: false });

  return (
    <Modal {...props} onClose={onClose} isCentered size="xlg">
      <ModalOverlay>
        <ModalContent p={0}>
          <ModalHeader justifyContent="center" width={1}>
            {headerText || t('confirm')}
          </ModalHeader>
          <ModalCloseButton color="neutral.500" />
          <ModalBody py={py} px={px} textAlign="left">
            {hasWarning && <Warning color="negative.500" boxSize="12" mb="2" />}
            <Box
              textStyle={{
                _: 'heading-small-medium',
                m: 'heading-normal-medium',
              }}
              mb={2}
            >
              {modalTitleText || t('are-you-sure')}
            </Box>
            <Box textStyle="body-large-light">
              {confirmationText || t('click-ok-to-confirm')}
            </Box>
          </ModalBody>
          <ModalFooter
            borderTopWidth={0}
            px={px}
            pb={px}
            pt={{ _: 4, m: 0 }}
            boxShadow={{ _: 'shadow_bottom500', m: 'none' }}
          >
            <Button
              size="lg"
              onClick={onClose}
              variant="outline-negative"
              mr={{ _: 0, m: 4 }}
              mb={{ _: 3, m: 0 }}
              width={{ _: 1, m: 1 / 3 }}
            >
              {cancelText || t('no-go-back')}
            </Button>
            <Button
              size="lg"
              variant="negative"
              onClick={handleSubmit}
              width={{ _: 1, m: 1 / 3 }}
            >
              {submitText || t('yes-continue')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

const py = { _: 6, m: 7 } as ResponsiveValue<Space>;
const px = { _: 6, m: 8 } as ResponsiveValue<Space>;
