import { Route, RouteProps } from 'react-router-dom';
import { useAuth } from 'src/context/AuthContext';
import { CenteredLoading } from 'src/shared/components/Loading';

export const PrivateRoute = ({ component, ...props }: RouteProps) => {
  const { isAuthenticated } = useAuth();

  return (
    <Route
      {...props}
      component={isAuthenticated ? component : CenteredLoading}
    />
  );
};
