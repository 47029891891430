import {
  defaultBorderBottom,
  Flex,
  FlexProps,
} from '@amzn/ring-ui-react-components';

export const SubNavbar = (props: FlexProps) => (
  <Flex
    bg="white"
    height={subNavbarHeight}
    px={{ base: 6, m: 10 }}
    py={4}
    zIndex="docked"
    align="center"
    {...props}
    {...defaultBorderBottom}
  />
);

export const subNavbarHeight = 14;
