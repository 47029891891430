import { Event } from '@amzn/ring-neighbors-api-orchestrator-gql-schema';
import { DeniedPostPage } from '@amzn/ring-neighbors-contentful-gql-schema';
import { NoPosts } from './NoPosts';
import { PostsInReview } from './PostsInReview';

type UnifiedMyPostsProps = {
  events: Event[];
  handleNoPostsClick: () => void;
  handleResolvePostClick: () => void;
  dataTestId: string;
  handleReSchedulePostClick?: (id: string, scheduledFor: string) => void;
  mutationHasFinished?: () => void;
  handleEditScheduledPostClick?: (
    id: string,
    title: string,
    description: string,
  ) => void;
  denialReasons?: DeniedPostPage[];
};

export const UnifiedMyPosts = (props: UnifiedMyPostsProps): JSX.Element => {
  const {
    events,
    handleNoPostsClick,
    handleResolvePostClick,
    handleReSchedulePostClick,
    handleEditScheduledPostClick,
    mutationHasFinished,
    dataTestId,
    denialReasons,
  } = props;

  return events.length === 0 ? (
    <NoPosts onClick={handleNoPostsClick} />
  ) : (
    <PostsInReview
      events={events}
      mb={6}
      handleResolvePostClick={handleResolvePostClick}
      handleReSchedulePostClick={handleReSchedulePostClick}
      handleEditScheduledPostClick={handleEditScheduledPostClick}
      mutationHasFinished={mutationHasFinished}
      data-testid={dataTestId}
      denialReasons={
        denialReasons && denialReasons.length > 0
          ? (denialReasons as DeniedPostPage[])
          : undefined
      }
    />
  );
};
