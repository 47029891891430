import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact, {
  BugsnagPluginReactResult,
} from '@bugsnag/plugin-react';
import Cookies from 'js-cookie';
import React from 'react';
import { config } from 'src/config';
import { ignoreError, labelErrorByRoute } from './utils';
import { isProd, isQA } from '../utils/constants';
const EVENT_STREAM_UUID_ID = 'event-stream-uuid';

const getReleaseStage = (): string => {
  if (isProd) {
    return 'production';
  }

  if (isQA) {
    return 'gamma';
  }

  return 'beta';
};

Bugsnag.start({
  apiKey: config.BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: ['beta', 'gamma', 'production'],
  releaseStage: getReleaseStage(),
  collectUserIp: false,
  onError: (event) => {
    const { errorMessage } = event.errors[0];
    const { url } = event.request;

    if (event.app.releaseStage !== 'production') {
      console.debug(event.errors[0]); // during beta and gamma, show error in console log
    }

    if (ignoreError(errorMessage, url)) {
      return false;
    }

    const eventStreamUuid = Cookies.get(EVENT_STREAM_UUID_ID) || '';
    event.setUser(eventStreamUuid);
    event.groupingHash = labelErrorByRoute(errorMessage);
    event.request.referer = document.referrer;
  },
  endpoints: {
    notify: config.BUGSNAG_NOTIFY_URL,
    sessions: config.BUGSNAG_SESSIONS_URL,
  },
});

export const BugsnagErrorBoundary = (
  Bugsnag.getPlugin('react') as BugsnagPluginReactResult
).createErrorBoundary(React);
