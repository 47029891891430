import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n
  // load translations through http layer (/public/locales)
  .use(Backend)
  // detect browser language
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      allowMultiLoading: true,
    },
    detection: {
      order: [
        'cookie',
        'navigator',
        'querystring',
        'localStorage',
        'sessionStorage',
        'htmlTag',
      ],
      lookupCookie: 'locale',
    },
    fallbackLng: 'en-US',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
