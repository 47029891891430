import { rgba } from 'polished';

const black = '#000';
const white = '#fff';

const commonColors = {
  black,
  white,
  transparent: 'transparent',
  currentColor: 'currentColor',
  whiteAlpha: {
    50: rgba(white, 0.04),
    100: rgba(white, 0.06),
    200: rgba(white, 0.08),
    300: rgba(white, 0.16),
    400: rgba(white, 0.24),
    500: rgba(white, 0.36),
    600: rgba(white, 0.48),
    700: rgba(white, 0.64),
    800: rgba(white, 0.8),
    900: rgba(white, 0.92),
  },
  blackAlpha: {
    50: rgba(black, 0.04),
    100: rgba(black, 0.06),
    200: rgba(black, 0.08),
    300: rgba(black, 0.16),
    400: rgba(black, 0.24),
    500: rgba(black, 0.36),
    600: rgba(black, 0.48),
    700: rgba(black, 0.64),
    800: rgba(black, 0.8),
    900: rgba(black, 0.92),
  },
} as const;

// https://www.figma.com/file/O0FWE4TJWmIGCksbLUEL0i0y/%E2%9D%96-Web-(Logged-In)?node-id=0%3A1138

const baseColors = {
  primary: {
    100: '#EFF8FC',
    200: '#AFDBF1',
    500: '#1998D5',
    400: '#1476AD',
    700: '#10628A',
  },
  secondary: {
    100: '#FEF4EE',
    200: '#FACAAB',
    500: '#F1670D',
    400: '#C74E00',
    700: '#9C4308',
  },
  negative: {
    100: '#FCEFEF',
    200: '#F0B2AF',
    500: '#D4231A',
    400: '#A72721',
    700: '#7C1212',
  },
  positive: {
    100: '#EFF6EF',
    200: '#AED3AF',
    300: '#40A142',
    400: '#157217',
    500: '#178019',
    700: '#22580E',
  },
} as const;

export type BaseColor = keyof typeof baseColors;

type BaseColorValue = typeof baseColors[BaseColor];

const baseColorsWithAliases = Object.entries(baseColors).reduce(
  (acc, [k, v]) => {
    acc[k as BaseColor] = {
      ...v,
      bg: v[100],
      disabled: v[200],
      default: v[500],
      hover: v[400],
      pressed: v[700],
    };

    return acc;
  },
  {} as Record<
    BaseColor,
    BaseColorValue & {
      bg: string;
      disabled: string;
      default: string;
      hover: string;
      pressed: string;
    }
  >,
);

const ringColors = {
  ...baseColorsWithAliases,
  caution: {
    500: '#FDD835',
  },
  tertiary: {
    300: '#F6F8FA',
    500: '#DEE5EC',
    700: '#81919F',
  },
  neutral: {
    50: '#FAFBFC',
    200: '#BDBDBD',
    300: '#6E6E6E',
    500: '#424242',
  },
};

const extraAliases = {
  borders: ringColors.tertiary[500],
  muted: commonColors.blackAlpha[500],
  backup: commonColors.blackAlpha[700],
  resolved: ringColors.positive[500],
} as const;

export const colors = {
  ...commonColors,
  ...ringColors,
  ...extraAliases,
} as const;

export type Color = keyof typeof colors;
