import { Box, Flex, FlexProps } from '@amzn/ring-ui-react-components';
import { cloneElement, ReactElement } from 'react';
import { Icon, IconName } from '../Icon';

// TODO: type correctly using generics
export interface DrawerMenuItemProps extends FlexProps {
  as?: 'a';
  children: string;
  disabled?: boolean;
  icon?: IconName | ReactElement;
  href?: string;
}

export const DrawerMenuItem = ({
  children,
  disabled,
  icon,
  onClick,
  ...props
}: DrawerMenuItemProps) => (
  <Flex
    align="center"
    color="neutral.500"
    cursor={!disabled ? 'pointer' : undefined}
    onClick={!disabled ? onClick : undefined}
    p={3}
    position="relative"
    textDecoration="none"
    data-testid="drawer-menu-item"
    {...props}
  >
    {icon ? (
      typeof icon === 'string' ? (
        // TODO: remove old Icon component usage
        <Icon name={icon} size="1.3em" mr={3} />
      ) : (
        cloneElement(icon, { boxSize: '1.3em', mr: 3 })
      )
    ) : null}
    <Box as="span" textStyle="body-large-medium">
      {children}
    </Box>
  </Flex>
);

export const NpssDrawerMenuItem = ({
  children,
  disabled,
  icon,
  onClick,
  ...props
}: DrawerMenuItemProps) => (
  <Flex
    align="center"
    color="neutral.500"
    cursor={!disabled ? 'pointer' : undefined}
    onClick={!disabled ? onClick : undefined}
    py={3}
    px={6}
    position="relative"
    textDecoration="none"
    data-testid="drawer-menu-item"
    _hover={{
      backgroundColor: 'primary.100',
      color: 'primary.400',
    }}
    {...props}
  >
    {icon ? (
      typeof icon === 'string' ? (
        // TODO: remove old Icon component usage
        <Icon name={icon} size={24} mr={4} />
      ) : (
        cloneElement(icon, { boxSize: 24, mr: 4 })
      )
    ) : null}
    <Box as="span" textStyle="body-large-regular">
      {children}
    </Box>
  </Flex>
);
