import { ViewType, ItemType } from 'src/components/App/hooks';
import { useEventStream } from 'src/shared/hooks/useEventStream';

export const useNeighborsWebClickEventStreamDispatcher = (
  view: ViewType | undefined,
) => {
  const { dispatchEvent } = useEventStream();

  if (!view) return undefined;

  return async (item: ItemType, tags: string[] = [], properties: {} = {}) =>
    await dispatchEvent('Item.Click', [view, item, ...tags], {
      itemName: item,
      screenTitle: view,
      ...properties,
    });
};
